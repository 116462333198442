<template>
  <div>
    <div class="results-and-sorting-wrap">
      <h2 v-if="total > 0" class="h3 font-weight-bold mb-1 mb-lg-0">{{ total }} pasujące wyniki</h2>
      <h2 v-if="total === 0" class="h3 font-weight-bold mb-1 mb-lg-0">&nbsp;</h2>

      <div class="form-group d-flex justify-content-lg-end align-items-center mb-0">
        <label class="small font-weight-bold text-primary pt-2">Sortuj:&nbsp;</label>
        <div>
          <div class="dropdown dropdown-primary">
            <a class="text-primary dropdown-toggle" type="button" id="options-sorting" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ getSortingLabel(sorting) }}
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="options-sorting">
              <a @click.prevent="sorting='default'" class="dropdown-item" href="#" data-value="default">{{ getSortingLabel('default') }}</a>
              <a @click.prevent="sorting='distance'" class="dropdown-item" href="#" data-value="distance">{{ getSortingLabel('distance') }}</a>
              <a @click.prevent="sorting='rating_intS'" class="dropdown-item" href="#" data-value="rating_intS">{{ getSortingLabel('rating_intS') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="resultSetNonEmpty && maxPossiblePaginationCountExceedsLimit" class="text-center mt-5 mb-6">
      <strong>{{
          'search.possible-total-exceeds-display-limit-info' | trans({maxCount: maxPossibleResultCountToShow}, 'AppProduct')
        }}</strong>
    </div>

    <!-- placeholder list -->
    <div v-if="isLoading">
      <search-results-placeholder v-for="(cnt, index) in [1,2,3,4,5]" :key="index" :item="cnt">
      </search-results-placeholder>
    </div>

    <!--begin::results list-->
    <div v-if="resultSetNonEmpty">
      <search-results-item v-for="(resultItem, index) in searchResults" :key="index" :item="resultItem">
      </search-results-item>
      <div v-if="maxPaginationCountExceeded" class="text-center">
        <strong>{{ 'search.total-exceeds-display-limit-info' | trans({}, 'AppProduct') }}</strong>
      </div>
    </div>
    <infinite-scroll-trigger
      :is-active="infiniteScrollIsActive"
      :is-loading="isLoading"
      @trigger-intersected="loadMoreResults">
    </infinite-scroll-trigger>
    <!--end::results list-->

    <!--begin:: Components/Pagination/Default-->
    <!--paginator
      :page="pagination.page"
      :perpage="pagination.perpage"
      :total="total"
      v-if="resultSetNonEmpty"
      @paginate="paginationEventHandler" @trigger-intersected="loadMoreResults"></paginator-->
    <!--end:: Components/Pagination/Default-->

  </div>
</template>

<script>
import JsRouting from 'fos-jsrouting-bundle';
import 'lity';
import 'css/lity.scss';
import 'babel-polyfill'; // needed to support "async + await"

//import Paginator from 'js/app/components/paginator';
import GeneralUtil from 'js/app/utils/general';
import SearchResultsItem from './search--results.item.vue';
import InfiniteScrollTrigger from 'js/app/components/infinite-scroll-trigger';
import SearchResultsPlaceholder from './search--results.placeholder.vue';

export default {
  name: 'SearchResults',
  components: {
    //Paginator,
    SearchResultsItem,
    InfiniteScrollTrigger,
    SearchResultsPlaceholder,
  },
  props: {
    paginationInput: {
      type: Object,
      default: null,
    },
    searchResults: {
      type: Array,
      default: null,
    },
    total: {
      type: Number,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    maxPaginationCount: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      pagination: {
        page: parseInt(this.paginationInput.page),
        perpage: parseInt(this.paginationInput.perpage),
      },
      maxPossiblePaginationCountExceedsLimit: false,
      maxPaginationCountExceeded: false,
      sorting: 'default',
      infiniteScrollIsActive: true, // make it always true initially!
      showPlaceholder: true,
    };
  },
  computed: {
    resultSetNonEmpty() {
      return this.searchResults !== null && this.searchResults.length > 0;
    },
    maxPossibleResultCountToShow() {
      return this.maxPaginationCount * this.pagination.perpage;
    },
  },
  watch: {
    paginationInput: {
      handler(val) {
        this.pagination.page = parseInt(val.page);
        this.pagination.perpage = parseInt(val.perpage);
      },
      deep: true,
    },
    sorting(val) {
      this.$emit('sort', val);
    },
    'pagination.page': {
      handler(val) {
        if (val > this.maxPaginationCount) {
          this.maxPaginationCountExceeded = true;
        }
      },
      immediate: true, // react also on the initial change (setting from prop)
    },
    maxPaginationCountExceeded(val) {
      if (val === true) {
        // do not attempt fetching more results
        // stop infinite scroll
        this.infiniteScrollIsActive = false;
      }
    },
    searchResults: {
      handler(val) {
        if (Array.isArray(val) && this.total > 0) {
          if (this.total <= val.length) {
            // we reached all search results
            // stop infinite scroll
            this.infiniteScrollIsActive = false;
          } else {
            if (this.total > this.maxPossibleResultCountToShow) {
              this.maxPossiblePaginationCountExceedsLimit = true;
            }
            this.infiniteScrollIsActive = true;
          }
        }
      },
      deep: true,
      immediate: true, // react also on the initial change (setting from prop)
    },
  },
  created() {

  },
  mounted() {
    let self = this;
    // fetch user position
    this.userPosition = null;
    this.init();
  },
  methods: {
    async init() {
      const self = this;

      if (navigator.geolocation) {
        await navigator.geolocation.getCurrentPosition((position) => {
          self.userPosition = position;
        });
      }
    },

    trans(key, params, domain, multipleCount) {
      if (multipleCount) {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'AppProduct');
      } else {
        return Translator.trans(key, params || {}, domain || 'AppProduct');
      }
    },

    getSortingLabel(sortingValue) {
      let langKey = '';
      switch (sortingValue) {
        case 'default':
          langKey = 'product.search.sorting.random';
          break;
        case 'distance':
          langKey = 'product.search.sorting.distance';
          break;
        case 'rating_intS':
          langKey = 'product.search.sorting.rating';
          break;
      }
      return this.trans(langKey, {}, 'AppProduct');
    },

    // Needed only when paginator is being used instead of infinite scroll
    // paginationEventHandler(page, perpage) {
    //     this.pagination.page = page;
    //     this.pagination.perpage = perpage;
    //     // pass this event to parent container which has access to the search-detailed-form component
    //     this.$emit('paginate', page, perpage);
    // },

    /**
     * Load more results triggered by infinite scroll trigger
     */
    loadMoreResults() {
      if (this.resultSetNonEmpty === true) {
        this.pagination.page += 1;
        this.$nextTick(() => {
          // next tick to be sure maxPaginationCountExceeded has been calculated
          // after we raised this.pagination.page
          if (this.maxPaginationCountExceeded === false) {
            this.$emit('paginate', this.pagination.page, this.pagination.perpage);
          }
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
