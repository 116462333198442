var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "kt-notifications-panel", staticClass: "kt-notification" },
    [_vm._v("\n  TODO\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }