<template>
  <div class="zero-section-geocoder-map">
    <div class="w-lg-60 mx-3 mx-sm-6 mx-lg-auto pt-sm-1">
      <form class="position-relative z-index-2"
            :action="actionUrl"
            autocomplete="off"
            @submit.prevent="submitForm"
            method="get"
      >
        <div class="input-group">
          <div ref="geocoder" id="geocoder" class="geocoder"></div>
          <div class="input-group-append">
            <button class="btn btn-lg btn-primary rounded-right-pill" type="submit"><i class="fas fa-search mr-lg-2"></i> <span class="d-none d-lg-inline-block">Szukaj</span></button>
          </div>
        </div>
        <input type="hidden" name="_token" :value="token">
      </form>
    </div>
  </div>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css';
// Do not use mapbox-gl-geocoder.css if you override the styles heavily in own theme
//import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import xsrfUtils from 'js/app/utils/xsrf-utils';
import JsRouting from 'fos-jsrouting-bundle';
import Translator from 'bazinga-translator';
import persistentStore from '../store/PersistentStore';

export default {
  name: 'ZeroSectionGeocoderStandalone',
  props: {
    longitude: {
      type: String,
      default: '17.0297824',
    },
    latitude: {
      type: String,
      default: '51.1096513',
    },
    mapboxApiKey: {
      type: String,
      default: '',
    },
    actionRoute: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      spatialPt: '',
      token: '',
      place: '',
      geocoderInitialized: false,
      initialPosition: new mapboxgl.LngLat(
        this.longitude,
        this.latitude,
      ),
      position: new mapboxgl.LngLat(
        this.longitude,
        this.latitude,
      ),
    };
  },
  computed: {
    apiAvailable() {
      return !(typeof mapboxgl === 'undefined');
    },
    actionUrl() {
      return JsRouting.generate(this.actionRoute, {}, true);
    },
  },
  watch: {
    position(lngLat) {
      if (typeof lngLat !== 'undefined') {
        this.spatialPt = `${lngLat.lat},${lngLat.lng}`;
      }
    },
  },
  created() {
    this.geocoder = null;
  },
  mounted() {
    const self = this;
    if ($(this.$el)[0].nodeName === 'FORM') {
      this.$form = $(this.$el);
    } else {
      this.$form = $(this.$el).find('form');
    }
    self.init();
  },
  methods: {
    async init() {
      const self = this;

      try {
        await this.detectInitialSearchLocation();
        this.initGeocoder();
      } catch (err) {
        console.error(err); // TypeError: failed to fetch
      }
    },

    trans(key, domain, params, multipleCount) {
        if (multipleCount) {
            return Translator.transChoice(key, multipleCount, params || {}, domain || 'AppProduct');
        } else {
            return Translator.trans(key, params || {}, domain || 'AppProduct');
        }
    },

    async detectInitialSearchLocation() {
      const self = this;
      // determine the location for the search
      if (navigator.geolocation) {
        // fetch user position from the browser
        await navigator.geolocation.getCurrentPosition((position) => {
          self.initialPosition = new mapboxgl.LngLat(
            position.coords.longitude,
            position.coords.latitude,
          );
        });
      } else {
        // static fallback
        console.info('No geolocation data available');
        self.initialPosition = new mapboxgl.LngLat(
          this.longitude,
          this.latitude,
        );
      }
      self.position = new mapboxgl.LngLat(
        self.initialPosition.lng,
        self.initialPosition.lat,
      );
    },

    /**
     * @see https://github.com/mapbox/mapbox-gl-geocoder/blob/master/API.md
     */
    initGeocoder() {
      const self = this;
      mapboxgl.accessToken = this.mapboxApiKey;

      if (!this.$refs['geocoder']) {
        return false;
      }

      this.geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        language: 'pl',
        flyTo: false, // disable the default flyTo animation (very slow)
        clearAndBlurOnEsc: true, // the geocoder control will clear it's contents and blur when user presses the escape key
        clearOnBlur: true, // the geocoder control will clear its value when the input blurs
        mapboxgl: mapboxgl,
        types: 'place',
        countries: 'pl',
        placeholder: this.trans('form.search.where-do-you-want-to-search'),
        // render(carmenGeoJson) {
        //   return carmenGeoJson.text;
        // },
      });

      this.geocoder.addTo('#geocoder');

      // autocomplete listener
      this.geocoder.on('result', (e) => {
        self.place = null;
        const {result} = e;
        const resultType = result.id.split('.')[0];
        switch (resultType) {
          case 'place':
            self.place = result.text;
            break;
          case 'address':
            self.place = result.text + (result.address ? ` ${result.address}` : '');
            break;
          case 'poi':
            self.place = result.properties.address;
            break;
          case 'district':
            self.place = result.text;
            break;
          default:
            self.place = '';
        }
        self.position = new mapboxgl.LngLat(
          result.geometry.coordinates[0],
          result.geometry.coordinates[1],
        );
        this.$emit('geocode', self.position.lat, self.position.lng, self.place);
      });

      // important to set this flag
      this.geocoderInitialized = true;

      return true;
    },

    /**
     *
     * @param lngLat mapbox LngLat object
     * @returns {jQuery|*|{}}
     */
    reverseMapboxGeocode(lngLat) {
      // this reference for callback functions
      const self = this;
      const def = $.Deferred();

      // check api
      if (!this.apiAvailable) {
        def.reject();
        return def;
      }

      if (lngLat) {
        console.log(`Mapbox API call: lat=${lngLat.lat}, lng=${lngLat.lng}`);
        // save current query settings
        const tmpLimit = this.geocoder.getLimit();
        const tmpTypes = this.geocoder.getTypes();
        // set query settings for single place retrieval
        this.geocoder.setLimit(1);
        this.geocoder.setTypes('place');
        // do the query
        this.geocoder.query(`${lngLat.lng}, ${lngLat.lat}`);
        // restore current query settings
        this.geocoder.setLimit(tmpLimit);
        this.geocoder.setTypes(tmpTypes);
        def.resolve();
      } else {
        def.reject();
        throw new Error('No coordinates given');
      }
      return def;
    },

    submitForm() {
      const self = this;
      xsrfUtils.fetchCsrfToken('search').done((token) => {
        // store search location data in local storage to avoid exposing it to the user
        // which might violate the terms of use of the mapping/geocoder used
        persistentStore.commit('searchParams', {
          _token: token,
        });
        self.token = token;
        this.$nextTick(() => {
          // next tick to be sure the token value has been written in dom
          self.$form.submit();
        });
      }).fail(() => {
        console.error('token fetch failed');
      });
    },
  },
};
</script>

<style scoped>
.geocoder {
  z-index: 1;
  margin: 0px;
}
.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}
</style>
