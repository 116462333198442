import globalConfig from 'js/app/config/app';

class MercureUtil {
  constructor() {

  }

  static subscribeTopic(topic, handler) {
  }
}

export default MercureUtil;
