var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showStats
    ? _c(
        "div",
        { staticClass: "col-md-3 text-md-right pt-md-2" },
        _vm._l(_vm.enumeratedStats, function(statItem) {
          return _c(
            "div",
            {
              key: statItem.type,
              staticClass:
                "text-primary text-center border-md-bottom mb-3 pb-2 d-flex d-md-block"
            },
            [
              _c("img", {
                staticClass: "mr-3 mr-md-0 mb-md-1",
                staticStyle: { width: "21.5px" },
                attrs: {
                  src: _vm.statIcon(statItem.productType),
                  alt: "Image decription"
                }
              }),
              _vm._v(" "),
              _c("div", [
                _c("strong", [_vm._v(_vm._s(statItem.value))]),
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm._f("trans")(
                        statItem.transKey,
                        {},
                        "AppProduct",
                        statItem.value
                      )
                    ) +
                    "\n    "
                )
              ])
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }