<template>
  <!--begin::Forms Widget 6-->
  <div class="card card-custom gutter-b mb-2">
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Container-->
      <div>
        <!--begin::Header-->
        <div class="d-flex align-items-center">
          <!--begin::Symbol-->
          <!--div class="symbol symbol-40 symbol-light-success mr-5">
            <span class="symbol-label">
              <img src="assets/media/svg/avatars/009-boy-4.svg" class="h-75 align-self-end" alt="" />
            </span>
          </div-->
          <!--end::Symbol-->
          <!--begin::Info-->
          <div class="d-flex flex-column flex-grow-1">
            <span v-if="comment.author" class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">{{ comment.author.first_name }} {{ comment.author.last_name }}</span>
            <span v-if="!comment.author" class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">Komentarz anonimowy (lub usunięte konto)</span>
            <span class="text-muted font-size-xs">{{ comment.created_at | age }}</span>
          </div>
          <!--end::Info-->
          <!--begin::Dropdown-->
          <div v-if="isAuthenticated" class="dropdown dropdown-inline ml-2" data-toggle="tooltip" title="Quick actions" data-placement="left">
            <a href="#" class="btn btn-hover-light-primary btn-xs btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="ki ki-bold-more-hor"></i>
            </a>
            <div class="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
              <!--begin::Navigation-->
              <ul class="navi navi-hover">
                <li v-if="isOwner" class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-text">Edytuj komentarz</span>
                  </a>
                </li>
                <li v-if="isOwner" class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-text" @click.prevent="deleteComment">Usuń komentarz</span>
                  </a>
                </li>
                <!--li class="navi-separator mb-3 opacity-70"></li-->
                <li v-if="!isOwner" class="navi-item">
                  <a href="#" @click.prevent="reportMisuse()" class="navi-link">
                    <span class="navi-text">Zgłoś nadużycie</span>
                    <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>
                  </a>
                </li>
              </ul>
              <!--end::Navigation-->
            </div>
          </div>
          <!--end::Dropdown-->
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div>
          <!--begin::Text-->
          <p class="text-dark-75 font-size-lg font-weight-normal pt-2 mb-1">
            {{ comment.body }}
          </p>
          <!--end::Text-->
          <!--begin::Action-->
          <!--div class="d-flex align-items-center">
            <a href="#" class="btn btn-sm btn-text-dark-50 btn-hover-icon-danger btn-hover-text-danger bg-hover-light-danger font-weight-bolder rounded font-size-sm p-2">
              <span class="svg-icon svg-icon-md svg-icon-dark-25 pr-1">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path d="M16.5,4.5 C14.8905,4.5 13.00825,6.32463215 12,7.5 C10.99175,6.32463215 9.1095,4.5 7.5,4.5 C4.651,4.5 3,6.72217984 3,9.55040872 C3,12.6834696 6,16 12,19.5 C18,16 21,12.75 21,9.75 C21,6.92177112 19.349,4.5 16.5,4.5 Z" fill="#000000" fill-rule="nonzero" />
                  </g>
                </svg>
              </span>75</a>
          </div-->
          <!--end::Action-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Forms Widget 6-->
</template>

<script>
import Thread from '../models/Thread';
import Comment from '../models/Comment';
import JsRouting from 'fos-jsrouting-bundle';
import securityUtil from 'js/app/utils/security';
import globalState from 'js/app/store/GlobalStore';
import Axios from 'axios';
import guiUtil from 'js/app/utils/gui';
import HttpUtil from 'js/app/utils/http';
import Translator from 'bazinga-translator';
import xsrfUtils from 'js/app/utils/xsrf-utils';

export default {
  name: 'CommentSectionEntry',
  props: {
    threadId: {
      type: String,
      default: null,
    },
    comment: {
      type: Object,
      default: new Comment(),
    },
  },
  computed: {
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    },
    isOwner() {
      const currentUser = globalState.state.userData;
      if (currentUser && currentUser.hashid && this.comment.author) {
        return currentUser.hashid === this.comment.author.hashid;
      }
    },
  },
  methods: {
    trans(key, params, domain, multipleCount) {
      if (multipleCount) {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'messages');
      } else {
        return Translator.trans(key, params || {}, domain || 'messages');
      }
    },

    deleteComment() {
      const self = this;

      swal.fire({
        title: self.trans('comment.thread-comment.delete.are-you-sure', {}, 'messages'),
        //text: self.trans('confirmation.plan_cancel.info'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: self.trans('dict.i_do_confirm', {}, 'messages'),
        cancelButtonText: Translator.trans('dict.cancel', {}),
      }).then((result) => {
        if (result.value) {
          guiUtil.blockUI($(self.$el));
          xsrfUtils.fetchCsrfToken('comment.delete').done((token) => {
            const url = JsRouting.generate('fos_comment_delete_thread_comment', {
              id: self.threadId,
              commentId: self.comment.hashid,
              _token: token,
            }, true);
            Axios.delete(url).then((response) => {
              const deletedComment = new Comment(response.data);
              self.$emit('comment-deleted', deletedComment);
            }).catch((error) => {
              HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
              });
            }).then(() => {
              // 2nd then after catch is always executed - catch MUST be present for this to work
              guiUtil.unblockUI($(self.$el));
            });
          }).fail(() => {
            guiUtil.unblockUI($(self.$el));
            console.error('token fetch failed');
          });
        }
      });
    },

    reportMisuse() {
      // TODO implement misuse reporting feature
    },
  },
};
</script>

<style scoped>

</style>
