import 'css/search/detailed.scss';
import 'css/search/map.scss';

import Vue from 'vue';
import ZeroSectionGeocoderStandalone from '../app/components/zero-section-geocoder-standalone.vue';
import Search from './components/search.vue';
import persistentStore from '../app/store/PersistentStore';

export default function (anchorIds) {
  anchorIds = anchorIds || [];
  /**
   * #view-public-search-common
   */
  ((id) => {
    if (!anchorIds.includes(id)) return false;
    const appContainer = document.getElementById(id);
    if (appContainer) {
      const app = new Vue({
        el: appContainer,
        components: {
          ZeroSectionGeocoderStandalone,
          Search,
        },
        methods: {
          handleGeocode(lat, lng, place) {
            persistentStore.commit('searchParams', {
              place: place,
              spatial_pt: `${lat},${lng}`,
            });
          },
        },
      });
    }
  })('view-public-search-common');

  /**
   * #view-public-search-accommodation
   */
  ((id) => {
    if (!anchorIds.includes(id)) return false;
    const appContainer = document.getElementById(id);
    if (appContainer) {
      const app = new Vue({
        el: appContainer,
        components: {
          ZeroSectionGeocoderStandalone,
          Search,
        },
        methods: {
          handleGeocode(lat, lng, place) {
            persistentStore.commit('searchParams', {
              place: place,
              spatial_pt: `${lat},${lng}`,
            });
          },
        },
      });
    }
  })('view-public-search-accommodation');

  /**
   * #view-public-search-food
   */
  ((id) => {
    if (!anchorIds.includes(id)) return false;
    const appContainer = document.getElementById(id);
    if (appContainer) {
      const app = new Vue({
        el: appContainer,
        components: {
          ZeroSectionGeocoderStandalone,
          Search,
        },
        methods: {
          handleGeocode(lat, lng, place) {
            persistentStore.commit('searchParams', {
              place: place,
              spatial_pt: `${lat},${lng}`,
            });
          },
        },
      });
    }
  })('view-public-search-food');

  /**
   * view-public-search-handicraftandservices
   */
  ((id) => {
    if (!anchorIds.includes(id)) return false;
    const appContainer = document.getElementById(id);
    if (appContainer) {
      const app = new Vue({
        el: appContainer,
        components: {
          ZeroSectionGeocoderStandalone,
          Search,
        },
        methods: {
          handleGeocode(lat, lng, place) {
            persistentStore.commit('searchParams', {
              place: place,
              spatial_pt: `${lat},${lng}`,
            });
          },
        },
      });
    }
  })('view-public-search-handicraftandservices');
}
