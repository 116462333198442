import {Model, Collection} from 'vue-mc';
import moment from "moment";
import guiUtil from 'js/app/utils/gui';
import JsRouting from 'fos-jsrouting-bundle';
import Translator from "bazinga-translator";
import globalConfig from 'js/app/config/app';
import User from 'js/user/models/User';

class Message extends Model {

  constructor(data) {
    super(data);
    this.init();
  }

  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: null,
      created_at: null,
      body: null,
      sender: null,
      metadata: null,
      is_read_by_active_user: null,
      age_in_minutes: null,
      thread_data: null,
      thread_id: null,
      last_counterpart_message_date: null,
    };
  }

  // Attribute mutations.
  mutations() {
    return {
      identifier: String,
      action: String,
      title: (val) => {
        if (val) {
          if (val.substring(0, 3) === 'LLL') {
            let parts = val.split(':');
            return Translator.trans(parts[2], {}, parts[1]);
          }
        }
      },
      label: (val) => {
        if (val) {
          if (val.substring(0, 3) === 'LLL') {
            let parts = val.split(':');
            return Translator.trans(parts[2], {}, parts[1]);
          }
        }
      },
      created_at: (val) => {
        let age = moment.duration(moment().diff(moment(this.created_at)));
        this.age_in_minutes = Math.round(age.asMinutes());
        return val;
      },
      sender: (val) => {
        return new User(val);
      },
    };
  }

  // Attribute validation
  validation() {
    return {
      identifier: string.and(required),
      action: string.and(required)
    };
  }

  init() {
    let self = this;
    this.on('change', function (options) {
      switch (options.attribute) {
        case 'body':
          if (self.body === '') {
            throw new Error('Attribute "body" can not be empty');
          }
          break;
        case 'created_at':
          let age = moment.duration(moment().diff(moment(self.created_at)));
          self.age_in_minutes = Math.round(age.asMinutes());
          break;
      }
    });
  }

  getSenderProfileLink() {
    return '<a href="' + this.getSenderProfileUrl() + '">' + this.sender.username_canonical + '</a>';
  }

  getMessageHeader() {
    var header = Translator.trans('message_info', {
      'sender': this.getSenderProfileLink(),
      'date': this.getSentAt()
    }, 'FOSMessageBundle');
    return header;
  }

  getSenderProfileUrl() {
    return '';
  }

  getSenderName() {
    var name = this.sender.first_name;
    if (this.sender.last_name) {
      if (name > '') {
        name = name + ' ' + this.sender.last_name;
      } else {
        name = this.sender.last_name;
      }
    }
    if (!(name > '')) {
      name = this.username_canonical;
    }
    return name;
  }

  getSentAt() {
    return moment(this.created_at).format(globalConfig.moment.datetimeFormat);
  }

  getAge() {
    var age = this.age_in_minutes;
    var alt = age;
    if (age < 60) {
      return age + ' ' + Translator.transChoice('dict.minute', alt);
    } else if (age < 1440) {
      alt = Math.floor(age / 60);
      return Math.floor(age / 60) + ' ' + Translator.transChoice('dict.hour', alt);
    } else {
      alt = Math.floor(age / 1440);
      return Math.floor(age / 1440) + ' ' + Translator.transChoice('dict.day', alt);
    }
  }

}

export default Message;
