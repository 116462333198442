const params = {

  CONSOLE_LOG_ENABLE: (typeof GLOBAL != 'undefined' ? GLOBAL.CONSOLE_LOG_ENABLE : true),
  APP_CMS_COOKIES: (typeof GLOBAL != 'undefined' ? GLOBAL.APP_CMS_COOKIES : false),

  // use wroclaw as absolute fallback in case no current location can be fetched whatsoever
  fallbackCoordinates: {
    longitude: 17.038538,
    latitude: 51.107883,
  },

  // default moment js config
  moment: {
    dateFormat: 'DD.MM.YYYY',
    dateFormatWithoutDay: 'DD.MM',
    datetimeFormat: 'DD.MM.YYYY HH:mm',
    dayMonthFormat: 'DD MMM',
    dayMonthYearFormat: 'DD MMM YYYY',
    hourMinuteFormat: 'HH:mm',
  },

  // default datetime picker config
  picker: {
    dateFormat: 'dd.mm.yyyy',
    datetimeFormat: 'dd.mm.yyyy hh:ii',
  },

  decimalPoint: ',',

  isRTL: false,

  mercureHub: 'https://mercure.zamiastowi.pl/.well-known/mercure',

};

export default params;
