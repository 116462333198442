<template>
  <div class="sidebar-scroller">
    <div class="sidebar-container">
      <div class="sidebar-footer-offset" style="padding-bottom: 7rem;">
        <!-- Toggle Button -->
        <div class="d-flex justify-content-end align-items-center pt-4 px-4">
          <div class="hs-unfold">
            <a class="js-hs-unfold-invoker btn btn-icon btn-xs btn-soft-secondary" href="javascript:;"
               data-hs-unfold-options='{
                "target": "#sidebarContent",
                "type": "css-animation",
                "animationIn": "fadeInRight",
                "animationOut": "fadeOutRight",
                "hasOverlay": "rgba(55, 125, 255, 0.1)",
                "smartPositionOff": true
               }'>
              <svg width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor"
                      d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"/>
              </svg>
            </a>
          </div>
        </div>
        <!-- End Toggle Button -->

        <!-- Content -->
        <div class="kt-quick-panel__nav">
          <ul ref="quick-panel-tabs"
              class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand  kt-notification-item-padding-x"
              role="tablist">
            <li class="nav-item active">
              <a class="nav-link active" data-toggle="tab" href="#quick_panel_tab_activities" role="tab"
                 v-html="trans('quick-sidebar.tab.recent-activities')">Recent Activities</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#quick_panel_tab_notifications" role="tab"
                 v-html="trans('quick-sidebar.tab.user-notifications')">User Notifications</a>
            </li>
          </ul>
        </div>
        <div id="kt-quick-panel__content" class="kt-quick-panel__content">
          <div class="tab-content">

            <div class="tab-pane fade show kt-scroll active" id="quick_panel_tab_activities" role="tabpanel">
              <div class="scrollbar sidebar-body">
                <div class="sidebar-content py-4 px-7">
                  <aside-panel-recent-activities gui-block-id="kt_quick_panel"></aside-panel-recent-activities>
                </div>
              </div>
            </div>

            <div class="tab-pane fade kt-scroll" id="quick_panel_tab_notifications" role="tabpanel">
              <div class="scrollbar sidebar-body">
                <div class="sidebar-content py-4 px-7">
                  <aside-panel-user-notifications gui-block-id="kt_quick_panel"></aside-panel-user-notifications>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- End Content -->
      </div>
    </div>
  </div>
</template>

<script>
import AsidePanelUserNotifications from 'js/app/components/aside-panel-user-notifications';
import AsidePanelRecentActivities from 'js/app/components/aside-panel-recent-activities';
import JsRouting from 'fos-jsrouting-bundle';
import Axios from "axios";
import securityUtil from "js/app/utils/security";
import globalConfig from 'js/app/config/app';
import guiUtil from "js/app/utils/gui";
import {EventBus} from 'js/app/utils/EventBus';
import HSUnfold from 'theme/assets/vendor/hs-unfold/dist/hs-unfold';

export default {
  name: 'AsidePanel',
  components: {
    AsidePanelUserNotifications,
    AsidePanelRecentActivities
  },
  data() {
    return {
      triggerFetchNotifications: false,
      triggerFetchActivities: false,
    };
  },

  computed: {
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    },
  },

  watch: {},

  created() {
  },

  mounted() {
    try {
      this.init();
    } catch (error) {
      throw new Error(error);
    }
  },

  methods: {

    init() {
      const self = this;

      let unfold = null;
      $(this.$el).find('.js-hs-unfold-invoker').each((el, val) => {
        unfold = new HSUnfold($(val)).init();
      });

      if (this.isAuthenticated) {

        // bootstrap event listener for a shown tab
        $(this.$refs['quick-panel-tabs']).on('click', 'a[data-toggle="tab"]', function (e) {
          let $tab = $(e.target);
          let target = $tab.attr('href');

          switch (target) {
            case '#quick_panel_tab_activities':
              // do nothing, static content
              break;
            case '#quick_panel_tab_notifications':
              // @see https://alligator.io/vuejs/global-event-bus/
              EventBus.$emit('quick-panel-load-notifications', []);
              break;
          }

        });

      }
    },

    trans(key, domain, params) {
      return Translator.trans(key, params || {}, domain || 'messages');
    }

  }
}
</script>

<style scoped>

</style>
