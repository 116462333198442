<!--template>
inline template
</template-->

<script>
import '@fancyapps/fancybox/dist/jquery.fancybox';
import 'theme/assets/js/hs.fancybox';

import Axios from 'axios/index';
import guiUtil from 'js/app/utils/gui';
import HttpUtil from 'js/app/utils/http';
import Translator from 'bazinga-translator';
import JsRouting from 'fos-jsrouting-bundle';
import SlickCarousel from 'js/app/components/slick-carousel.vue';
import SubjectLikes from 'js/app/components/subject-likes.vue';
import SubjectStats from 'js/app/models/SubjectStats';

export default {
  name: 'ArticleSingle',
  components: {
    SlickCarousel,
    SubjectLikes,
  },
  props: {
    articleId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      articleStats: new SubjectStats(),
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // init bootstrap & Co.
      this.initPlugins();
      this.fetchStats();
    },
    initPlugins() {
      const self = this;
      function initFancybox() {
        // initialization of fancybox
        $(self.$el).find('.js-fancybox').each((key, el) => {
          $.HSCore.components.HSFancyBox.init($(el));
        });

        $('[data-fancybox]').fancybox({
          toolbar: false,
          smallBtn: true,
          iframe: {
            preload: true,
          },
        });
      }

      initFancybox();
    },
    fetchStats() {
      const self = this;
      const url = JsRouting.generate('api_press.article.stats', {articleId: this.articleId}, true);

      Axios.get(url).then((response) => {
        self.articleStats = new SubjectStats({
          likes: response.data.likes,
          user: {
            likes: response.data.user.likes,
          },
        });
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
          self.error = errorString;
        });
      }).then(() => {
        // 2nd then after catch is always executed - catch MUST be present for this to work
      });
    },
  },
};
</script>

<style scoped>

</style>
