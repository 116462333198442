import Vue from "vue";
import JsRouting from 'fos-jsrouting-bundle';
import Translator from "bazinga-translator";
import guiUtil from 'js/app/utils/gui';

Vue.directive('bug-report-button', {
  bind(el, binding, vnode) {
    const url = JsRouting.generate('app.report_a_bug');
    if (binding.value) {
      el.innerHTML = '<a href="' + url + '" class="btn btn-warning"><i class="la la-bullhorn pr-2"></i>' + binding.value + '</a>';
    } else {
      el.innerHTML = '<a href="' + url + '" class="btn btn-warning"><i class="la la-bullhorn pr-2"></i>' + Translator.trans('gui.report_a_bug.title') + '</a>';
    }
  },
});

Vue.directive('alert-error', {
  bind(el, binding, vnode) {
    const cssClass = el.dataset.cssClass ? el.dataset.cssClass : 'alert alert-custom alert-light-danger mb-5 p-5';
    el.innerHTML = `<div class="metronic-scope"><div class="${cssClass}" role="alert">`
      + '<div class="alert-icon"><i class="flaticon-warning"></i></div>'
      + `<div class="alert-text">${el.innerHTML}</div>`
      + '</div></div>';
  },
});

Vue.directive('alert-call-to-action', {
  bind(el, binding, vnode) {
    const cssClass = el.dataset.cssClass ? el.dataset.cssClass : 'alert alert-custom alert-light-primary mb-5 p-5';
    el.innerHTML = '<div class="metronic-scope"><div class="' + cssClass + '" role="alert">'
      + (el.dataset.title ? '<h4 class="alert-heading">' + el.dataset.title + '</h4>' : '')
      + `<div class="alert-text">${el.innerHTML}</div>`
      + '</div></div>';
  },
});

Vue.directive('init', {
  // initialize the data with the value currently set in HTML
  bind(el) {
    switch (el.nodeName) {
      case 'INPUT':
        switch (el.getAttribute('type')) {
          case 'checkbox':
            if (el.getAttribute('checked')) {
              el.checked = 'checked';
            }
            break;
          default:
            el.value = el.getAttribute('value');
        }
        el.dispatchEvent(new Event('input'));
        break;
      case 'SELECT':
        let selVal = el.options[el.selectedIndex].value;
        el.value = selVal;
        el.dispatchEvent(new Event('change'));
        break;
    }
  },
});

Vue.directive('expandible-list', {
  componentUpdated(el, binding, vnode) {
    let $ul = $(el);
    let $lis = $ul.find(`li:gt(${binding.value ? binding.value : 8})`);
    let isExpanded = $ul.hasClass('expanded');
    $lis[isExpanded ? 'show' : 'hide']();
    if ($lis.length > 0) {
      $ul.after($('<span class="showmore btn btn-xs btn-pill btn-outline-primary">' + (isExpanded ? Translator.trans('dict.show-less', {}, 'messages') : Translator.trans('dict.show-more', {}, 'messages')) + '</span>')
        .click((event) => {
          var isExpanded = $ul.hasClass('expanded');
          if (isExpanded) {
            guiUtil.scrollIntoView(el, -150);
          }
          event.preventDefault();
          $(event.target).html(isExpanded ? Translator.trans('dict.show-more', {}, 'messages') : Translator.trans('dict.show-less', {}, 'messages'));
          $ul.toggleClass('expanded');
          $lis.toggle();
        }),
      );
    }
  },
});
