import {Model, Collection} from 'vue-mc';
import moment from 'moment';
import globalConfig from 'js/app/config/app';

class Subscription extends Model {

  constructor(data) {
    super(data);
    this.init(data);
  }

  // Default attributes that define the "empty" state.
  defaults() {
    return {
      type: null,
      status: null,
      canceled_at: null,
      expires_at: null,
      starts_at: null,
      hashid: null,
    };
  }

  // Attribute mutations.
  mutations() {
    return {
      status: String,
      type: String,
      canceled_at: (canceledAt) => {
        if (canceledAt) {
          return moment(canceledAt).format(globalConfig.moment.dateFormat);
        }
      },
      expires_at: (expiresAt) => {
        if (expiresAt) {
          return moment(expiresAt).format(globalConfig.moment.dateFormat);
        }
      },
      starts_at: (expiresAt) => {
        if (expiresAt) {
          return moment(expiresAt).format(globalConfig.moment.dateFormat);
        }
      },
    };
  }

  // Attribute validation
  validation() {
    return {
      hashid: string.and(required),
      status: string.and(required),
      type: string.and(required),
    };
  }

  // // Route configuration
  // routes() {
  //   return {
  //     fetch: '/task/{id}',
  //     save:  '/task',
  //   }
  // }

  init() {
    this.on('change', function (options) {
      switch (options.attribute) {
        case 'code':
          //console.log(options.previous + ' => ' + options.value);
          break;
      }
    });
  }

}

export default Subscription;
