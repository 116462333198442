class KeyValuePairArray {
  /**
   * set an element in a key value pair array based on key AND value
   * if not already found
   * @param kvArray
   * @param key
   * @param value
   * @param forceStringValues
   */
  elementSetIfNotFound(kvArray, key, value, forceStringValues = false) {
    let found = false;
    $.each(kvArray, (i, pair) => {
      if (pair[0] === key && pair[1] === value) {
        found = key;
      }
    });
    if (!found) {
      kvArray.push([key, (forceStringValues === true ? `${value}` : value)]);
    } else {
      if (forceStringValues) {
        kvArray[found] = [key, `${kvArray[found][1]}`];
      }
      ;
    }
  }

  /**
   * Remove a key value pair array element based on key AND alternative a specific value in case
   * the key is not unique in the array as its the case for multiple choice values
   * @param kvArray
   * @param key
   * @param value
   */
  elementRemove(kvArray, key, value = null) {
    let indexesToRemove = [];
    $.each(kvArray, function (i, pair) {
      if (pair[0] === key && (value === null || pair[1] === value)) {
        indexesToRemove.push(i);
      }
    });
    $.each(indexesToRemove, function (i, key) {
      kvArray.splice(key, 1);
    });
  }

  /**
   * Replace a key value pair array element based on key ONLY
   * Which means we look for a pair with matching key and expect only one to be present
   * We set the value on that pair and REMOVE all other matching key pairs
   * @param kvArray
   * @param key
   * @param value
   * @param forceStringValues
   */
  uniqueKeyElementSet(kvArray, key, value, forceStringValues = false) {
    const indexesToRemove = [];
    let found = false;
    const newKvArray = kvArray || [];
    $.each(newKvArray, (i, pair) => {
      if (pair[0] === key) {
        if (!found) {
          found = true;
          newKvArray[i] = [key, (forceStringValues ? `${value}` : value)];
        } else {
          indexesToRemove.push(i);
        }
      }
    });
    if (!found) {
      newKvArray.push([key, (forceStringValues ? `${value}` : value)]);
    }
    $.each(indexesToRemove, (i, _key) => {
      newKvArray.splice(_key, 1);
    });
    return newKvArray;
  }

  /**
   * get an element from a key value pair array based on key only
   * return array because we can have multiple
   * @param kvArray
   * @param key
   */
  elementGet(kvArray, key) {
    const found = [];
    $.each(kvArray, (i, pair) => {
      if (pair[0] === key) {
        found.push(pair[1]);
      }
    });
    return found;
  }
}

export default new KeyValuePairArray();
