<template>
  <div :class="{'infinite-scroll-loading-indicator': 1, 'loading': isLoading}">&nbsp;</div>
</template>

<script>
/**
 * @see https://www.netguru.com/codestories/infinite-scroll-with-vue.js-and-intersection-observer
 */

import guiUtil from 'js/app/utils/gui';

export default {
  name: 'InfiniteScrollTrigger',
  props: {
    options: {
      type: Object,
      default() {
        return {
          root: null,
          threshold: 0,
        };
      },
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observer: null,
    };
  },
  watch: {
    isActive: {
      handler(val) {
        if (val === false) {
          this.observer.unobserve(this.$el);
        } else {
          this.observer.observe(this.$el);
        }
      },
    },
    isLoading: {
      handler(val) {
        if (val === true) {
          this.$nextTick(() => {
            guiUtil.blockUI($(this.$el), {centerY: true});
          });
        } else {
          this.$nextTick(() => {
            guiUtil.unblockUI($(this.$el));
          });
        }
      },
      immediate: true, // react also on the initial change (setting from prop)
    },
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      this.handleIntersect(entries[0]);
    }, this.options);

    if (this.isActive) {
      this.observer.observe(this.$el);
    }
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    handleIntersect(entry) {
      if (entry.isIntersecting) this.$emit('trigger-intersected');
    },
  },
};

</script>

<style scoped>
.infinite-scroll-loading-indicator {
  height: 0px;
  padding: 0;
  margin: 0;
}

.infinite-scroll-loading-indicator.loading {
  height: 100px;
  margin-bottom: 50px;
}
</style>
