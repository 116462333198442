<template>
  <div class="ph-item ph-item-product z-widget-offer-item row no-gutters border-bottom mb-6 mb-md-6 pb-3">
    <div class="col-lg-3 mb-4 mb-lg-0">
      <div class="ph-picture ph-h-3"></div>
    </div>
    <div class="col-lg-9 d-lg-flex flex-column pl-lg-4">
      <div class="row justify-content-between mb-5 mb-lg-0">
        <div class="col-md-9 mb-4 mb-md-0">
          <div class="ph-row font-weight-bold text-lh-sm mb-4">
            <div class="ph-col-6 extra-big"></div>
          </div>

          <div class="ph-row text-nowrap align-items-center justify-content-betweeb mb-4">
            <div class="ph-col-2 big"></div>
            <div class="ph-col-2 empty"></div>
            <div class="ph-col-2 big"></div>
            <div class="ph-col-1 empty"></div>
            <div class="ph-col-2 big"></div>
            <div class="ph-col-1 empty"></div>
            <div class="ph-col-2 big"></div>
          </div>

          <div class="ph-row">
            <div class="ph-col-10"></div>
            <div class="ph-col-8"></div>
            <div class="ph-col-12"></div>
            <div class="ph-col-9"></div>
          </div>

        </div>
        <div class="ph-row col-md-3 text-md-right pt-md-2">
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-8 extra-big"></div>
          <div class="ph-col-12 empty"></div>
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-8 extra-big"></div>
          <div class="ph-col-12 empty"></div>
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-8 extra-big"></div>
          <div class="ph-col-12 empty"></div>
        </div>
      </div>

      <div class="text-md-left border-top border-md-top-0 pt-11 pt-md-0 mb-n4 mb-md-0">
        <div class="ph-row mr-md-9">
          <div class="ph-col-12 empty"></div>
          <div class="ph-col-12 empty"></div>
          <div class="ph-col-3 extra-big"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SearchResultsPlaceholder',
  components: {
  },
  props: {
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
