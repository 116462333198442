<script>
import Vue from 'vue';
import Axios from 'axios/index';
import guiUtil from 'js/app/utils/gui';
import HttpUtil from 'js/app/utils/http';
import Translator from 'bazinga-translator';
import JsRouting from 'fos-jsrouting-bundle';
import Parsley from 'parsleyjs';
import Gallery from '../../galleries/models/Gallery';
import xsrfUtil from 'js/app/utils/xsrf-utils';

export default {
  name: 'FooterNewsletterSubscription',
  props: {},
  template: '#x-template-footer-newsletter-subscription',
  data() {
    return {
      email: null,
      csrfToken: null,
    };
  },
  created() {
    this.$form = null;
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$el.nodeName === 'FORM') {
        this.form = this.$el;
      } else {
        this.form = $(this.$el).find('form')[0];
      }
      // init parsley
      this.validator = new Parsley.Factory(this.form, window.ParsleyConfig);
    },

    trans(key, params, domain, multipleCount) {
      if (multipleCount) {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'messages');
      }
      return Translator.trans(key, params || {}, domain || 'messages');
    },

    handleSubmit(event) {
      const self = this;
      const form = event.target;
      if (this.validator.isValid()) {
        guiUtil.blockUI($(this.$refs['teaser']));
        xsrfUtil.fetchCsrfToken('newsletter-subscription').done((token) => {
          self.csrfToken = token;
          // the reactive value above gets replaced after the next line fetchingForm data
          // so the token is empty, in knockout.js we would call ko.tasks.runEarly();
          // workaround for the problem above - setting the value manually (formData.set('_token', token))
          // this.$nextTick(), we chose next tick since we have a deeper datasructure in the formData object
          this.$nextTick(() => {
            const formData = new FormData(form);
            //console.log(formData);
            // now submit the form
            Axios.post(form.action, formData).then((response) => {
              let notificationTitle = null;
              let notificationDescription = null;
              notificationTitle = self.trans('layout.homepage-newsletter-cta.notification.title');
              notificationDescription = self.trans('layout.homepage-newsletter-cta.notification.info');
              window.swal.fire({
                title: notificationTitle,
                text: notificationDescription,
                type: 'success',
                confirmButtonClass: 'btn btn-secondary',
              }).then((result) => {
                self.$router.push({path: '/', params: {}});
              });
            }).catch((error) => {
              HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
                window.swal.fire({
                  title: Translator.trans('gui.error_occured'),
                  text: errorString,
                  type: 'error',
                  confirmButtonClass: 'btn btn-secondary',
                });
              });
            }).then(() => {
              // 2nd then after catch is always executed - catch MUST be present for this to work
              guiUtil.unblockUI($(this.$refs['teaser']));
            });
          });
        }).fail(() => {
          guiUtil.unblockUI($(this.$refs['teaser']));
        }).always(() => {
          guiUtil.unblockUI($(this.$refs['teaser']));
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
