import toastr from 'toastr';
import JsRouting from 'fos-jsrouting-bundle';
import HSUnfold from 'theme/assets/vendor/hs-unfold/dist/hs-unfold';

class GuiUtil {

  constructor() {

  }

  /**
   * Show notification
   */
  showNotification(type, message, title, _options) {

    let def = $.Deferred();
    let options = _options || {};

    if (message.length > 500) {
      message = message.substring(0, 500) + ' [...]';
    }

    options = $.extend({
      "type": type,
      "title": title,
      "text": message,
      "confirmButtonClass": "btn btn-secondary"
    }, options);

    Swal.fire(options).then((result) => {
      def.resolve(result);
    });

    return def;

  }

  /**
   * Show notification
   */
  showToastr(config) {
    config.options = config.options || {};
    config.title = config.title || '';
    config.message = config.message || '';

    if (config.title.length > 500) {
      config.title = config.title.substring(0, 500) + ' [...]';
    }

    toastr.options = $.extend({
      closeButton: false,
      debug: false,
      newestOnTop: true,
      progressBar: false,
      positionClass: 'toast-bottom-center',
      preventDuplicates: false,
      onclick: null,
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    }, config.options);

    let toastrType = null;
    switch (config.type) {
      case 'success':
        toastrType = 'success';
        break;
      case 'info':
        toastrType = 'info';
        break;
      case 'warning':
        toastrType = 'warning';
        break;
      case 'error':
        toastrType = 'error';
        break;
    }
    if (config.message && config.title) {
      toastr[toastrType](config.message, config.title);
    } else if (config.title) {
      toastr[toastrType](config.title);
    } else if (config.message) {
      toastr[toastrType](config.message);
    }
  }

  /**
   * Block UI loading overlay
   * @param elementSelector
   * @param options
   */
  blockUI(elementSelector, options) {
    options = options || {};
    if (elementSelector instanceof jQuery) {
      elementSelector = elementSelector[0];
    }

    const blockUiOptions = jQuery.extend(
      {
        overlayColor: '#000',
        opacity: 0.1,
        state: 'primary', // a bootstrap color
        size: 'lg',
        centerX: true,
        centerY: false,
        shadow: false,
      },
      options,
    );
    window.KTApp.block(elementSelector, blockUiOptions);
  }

  /**
   * Block UI loading overlay
   * @param elementSelector
   */
  unblockUI(elementSelector) {
    if (elementSelector instanceof jQuery) {
      elementSelector = elementSelector[0];
    }
    window.KTApp.unblock(elementSelector);
  }

  /**
   * Highlight form validation errors using parsley
   * after the form has been submitted using AJAX
   * and validation errors have been returned as JSON object
   * @param xhr
   * @param formNamespace
   */
  symfonyFormHiglightValidationJsonErrors(xhr, formNamespace) {

    var errorsJSON = xhr.responseJSON;
    var invalidField = null, fieldFormName = null, $invalidField = null;
    formNamespace = formNamespace || '';

    if (typeof errorsJSON === 'undefined' || typeof errorsJSON.form === 'undefined') {
      return false;
    }

    // iterate over the form children and look for errors
    ko.utils.objectForEach(errorsJSON.form.children, function (fieldName, fieldValidationData) {
      if (typeof fieldValidationData.errors === 'object' && Array.isArray(fieldValidationData.errors) && fieldValidationData.errors.length > 0) {
        if (formNamespace > '') {
          fieldFormName = formNamespace + '[' + fieldName + ']';
        } else {
          fieldFormName = fieldName;
        }
        $invalidField = $('[name="' + fieldFormName + '"]');
        invalidField = $invalidField.parsley();

        // set custom error on the field with error
        window.ParsleyUI.addError(invalidField, "remoteError", fieldValidationData.errors[0]);

        // set a click event to remove the custom error when field clicked
        $invalidField.on('change', function () {
          window.ParsleyUI.removeError(invalidField, "remoteError");
        });
      }
    });

  }

  /**
   * load HTML part using AJAX
   * @param url
   * @param data
   */
  ajaxGetHTML(url, data) {

    var def = $.Deferred();

    $.ajax({
      type: 'GET',
      url: url,
      data: data,
      beforeSend: function () {
      },
      success: function (data, textStatus, jqXHR) {
        def.resolve(data, textStatus, jqXHR);
      },
      error: function (xhr, textStatus, error) {
        def.reject(xhr, textStatus, error);
      }
    });

    return def;

  }

  /**
   * load JSON using AJAX
   * @param url
   * @param successCallback
   * @param errorCallback
   */
  ajaxGetJson(url, successCallback, errorCallback) {

    var deferred = $.Deferred();

    /*
     * Throw the form values to the server!
     */
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: url,
      beforeSend: function () {
      },
      success: function (data, textStatus, jqXHR) {
        deferred.resolve(data, textStatus, jqXHR);
        if (typeof successCallback !== 'undefined') {
          successCallback(data, textStatus, jqXHR);
        }
      },
      error: function (xhr, textStatus, error) {
        deferred.reject(xhr, textStatus, error);
        if (typeof errorCallback !== 'undefined') {
          errorCallback(xhr, textStatus, error);
        }
      }
    });

    return deferred;

  }

  /**
   * load HTML part using AJAX and put into a container on the page
   * @param url
   * @param ajaxTarget
   * @param data
   */
  ajaxLoadHtml(url, ajaxTarget, data) {

    data = data || {};

    var deferred = $.Deferred();

    this.ajaxGetHTML(url, data).done((data, textStatus, jqXHR) => {

      if (typeof ajaxTarget === 'string') {
        // ajaxTarget is a HTML ID
        $('#' + ajaxTarget).html(data);
      } else {
        // ajaxTarget is a jquery object
        ajaxTarget.html(data);
      }

      deferred.resolve(data, textStatus, jqXHR);
    }).fail((xhr, textStatus, error) => {

      deferred.reject(xhr, textStatus, error);
    });

    return deferred;

  }

  /**
   * Make the window title bar blink if window is not active
   * by changing the window title for 10 seconds
   * @see http://blog.chatwee.com/2013/10/how-to-make-browser-window-blink-a-notification-to-the-users/
   */
  notifyWindowTitle(notification) {

    let originalTitle;
    let blinkTitle;
    let blinkLogicState = false;
    let blinkHandler;

    function StartBlinking(title) {
      originalTitle = document.title;
      blinkTitle = title;
      BlinkIteration();
    }

    function BlinkIteration() {
      if (blinkLogicState === false) {
        document.title = blinkTitle;
      } else {
        document.title = originalTitle;
      }

      blinkLogicState = !blinkLogicState;
      blinkHandler = setTimeout(BlinkIteration, 1000);
    }

    function StopBlinking() {
      if (blinkHandler) {
        clearTimeout(blinkHandler);
      }

      document.title = originalTitle;
    }

    let oldTitle = document.title;
    if (oldTitle && !window.appWindowIsActive) {
      StartBlinking(notification);
      // stop blinking after 10 sec
      setTimeout(() => {
        StopBlinking();
      }, 10000);
    }
  }

  playNotificationSound() {
    let audio = new Audio('/assets/global/definite.mp3');
    const playPromise = audio.play();
    if (playPromise !== null) {
      playPromise.catch(() => {
      })
    }
  }

  /**
   * init
   * @param $form
   * @param forcePut
   * @param forceSync
   * @param callbackBefore
   * @param supportHtml5FileUpload
   */
  postForm($form, forcePut, forceSync, callbackBefore, supportHtml5FileUpload) {

    if (typeof forceSync == 'undefined') {
      forceSync = false;
    }

    if (typeof forcePut == 'undefined') {
      forcePut = false;
    }
    supportHtml5FileUpload = supportHtml5FileUpload || false;

    var deferred = $.Deferred();
    var method = forcePut ? 'PUT' : $form.attr('method').toUpperCase();
    var formData = null;

    if (supportHtml5FileUpload == true) {
      /**
       * Using FormData here limits the compatibility to IE 10 +
       * since this is a XMLHTTPRequest2 object and supported only by
       * HTML 5 complient browsers.
       * I am using it here because this is the only way to submit
       * files using AJAX.
       */
      formData = new FormData($form[0]);
    } else {
      /**
       * This method is cross browser compatible but
       * does not support file uploads.
       * Using a jQuery file uploader plugin must
       * be considered to handle file uploads.
       */
      formData = $form.serializeArray();
    }

    /**
     * Remove the symfony HTTP form PUT method workaround (forms do not support PUT)
     * which is to put an extra hidden field "_method" with value "PUT" int othe form
     * sent otherwise using POST, the correct method will be resolved while receiving
     * the request.
     * Now we send the data using AJAX and can use the PUT method natively, so we must
     * remove the extra field which causes validation errors otherwise (no extra fields allowed!)
     */
    $.each(formData, function (index, fieldData) {
      if (fieldData.name == '_method') {
        if (method == 'PUT') {
          // here we have already PUT as method so we do not need the workaround
          // and we remove the _method field from data array
          formData.splice(index, 1);
        } else if (method == 'POST') {
          // Here we must check if the form is configured to use the symfony PUT workaround
          // in which case the method is POST and an additional form field '_method=PUT' is present.
          // If this is the case, we force PUT method for our AJAX call.
          if (fieldData.value == 'PUT') {
            method = 'PUT';
          }
        }
        // go out of the $.each method to continue
        return false;
      }
    })


    // fetch form action
    var formAction = $form.attr('action');

    /*
     * Throw the form values to the server!
     */
    $.ajax({
      type: method,
      url: formAction,
      async: !forceSync,
      data: formData,
      dataType: 'json',
      cache: false,
      contentType: (supportHtml5FileUpload == true ? false : 'application/x-www-form-urlencoded; charset=UTF-8'),
      processData: (supportHtml5FileUpload == false),
      beforeSend: function () {
        if (typeof callbackBefore == 'function') callbackBefore();
      },
      success: function (data, textStatus, jqXHR) {
        deferred.resolve(data, textStatus, jqXHR);
      },
      error: function (xhr, textStatus, error) {
        deferred.reject(xhr, textStatus, error);
      }
    });

    return deferred;

  }

  /**
   *
   * @param swalCustomOptions
   * @param loginViaUrl Some url requiring user session.
   * We will use loginViaUrl with the "login" button to make use of symfony's built in redirection functionality
   */
  showLoginExclusiveFeatureInfo(swalCustomOptions, loginViaUrl) {
    let url = JsRouting.generate('app.why_register', {}, true);
    let options = {
      title: Translator.trans('app.login_required_feature.title', {}),
      html: Translator.trans('app.login_required_feature.message', {'link': url}),
      type: "info",
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: Translator.trans('security.login.header', {}, 'FOSUserBundle'),
      confirmButtonClass: "btn btn-brand btn-primary",
      cancelButtonText: Translator.trans('dict.cancel', {}),
      cancelButtonClass: "btn btn-secondary"
    };
    options = $.extend(options, swalCustomOptions);
    swal.fire(options).then((dismissReason) => {
      switch (dismissReason.value) {
        case true:
          let url = JsRouting.generate('fos_user_security_login', {}, true);
          // We will use loginViaUrl with the "login" button
          // to make use of symfony's built in redirection functionality
          url = loginViaUrl ? loginViaUrl : url;
          window.location.href = url;
          break;
        case false:
          break;
      }
    });
  }

  /**
   * Initialize the cookie policy message
   * @param policyUrl
   */
  initCookieMessage(policyUrl) {

    var translator = Translator;

    $(document).ready(() => {

      // init cookiebar
      $.cookieBar({
        'fixed': true,
        'zindex': 10000,
        'element': 'body',
        'append': true,
        'message': translator.trans('cookiebar.message', {}, 'app') + ' <a href="' + policyUrl + '">Polityce cookies</a>.',
        'acceptText': translator.trans('cookiebar.i_understand', {}, 'app')
      });

    });

  }

  /**
   * Init HtmlStream Front Unfold
   * @param rootEl
   */
  initHsUnfold(rootEl) {
    $(rootEl).find('.js-hs-unfold-invoker').each((el, val) => {
      new HSUnfold($(val)).init();
    });
  }

  /**
   * A better implementation of el.scrollIntoView() with possible top offset
   * @see https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far
   * @param el
   * @param topOffset
   * @param options
   */
  scrollIntoView(el, topOffset, options) {
    const yOffset = topOffset || 0;
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    // using vanilla JavaScript but options variant, which allows smooth scrolling is not supported on IE
    //const _options = $.extend({top: y}, options);
    //window.scrollTo(_options);
    // jquery alternative
    $('html, body').stop().animate({
      scrollTop: $(el).offset().top - yOffset,
    }, 500, 'swing', () => {
      // callback when done scrolling
    });
  }

  // /**
  //  *
  //  * @param $el
  //  * @param clickHandler
  //  */
  // addFormOverlay($el, clickHandler) {
  //   const options = {
  //     opacity: 0.5,
  //     overlayColor: '#fff',
  //     type: '',
  //     size: '',
  //     state: 'brand',
  //     centerX: true,
  //     centerY: true,
  //     message: '',
  //     shadow: true,
  //     width: 'auto',
  //   };
  //   const params = {
  //     message: '',
  //     centerY: options.centerY,
  //     centerX: options.centerX,
  //     css: {
  //       top: '30%',
  //       left: '50%',
  //       border: '0',
  //       padding: '0',
  //       backgroundColor: 'none',
  //       width: options.width,
  //     },
  //     overlayCSS: {
  //       backgroundColor: options.overlayColor,
  //       opacity: options.opacity,
  //       cursor: 'default',
  //       zIndex: '10',
  //     },
  //     onUnblock(el) {
  //       if (el && el[0]) {
  //         KTUtil.css(el[0], 'position', '');
  //         KTUtil.css(el[0], 'zoom', '');
  //       }
  //     },
  //     onClick: clickHandler,
  //   };
  //   $el.block(params).on('click', (event) => {
  //     clickHandler();
  //     event.preventDefault();
  //     event.stopPropagation();
  //   });
  //   return $el;
  // }
  //
  // /**
  //  * moved to js/app/mixins/form-block-overlay.mixin.js
  //  * @param $el
  //  */
  // removeFormOverlay($el) {
  //   $el.unblock().off('click');
  //   return $el;
  // }
}

export default new GuiUtil();
