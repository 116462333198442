<template>
  <div class="zero-section-map">
    <div class="zero-section-map--listig">
      <div class="zero-section-map--search">
        <form class="position-relative z-index-2"
              :action="actionUrl"
              autocomplete="off"
              @submit.prevent="submitForm"
        >
          <div v-show="geocoderInitialized" class="input-group">
            <div ref="geocoder" class="geocoder"></div>
            <div class="input-group-append">
              <button class="btn btn-lg btn-primary rounded-right-pill" type="submit"><i class="fas fa-search mr-2"></i>
                <span>Szukaj</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div ref="map" class="rounded h-100"></div>
    </div>
    <search-geocoder-map-filters :filters="filters" @filterset-changed="handleFiltersetChanged" />
  </div>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css';
// Do not use mapbox-gl-geocoder.css if you override the styles heavily in own theme
//import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import xsrfUtils from 'js/app/utils/xsrf-utils';
import JsRouting from 'fos-jsrouting-bundle';
import KeyValuePairArrayUtil from 'js/app/utils/KeyValuePairArray';
import GeojsonUtil from 'js/app/utils/geojson';
import Translator from 'bazinga-translator';
import SearchGeocoderMapFilters from './search--geocoder-map-filters.vue';

export default {
  name: 'SearchGeocoderMap',
  components: {
    SearchGeocoderMapFilters,
  },
  props: {
    latLng: {
      type: Object,
      default() {
        return {
          lat: 51.079019,
          lng: 16.996882,
        };
      },
    },
    radius: {
      type: Number,
      default: 50,
    },
    mapboxApiKey: {
      type: String,
      default: '',
    },
    actionRoute: {
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
    filterConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      spatialPt: '',
      token: '',
      place: '',
      geocoderInitialized: false,
      position: new mapboxgl.LngLat(
        this.latLng.lng,
        this.latLng.lat,
      ),
    };
  },
  computed: {
    apiAvailable() {
      return !(typeof mapboxgl === 'undefined');
    },
    actionUrl() {
      return JsRouting.generate(this.actionRoute, {}, true);
    },
    zoomLevel() {
      // quick and dirty based on hardcoded map
      const map = {
        20: 9,
        50: 8,
        100: 7,
        200: 6,
      };
      return map[this.radius];
    },
  },
  watch: {
    position(lngLat) {
      if (typeof lngLat !== 'undefined') {
        this.map.jumpTo({
          center: new mapboxgl.LngLat(
            lngLat.lng,
            lngLat.lat,
          ),
        });
        this.renderMarker();
        this.renderRadius();
        this.spatialPt = `${lngLat.lat},${lngLat.lng}`;
      }
    },
    latLng: {
      handler(latLng) {
        if (latLng && this.map !== null) {
          this.position = new mapboxgl.LngLat(
            latLng.lng,
            latLng.lat,
          );
        }
      },
      deep: true,
    },
    radius(radiusKm) {
      if (radiusKm && this.map !== null) {
        // now we make a simple zoom to fit the search area into the map
        // try fit this.map.fitBounds() in future
        this.map.zoomTo(this.zoomLevel, {
          animate: false,
          //duration: 100,
        });
      }
    },
  },
  created() {
    this.map = null;
    this.geocoder = null;
    this.marker = null;
    this.defaultZoomLevel = 8;
  },
  mounted() {
    const self = this;
    if ($(this.$el)[0].nodeName === 'FORM') {
      this.$form = $(this.$el);
    } else {
      this.$form = $(this.$el).find('form');
    }
    self.init();
  },
  methods: {
    init() {
      const self = this;

      // check api
      if (!this.apiAvailable) {
        return false;
      }

      this.initMapboxMap().done(() => {
        this.renderMarker();
        this.renderRadius();
        this.initMapboxGeocoder();
      });
      return this;
    },

    trans(key, domain, params, multipleCount) {
        if (multipleCount) {
            return Translator.transChoice(key, multipleCount, params || {}, domain || 'AppProduct');
        } else {
            return Translator.trans(key, params || {}, domain || 'AppProduct');
        }
    },

    /**
     * @see https://docs.mapbox.com/mapbox-gl-js/api/
     */
    initMapboxMap() {
      const def = $.Deferred();
      // initialization of mapbox
      mapboxgl.accessToken = this.mapboxApiKey;
      this.map = new mapboxgl.Map({
        container: this.$refs['map'],
        style: 'mapbox://styles/mapbox/streets-v11',
        center: new mapboxgl.LngLat(
          this.latLng.lng,
          this.latLng.lat,
        ),
        zoom: this.zoomLevel,
      });
      // disable map zoom when using scroll for useability reasons
      // this way user on mobile devices can scroll the page without
      // "map zoom trap" when beeing over the map
      this.map.scrollZoom.disable();

      this.map.on('load', () => {
        def.resolve();
      });

      this.map.on('zoomend', () => {
        this.renderRadius();
      });

      this.map.on('dblclick', (e) => {
        this.position = new mapboxgl.LngLat(
          e.lngLat.lng,
          e.lngLat.lat,
        );
        this.reverseMapboxGeocode(this.position).done(() => {
          this.$emit('geocode', this.position.lat, this.position.lng);
        });
      });

      this.map.zoomTo(this.zoomLevel);

      return def;
    },

    /**
     * @see https://github.com/mapbox/mapbox-gl-geocoder/blob/master/API.md
     */
    initMapboxGeocoder() {
      const self = this;
      mapboxgl.accessToken = this.mapboxApiKey;

      if (!this.$refs['geocoder']) {
        return false;
      }

      this.geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        language: 'pl',
        marker: false,
        zoom: this.zoomLevel,
        flyTo: false, // disable the default flyTo animation (very slow)
        clearAndBlurOnEsc: true, // the geocoder control will clear it's contents and blur when user presses the escape key
        clearOnBlur: false, // the geocoder control will clear its value when the input blurs
        mapboxgl: mapboxgl,
        types: 'place',
        countries: 'pl',
        placeholder: this.trans('form.search.where-do-you-want-to-search'),
        // render(carmenGeoJson) {
        //   return carmenGeoJson.text;
        // },
      });

      this.$refs['geocoder'].append(this.geocoder.onAdd(this.map));

      // autocomplete listener
      this.geocoder.on('result', (e) => {
        self.place = null;
        const {result} = e;
        const resultType = result.id.split('.')[0];
        switch (resultType) {
          case 'place':
            self.place = result.text;
            break;
          case 'address':
            self.place = result.text + (result.address ? ` ${result.address}` : '');
            break;
          case 'poi':
            self.place = result.properties.address;
            break;
          case 'district':
            self.place = result.text;
            break;
          default:
            self.place = '';
        }
        self.position = new mapboxgl.LngLat(
          result.geometry.coordinates[0],
          result.geometry.coordinates[1],
        );
        this.$emit('geocode', self.position.lat, self.position.lng, self.place);
      });

      // important to set this flag
      this.geocoderInitialized = true;

      return true;
    },

    /**
     *
     * @param lngLat mapbox LngLat object
     * @returns {jQuery|*|{}}
     */
    reverseMapboxGeocode(lngLat) {
      // this reference for callback functions
      const self = this;
      const def = $.Deferred();

      // check api
      if (!this.apiAvailable) {
        def.reject();
        return def;
      }

      if (lngLat) {
        console.log(`Mapbox API call: lat=${lngLat.lat}, lng=${lngLat.lng}`);
        // save current query settings
        const tmpLimit = this.geocoder.getLimit();
        const tmpTypes = this.geocoder.getTypes();
        // set query settings for single place retrieval
        this.geocoder.setLimit(1);
        this.geocoder.setTypes('place');
        // do the query
        this.geocoder.query(`${lngLat.lng}, ${lngLat.lat}`);
        // restore current query settings
        this.geocoder.setLimit(tmpLimit);
        this.geocoder.setTypes(tmpTypes);
        def.resolve();
      } else {
        def.reject();
        throw new Error('No coordinates given');
      }
      return def;
    },

    /**
     * show marker
     * @param mapboxLngLat
     * @param inMapCenter
     */
    renderMarker(mapboxLngLat, inMapCenter) {
      // this reference for callback functions
      const self = this;
      let _mapboxLngLat = null;

      // check api
      if (!this.apiAvailable) {
        return false;
      }

      if (!mapboxLngLat && inMapCenter) {
        _mapboxLngLat = this.map.getCenter();
      } else {
        _mapboxLngLat = mapboxLngLat || new mapboxgl.LngLat(
          this.latLng.lng,
          this.latLng.lat,
        );
      }

      // init main marker
      if (this.marker == null) {
        this.marker = new mapboxgl.Marker({
          color: '#1FB7FD',
        }).setLngLat(_mapboxLngLat)
          .addTo(this.map);
        this.marker.on('dragend', (e) => {
        });
      } else {
        this.marker.setLngLat(_mapboxLngLat);
      }
      return true;
    },

    renderRadius() {
      const sourceId = 'polygon';
      const circleSource = this.map.getSource(sourceId);

      if (!this.map.isStyleLoaded()) {
        // map is not ready for drawing
        return false;
      }

      if (circleSource) {
        circleSource.setData(GeojsonUtil.createGeoJSONCircle(
          [this.latLng.lng, this.latLng.lat],
          this.radius).data);
      } else {
        this.map.addSource(
          sourceId,
          GeojsonUtil.createGeoJSONCircle(
            [this.latLng.lng, this.latLng.lat],
            this.radius),
        );
        this.map.addLayer({
          id: sourceId,
          type: 'fill',
          source: sourceId,
          layout: {},
          paint: {
            'fill-color': 'red',
            'fill-opacity': 0.05,
          },
        });
      }

      return true;
    },

    submitForm() {
      const self = this;
      xsrfUtils.fetchCsrfToken('search').done((token) => {
        self.token = token;
        this.$nextTick(() => {
          // next tick to be sure the token value has been written in dom
          let stateObj = [];
          stateObj = KeyValuePairArrayUtil.uniqueKeyElementSet(stateObj, 'place', self.place, true);
          this.$emit('search-initiated', stateObj);
        });
      }).fail(() => {
        console.error('token fetch failed');
      });
    },

    handleFiltersetChanged(newFilterSet) {
      this.$emit('filterset-changed', newFilterSet);
    },
  },
};
</script>

<style scoped>

</style>
