<template>
  <div class="z-widget-offer-item row no-gutters border-bottom mb-12 mb-md-6 pb-6" v-if="item">
    <div class="col-lg-3 mb-4 mb-lg-0">
      <a v-bind:href="item.thumbnails.thumbnail_large" data-lity>
        <img v-bind:src="item.thumbnails.thumbnail_medium" class="img-fluid w-100" alt="image">
      </a>
    </div>

    <div class="col-lg-9 d-lg-flex flex-column pl-lg-4">
      <div class="row justify-content-between mb-2 mb-lg-0">
        <div class="col-md-9">
          <h3 class="font-weight-bold text-lh-sm mb-4">
            <a :href="localizedUrl(item)" target="_blank" class="link-dark text-center">{{ item.name }}</a>
            <a href="javascript:;" @click.prevent="openShowOnMap(item.hashid)"
               class="btn btn-pill btn-outline-primary btn-xs d-none d-lg-inline">
              {{ 'layout.show_the_location_on_map' | trans({}, 'AppProduct') }}</a>
            <!--a :href="localizedUrl(item)" target="_blank" class="btn btn-pill btn-outline-primary btn-xs"
            >{{ 'dict.view' | trans }}</a-->
          </h3>
          <div class="row text-nowrap align-items-center justify-content-betweeb mb-2 mb-lg-3">
            <div class="col-lg-5 d-lg-flex align-items-center mb-4 mb-lg-0 d-none d-lg-block">
              <a href="javascript:;" @click.prevent="openShowOnMap(item.hashid)"
                 :title="trans('layout.show_the_location_on_map', {}, 'AppProduct')">
                <img class="mr-3" src="/theme/assets/img/icons/marker-primary.svg"
                     :alt="trans('layout.show_the_location_on_map', {}, 'AppProduct')">&nbsp;{{ item.address_zip }}
                {{ item.address_city }}
              </a>
            </div>
            <div class="col-lg-7 text-lg-right">
              <div class="row">
                <div class="col-12 col-md d-flex align-items-center mb-2 mb-lg-0 d-lg-none">
                  <a href="javascript:;" @click.prevent="openShowOnMap(item.hashid)"
                     :title="trans('layout.show_the_location_on_map', {}, 'AppProduct')">
                    <img class="mr-3" src="/theme/assets/img/icons/marker-primary.svg"
                         :alt="trans('layout.show_the_location_on_map', {}, 'AppProduct')">&nbsp;{{ item.address_zip }}
                    {{ item.address_city }}
                  </a>
                </div>
                <div class="col-4 col-md d-flex align-items-center mb-2 mb-lg-0">
                  <a href="javascript:;" @click.prevent="openShowOnMap(item.hashid)"
                     :title="trans('layout.show_the_location_on_map', {}, 'AppProduct')">
                    <img class="mr-3" src="/theme/assets/img/icons/address-primary.svg"
                         :alt="trans('layout.show_the_location_on_map', {}, 'AppProduct')">&nbsp;{{ formatGeoDistance(item.distance) }}
                  </a>
                </div>
                <div class="col-4 col-md d-flex align-items-center mb-2 mb-lg-0">
                  <img class="mr-3" src="/theme/assets/img/icons/star-primary.svg" alt="Icon">
                  {{ item.rating ? item.rating : '-' }}
                </div>
                <div class="col-4 col-md d-flex align-items-center mb-2 mb-lg-0">
                  <img class="mr-3" src="/theme/assets/img/icons/like-primary-2.svg" alt="Icon"> {{
                    item.stats.favorites
                  }}
                </div>
                <!--div class="col-md d-flex align-items-center mb-4 mb-md-0">
                  <smile-rating
                    type="mini"
                    show-review-count
                    :review-count="item.stats.reviews"
                    :rating="item.rating"></smile-rating>
                </div-->
              </div>
            </div>
          </div>
          <p class="font-family-book">
            {{ item.description | truncate(300, '...') }}
          </p>
        </div>
        <search-results-item-stats :stats="item.stats"></search-results-item-stats>
      </div>

      <div
        class="d-md-flex mt-md-auto align-items-center text-center text-md-left border-top border-md-top-0 pt-11 pt-md-0 mb-n4 mb-md-0">
        <div class="mr-md-9">
          <a :href="localizedUrl(item)"
             class="btn btn-sm btn-wide btn-primary btn-pill font-weight-bold"
             target="_blank">{{ 'dict.view' | trans }}</a>
        </div>
        <price-starting-from-display
          price-key="global"
          :prices="item.prices_min.default"
        ></price-starting-from-display>
      </div>
    </div>
  </div>
</template>

<script>
import SmileRating from 'js/app/components/smile-rating';
import PriceStartingFromDisplay from 'js/product-pricing/components/price-starting-from-display.vue';
import JsRouting from 'fos-jsrouting-bundle';
import SearchResultsItemStats from './search--results.item.stats.vue';
import 'lity';
import 'css/lity.scss';
import Translator from 'bazinga-translator';
import '@fancyapps/fancybox/dist/jquery.fancybox';

export default {
  name: 'SearchResultsItem',
  components: {
    SmileRating,
    PriceStartingFromDisplay,
    SearchResultsItemStats,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {

  },
  methods: {
    trans(key, params, domain, multipleCount) {
      if (multipleCount) {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'AppProduct');
      } else {
        return Translator.trans(key, params || {}, domain || 'AppProduct');
      }
    },

    localizedUrl(item) {
      let res;
      let url = null;
      item.detail_view_url.forEach((urlData, index) => {
        res = urlData.split('||');
        if (res[0] === document.documentElement.lang) {
          url = res[1];
        }
      });
      return url;
    },

    /**
     * Calculate the distance using google API
     * @param location
     * @returns {string}
     */
    getGeoDistance(location) {
      let distance = null;
      if (this.googleApiAvailable() && this.userPosition) {
        let to = new google.maps.LatLng(location.latitude, location.longitude);
        let from = new google.maps.LatLng(this.userPosition.coords.latitude, this.userPosition.coords.longitude);
        let distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(to, from);
        distance = (Math.round((distanceInMeters / 1000) * 10) / 10) + ' km';
      }
      return distance;
    },

    formatGeoDistance(floatKm) {
      let distanceInMeters = floatKm * 1000;
      let distance = (Math.round((distanceInMeters / 1000) * 10) / 10) + ' km';
      return distance;
    },

    openShowOnMap(hashId) {
      $.fancybox.open({
        src: JsRouting.generate('app_show_on_google_map', {id: hashId}, true),
        type: 'iframe',
        opts: {
          afterShow(instance, current) {
            console.info('done!');
          },
          iframe: {
            css: {
              width: '80%',
              height: '80%',
            },
          },
        },
      });
    },

  },
};
</script>

<style scoped>

</style>
