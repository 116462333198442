<template>
  <!--begin: Notifications -->
  <div class="kt-notification" ref="kt-notifications-panel">
     <span v-if="notifications.models.length == 0" class="kt-notification__item">
        <div class="kt-notification__item-details">{{ 'notification.no_new_notifications'|trans }}</div>
     </span>
    <a v-if="notifications.models.length > 0" v-for="notification in notifications.models" href="#"
       :class="'kt-notification__item kt-notification__item--' + (notification.is_read?'read':'unread')"
       @click="function(e) {return itemClicked(notification, e)}">
      <div class="kt-notification__item-icon">
        <i :class="getNotificationIcon(notification) + ' kt-font-success'"></i>
      </div>
      <div class="kt-notification__item-details">
        <div class="kt-notification__item-title">
          <span v-html="getNotificationTitle(notification)">---</span>
        </div>
        <div class="kt-notification__item-time">
          {{ notification.timestamp | age }}&nbsp;
          <span v-for="(action, index) in getActions(notification)"
                @click="actionClick(action, notification)"
                :class="'btn btn-label-'+action.css_class">{{ ('notification.action.' + index)|trans }}</span>
        </div>
      </div>
    </a>
  </div>
  <!--end: Notifications -->
</template>

<script>
import Notification from "js/app/models/Notification";
import NotificationCollection from "js/app/models/NotificationCollection";
import JsRouting from 'fos-jsrouting-bundle';
import Axios from "axios";
import securityUtil from "js/app/utils/security";
import globalConfig from 'js/app/config/app';
import guiUtil from "js/app/utils/gui";
import HttpUtil from 'js/app/utils/http';
import {EventBus} from 'js/app/utils/EventBus';

export default {
  name: 'AsidePanelUserNotifications',
  props: {
    guiBlockId: null,
  },
  data() {
    return {
      notifications: new NotificationCollection()
    };
  },

  computed: {
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    },
    unreadCount() {
      let count = 0;
      $.each(this.notifications.models, function (key, val) {
        if (val && !val.is_read) count++;
      });
      return count;
    },
  },

  watch: {},

  created() {
    let self = this;
    // @see https://alligator.io/vuejs/global-event-bus/
    EventBus.$once('quick-panel-load-notifications', () => {
      guiUtil.blockUI('#' + self.guiBlockId);
      this.fetchNotifications().done((data) => {
        guiUtil.unblockUI('#' + self.guiBlockId);
      });
    });
  },

  mounted() {
    try {
      this.init();
    } catch (error) {
      throw new Error(error);
    }
  },

  methods: {
    init() {
      const self = this;

      if (this.isAuthenticated) {
        // // subscribe for mercure events
        // const topic = 'notifications_' + securityUtil.getAuthenticatedUserHash();
        // const esUri = globalConfig.mercureHub + '?topic=' + encodeURIComponent(topic);
        // const es = new EventSource(esUri);
        // es.onmessage = e => {
        //     // Will be called every time an update is published by the mercure server
        //     self.fetchNotifications();
        // }
      }
    },

    trans(key, domain, params) {
      return Translator.trans(key, params || {}, domain || 'messages');
    },

    /**
     *
     */
    itemClicked(notification, e) {
      let targetEl = e.target;
      let aWrapper = $(targetEl).closest('.kt-notification__item');

      if (e.target.nodeName === 'DIV' || e.target.nodeName === 'SPAN' || e.target.nodeName === 'I') {
        //console.log('main clicked ' + e.target);
        guiUtil.blockUI(aWrapper);
        this.markNotificationAsRead(notification).done(() => {
        }).fail(() => {
        }).always(() => {
          guiUtil.unblockUI(aWrapper);
        });
      } else {
        //console.log('child clicked ' + targetEl);
      }

      if (typeof notification.url !== 'undefined' && notification.url > '') {
        let url = notification.url;
        // delete this notification from local storage
        //this.notifications = this.localStorageDelete(notification);
        document.location.replace(url);
        return false;
      }

    },

    /**
     * Fetch new notifications from remote
     */
    fetchNotifications() {
      let def = $.Deferred();
      const self = this;
      let url = JsRouting.generate('webapi_user_notifications_cget', {}, true);
      // Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
      // Axios.defaults.withCredentials = true;
      // Axios.defaults.credentials = 'same-origin';
      Axios.get(url).then((response) => {
        if (response.data) {
          let col = new NotificationCollection();
          $.each(response.data, function (key, val) {
            //console.log('incomming notification');
            // col.add(new Notification({
            //     'id': val.id,
            //     'url': val.url,
            //     'data': val.data,
            //     'title': val.title,
            //     'timestamp': val.created_at,
            //     'type': val.type,
            //     'is_read': val.is_read,
            // }));
          });
          self.notifications = col;
          if (response.data.length > 0 && self.notificationCount > 0) {
            guiUtil.notifyWindowTitle(response.data.length + ' new messages');
            guiUtil.playNotificationSound();
          }
        }
        def.resolve();
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
        });
        def.reject();
      });
      return def;
    },

    markNotificationAsRead(notification) {
      let def = $.Deferred();
      const self = this;
      let url = JsRouting.generate('webapi_user_notification_mark_read', {id: notification.id}, true);
      Axios.put(url).then((response) => {
        $.each(self.notifications.models, function (key, val) {
          if (val === notification) val.is_read = true;
        });
        def.resolve();
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
        });
        // do nothing
        def.reject();
      }).then(() => {
      });
      return def;
    },

    getNotificationTitle(notification) {
      return this.trans(notification.title, 'messages', notification.data.translation_parameters.title);
    },

    getNotificationIcon(notification) {
      let icon = 'flaticon-alert';
      switch (notification.type) {
        case 'notification.new_product_connection_request':
          icon = 'flaticon-network';
          break;
        case 'notification.new_message':
          icon = 'flaticon2-email';
          break;
        default:
          icon = 'flaticon-alert';
      }
      return icon;
    },

    // /**
    //  * Update local storage notification bag
    //  * @param newNotifications
    //  */
    // localStorageUpdate(newNotifications) {
    //
    //   // save new notifications in local storage
    //   let currentItems = JSON.parse(window.localStorage.getItem('notifications')) || {};
    //   $.each(newNotifications, function(key,val) {
    //     if (!currentItems[val.id]) {
    //       currentItems[val.id] = val;
    //     }
    //   });
    //   window.localStorage.setItem('notifications', JSON.stringify(currentItems));
    //
    //   let col = new NotificationCollection();
    //   $.each(currentItems, function(key,val) {
    //     col.add(new Notification({
    //       'id': val.id,
    //       'url': val.url,
    //       'data': val.data,
    //       'title': val.title,
    //       'timestamp': val.created_at,
    //       'type': val.type
    //     }));
    //   });
    //
    //   return col;
    // },
    //
    // /**
    //  * Delete item from local storage
    //  * @param notification
    //  */
    // localStorageDelete(notification) {
    //   let currentItems = JSON.parse(window.localStorage.getItem('notifications')) || {};
    //   delete currentItems[notification.id];
    //   window.localStorage.setItem('notifications', JSON.stringify(currentItems));
    //
    //   let col = new NotificationCollection();
    //   $.each(currentItems, function(key,val) {
    //     col.add(new Notification({
    //       'id': val.id,
    //       'url': val.url,
    //       'data': val.data,
    //       'label': val.label,
    //       'timestamp': val.created_at,
    //       'type': val.type
    //     }));
    //   });
    //
    //   return col;
    // },

    getActions(notification) {
      let actions = {};
      let cssClassMap = {
        accept: 'success',
        reject: 'danger'
      };
      if (typeof notification.data.actions !== 'undefined') {
        $.each(notification.data.actions, function (key, val) {
          actions[key] = {
            css_class: cssClassMap[key],
            url: val.url,
            ajax: val.ajax
          };
        });
      }
      return actions;
    },

    /**
     * An action button has been clicked,
     * do what it is supposed to do and mark this notification as read
     * @param action
     * @param notification
     */
    actionClick(action, notification) {
      let self = this;
      if (action.ajax) {
        guiUtil.blockUI(self.$refs['kt-notification-dropdown']);
        Axios.get(action.url).then((response) => {
          //self.notifications = this.localStorageDelete(notification);
        }).catch((error) => {
          HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
          });
          // do nothing
        }).then(() => {
          guiUtil.unblockUI(self.$refs['kt-notification-dropdown']);
        });
      } else {
        //this.notifications = this.localStorageDelete(notification);
        document.location.replace(action.url);
      }
      this.markNotificationAsRead(notification);
    }

  }
}
</script>

<style scoped>

</style>
