class GeometryUtil {

  constructor() {

  }

  distanceKm(latLng1, latLng2) {
    if ((latLng1.lat === latLng2.lat) && (latLng1.lng === latLng2.lng)) {
      return 0;
    } else {
      var radlat1 = Math.PI * latLng1.lat / 180;
      var radlat2 = Math.PI * latLng2.lat / 180;

      var theta = latLng1.lng - latLng2.lng;
      var radtheta = Math.PI * theta / 180;

      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;

      // calculate km distance
      dist = dist * 1.609344;
      return dist;
    }
  }
}

export default new GeometryUtil();
