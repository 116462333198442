<!--template>
inline-template used
</template-->

<script>
import Axios from 'axios/index';
import HttpUtil from 'js/app/utils/http';
import JsRouting from 'fos-jsrouting-bundle';
import SubjectLikes from 'js/app/components/subject-likes.vue';
import SubjectStats from 'js/app/models/SubjectStats';

export default {
  name: 'ArticleList',
  components: {
    SubjectLikes,
  },
  props: {
    articleIdsJson: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      statsMap: new Map(),
    };
  },
  mounted() {
    this.fetchStats();
  },
  methods: {
    fetchStats() {
      const self = this;
      const ids = JSON.parse(this.articleIdsJson).join(',');
      const url = JsRouting.generate('api_press.articles.stats', {articleIds: ids}, true);

      Axios.get(url).then((response) => {
        if (response.data) {
          let statMap = new Map();
          for (let statDef of response.data) {
            statMap.set(statDef['cms_hashid'], new SubjectStats(statDef.stats));
          }
          self.statsMap = statMap;
        }
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
          self.error = errorString;
        });
      }).then(() => {
        // 2nd then after catch is always executed - catch MUST be present for this to work
      });
    },
  },
};
</script>

<style scoped>

</style>
