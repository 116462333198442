import {Model, Collection} from 'vue-mc';

class PoiEntry extends Model {

  constructor(data) {
    super(data);
    this.init();
  }

  // Default attributes that define the "empty" state.
  defaults() {
    return {
      hashid: '',
      name: '',
      copyright: '',
      category: '',
      category_name: '',
      coordinates: '',
      review_status: '',
    };
  }

  // Attribute mutations.
  mutations() {
    return {
      category: (categoryData) => {
        let returnValue;
        if (typeof categoryData === 'object') {
          returnValue = categoryData['code'];
          this.category_name = categoryData['name'];
        } else returnValue = categoryData;
        return returnValue;
      },
    };
  }

  // Attribute validation
  validation() {
    return {
      hashid: string.and(hashid),
      name: string.and(required),
      category: string.and(required),
      review_status: string.and(required),
      coordinates: required,
    };
  }

  // // Route configuration
  // routes() {
  //   return {
  //     fetch: '/task/{id}',
  //     save:  '/task',
  //   }
  // }

  init() {
    this.on('change', function (options) {
      switch (options.attribute) {
        case 'code':
          break;
      }
    });
  }

}

export default PoiEntry;
