import Axios from 'axios';
import HttpUtil from 'js/app/utils/http';

const InfiniteScrollMixin = {
  data() {
    return {
      scrollPage: 1,
      scrollLimit: 5,
      scrollIsActive: true,
      scrollIsLoading: false,
    };
  },
  computed: {
    scrollOffset() {
      return (this.scrollPage - 1) * this.scrollLimit;
    },
  },
  methods: {
    toggleLoader(flag) {
      this.scrollIsLoading = (flag === true);
    },
    scrollNextPage(url) {
      const self = this;
      const deferred = $.Deferred();

      // advance the page count
      this.scrollPage += 1;

      this.toggleLoader(true);
      Axios.get(url).then((response) => {
        let dataRows = [];
        let dataStats = [];
        if (response.data.stats && response.data.rows) {
          // in case the api delivers an array of records as "rows" and some other info as "stats"
          dataRows = response.data.rows;
          dataStats = response.data.stats;
        } else {
          // in case the api delivers directly an array of records
          dataRows = response.data;
        }
        if (dataRows && dataRows.length > 0) {
          deferred.resolve(dataRows, dataStats);
          if (dataRows.length < self.scrollLimit) {
            // we reached and of collection so we stop watching endless scroll
            this.scrollIsActive = false;
          }
        } else {
          this.scrollIsActive = false;
          deferred.resolve(null);
        }
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
        });
        deferred.reject(error);
      }).then(() => {
        // 2nd then after catch is always executed - catch MUST be present for this to work
        this.toggleLoader(false);
        deferred.reject();
      });
      return deferred;
    },
  },
};

export default InfiniteScrollMixin;
