var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-primary subject-display-likes" }, [
    _vm.userLikes
      ? _c(
          "a",
          {
            staticClass: "active",
            attrs: {
              href: "javascript:;",
              title: _vm.trans("gui.click_to_stop_liking")
            },
            on: {
              click: function($event) {
                return _vm.removeLike()
              }
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "mb-1 mr-3",
                attrs: {
                  id: "like",
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "17.124",
                  height: "14.983",
                  viewBox: "0 0 17.124 14.983"
                }
              },
              [
                _c("path", {
                  attrs: {
                    id: "Path_418",
                    "data-name": "Path 418",
                    d:
                      "M0,1.07V9.632A1.07,1.07,0,0,0,1.07,10.7H4.281V0H1.07A1.07,1.07,0,0,0,0,1.07Z",
                    transform: "translate(0 4.281)",
                    fill: "#fff"
                  }
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "Path_419",
                    "data-name": "Path 419",
                    d:
                      "M9.8,5.381H6.635a.535.535,0,0,1-.468-.795L7.281,2.579A1.6,1.6,0,0,0,6.267.242l-.786-.2a.534.534,0,0,0-.527.161L.686,4.948A2.674,2.674,0,0,0,0,6.738v5.6a2.679,2.679,0,0,0,2.676,2.676H8a2.682,2.682,0,0,0,2.58-1.968L11.722,7.8A1.976,1.976,0,0,0,9.8,5.381Z",
                    transform: "translate(5.351 -0.03)",
                    fill: "#fff"
                  }
                })
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.userLikes
      ? _c(
          "a",
          {
            attrs: {
              href: "javascript:;",
              title: _vm.trans("gui.click_to_start_liking")
            },
            on: {
              click: function($event) {
                return _vm.addLike()
              }
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "mb-1 mr-3",
                attrs: {
                  id: "like",
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "17.124",
                  height: "14.983",
                  viewBox: "0 0 17.124 14.983"
                }
              },
              [
                _c("path", {
                  attrs: {
                    id: "Path_418",
                    "data-name": "Path 418",
                    d:
                      "M0,1.07V9.632A1.07,1.07,0,0,0,1.07,10.7H4.281V0H1.07A1.07,1.07,0,0,0,0,1.07Z",
                    transform: "translate(0 4.281)",
                    fill: "#fff"
                  }
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "Path_419",
                    "data-name": "Path 419",
                    d:
                      "M9.8,5.381H6.635a.535.535,0,0,1-.468-.795L7.281,2.579A1.6,1.6,0,0,0,6.267.242l-.786-.2a.534.534,0,0,0-.527.161L.686,4.948A2.674,2.674,0,0,0,0,6.738v5.6a2.679,2.679,0,0,0,2.676,2.676H8a2.682,2.682,0,0,0,2.58-1.968L11.722,7.8A1.976,1.976,0,0,0,9.8,5.381Z",
                    transform: "translate(5.351 -0.03)",
                    fill: "#fff"
                  }
                })
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("span", {}, [_vm._v(_vm._s(_vm.likeCount))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }