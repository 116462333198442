import {Model, Collection} from 'vue-mc';
import JsRouting from 'fos-jsrouting-bundle';

class Gallery extends Model {

  constructor(data) {
    super(data);
    this.init();
  }

  // Default attributes that define the "empty" state.
  defaults() {
    return {
      name: '',
      description: '',
      image_urls: []
    };
  }

  // Attribute mutations.
  mutations() {
    return {
      name: String,
      description: String,
      // start: (start) => {
      //   if (start) {
      //     return moment(start).format(globalConfig.moment.dateFormat);
      //   }
      // }
    };
  }

  // Attribute validation
  validation() {
    return {
      name: string.and(required),
    };
  }

  // // Route configuration
  // routes() {
  //   return {
  //     fetch: '/task/{id}',
  //     save:  '/task',
  //   }
  // }

  init() {
    this.on('change', function (options) {
      switch (options.attribute) {
        case 'name':
          if (!(options.value > '')) {
            throw new Error('Attribute "name" can not be empty');
          }
          //console.log(options.previous + ' => ' + options.value);
          break;
      }
    });
  }

  getShowUrl() {
    return JsRouting.generate('app_media_gallery_view', {id: this.get('id')});
  }

  getEditUrl() {
    return JsRouting.generate('app_media_gallery_edit', {id: this.get('id')});
  }
}

export default Gallery;
