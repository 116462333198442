<template>
  <div class="row mapbox-geocoder mapbox-geocoder--current-location-finder">
    <div class="col-sm-12 col-lg-12">
      <div ref="geocoder-map" class="geocoder--map" style="width:100%"/>
      <div ref="geocoder" class="geocoder--input"/>
    </div>
  </div>
</template>

<script>
import Translator from 'bazinga-translator';
import 'babel-polyfill'; // needed to support "async + await"
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';
// Do not use mapbox-gl-geocoder.css if you override the styles heavily in own theme
//import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import geometryUtil from 'js/app/utils/geometry';
import generalUtil from 'js/app/utils/general';
import globalConfig from 'js/app/config/app';

export default {
  name: 'CurrentLocationFinderMapboxGl',
  components: {},
  directives: {},
  props: {
    config: {
      type: Object,
      default() {
        return {
          reverseGeocodeOnFocus: false, // perform automatic reverse geocoding if input field focused
        };
      },
    },
    mapboxApiKey: {
      type: String,
      default: '',
    },
    initLocation: {
      type: Object,
      default: {
        longitude: 0,
        latitude: 0,
      },
    },
  },
  data() {
    return {
      geoCity: '',
      geoCountry: '',
      geoAddress: '',
      mapAddress: '',
      map: null,
      mapInitialized: false,
      mainPointPosition: null,
    };
  },
  computed: {
    apiAvailable() {
      return !(typeof mapboxgl === 'undefined');
    },
    storedLocation() {
      const lastData = this.localStorageGet();
      let location = null;
      if (lastData) {
        location = lastData.location;
      }
      return location;
    },
    storedAddress() {
      const lastData = this.localStorageGet();
      let address = null;
      if (lastData) {
        address = lastData.address;
      }
      return address;
    },
  },
  watch: {
    mainPointPosition(lngLat) {
      if (typeof lngLat !== 'undefined') {
        this.map.panTo(lngLat);
        this.renderMarker();
        this.localStorageUpdate();
        this.$emit('geocode', lngLat.lat, lngLat.lng);
      }
    },
  },
  created() {
    this.map = null;
    this.autocomplete = null;
    this.geocoder = null;
    this.defaultZoom = 12;
    this.browserLocation = null;
  },
  mounted() {
    try {
      const self = this;
      this.init();
      this.$on('repaint-map', () => {
        // very important (at least for mapboxgl) to listen for map resize/repaint triggers
        // which should be fired sometimes eg. after tab switch or other UI dynamics
        self.map.resize();
      });
    } catch (error) {
      throw new Error(error);
    }
  },
  beforeDestroy() {
    // remove listener to avoid multiple registrations
    this.$off('repaint-map');
  },
  methods: {
    init() {
      const self = this;
      generalUtil.browserGeolocation().done((coordinates) => {
        self.browserLocation = coordinates;

        if (coordinates != null) {
          // use current location data from the browser
          self.mainPointPosition = new mapboxgl.LngLat(
            coordinates.lng,
            coordinates.lat,
          );
        } else if (typeof self.storedAddress !== 'undefined') {
          // use location data form local storage
          self.mapAddress = self.storedAddress;
          self.mainPointPosition = new mapboxgl.LngLat(
            self.storedLocation.lng,
            self.storedLocation.lat,
          );
        } else {
          // No geolocation data available, use system wide reference location
          self.mainPointPosition = new mapboxgl.LngLat(
            globalConfig.fallbackCoordinates.longitude,
            globalConfig.fallbackCoordinates.latitude,
          );
          swal.fire({
            title: this.trans('product.search.geolocation-missing.warning'),
            text: this.trans('product.search.geolocation-missing.description'),
            type: 'info',
            showCloseButton: true,
            confirmButtonClass: 'btn btn-secondary',
          });
        }

        /**
         * init leaflet map
         */
        self.initializeMapMapboxGl().done(() => {
          self.initializeGeocoder().done(() => {
            // if (self.mapAddress) {
            //     $('.mapboxgl-ctrl-geocoder--input').val(self.mapAddress);
            // }
            // // set map and autocomplete listeners
            self.setListeners();
            self.mapInitialized = true;
          });
        }).fail((error) => {
          console.error(`Mapbox Map could not be initialized: ${error}`);
        });
      });
    },

    /**
     *
     */
    trans(key, params, domain, multipleCount) {
      if (multipleCount) {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'AppProduct');
      }
      return Translator.trans(key, params || {}, domain || 'AppProduct');
    },

    /**
     * @see https://docs.mapbox.com/mapbox-gl-js/api/
     */
    initializeMapMapboxGl(config) {
      // this reference for callback functions
      const self = this;
      const def = $.Deferred();

      // check api
      if (!this.apiAvailable) {
        def.reject('No API available');
        return def;
      }

      mapboxgl.accessToken = this.mapboxApiKey;
      this.map = new mapboxgl.Map({
        container: this.$refs['geocoder-map'],
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [this.mainPointPosition.lng, this.mainPointPosition.lat],
        zoom: this.defaultZoom,
      });

      // Add zoom controls to the map.
      this.map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        }),
        'bottom-left',
      );

      def.resolve();

      return def;
    },

    /**
     * @see https://github.com/mapbox/mapbox-gl-geocoder/blob/master/API.md
     */
    initializeGeocoder(config) {
      // this reference for callback functions
      const self = this;
      const def = $.Deferred();

      // check api
      if (!this.apiAvailable) {
        def.reject('No API available');
        return def;
      }

      this.geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        limit: 5,
        marker: false,
        flyTo: false, // disable the default flyTo animation (very slow)
        language: 'pl',
        country: 'PL',
        types: 'place,region,locality',
        placeholder: this.trans('product.search.section-title.search-base'),
      });

      // define custom map reaction on geocoding result
      this.geocoder.on('result', (e) => {
        const {result} = e;
        self.map.jumpTo({
          center: result.geometry.coordinates,
          zoom: self.defaultZoom,
        });
      });
      this.$refs.geocoder.appendChild(this.geocoder.onAdd(this.map));

      def.resolve();

      return def;
    },

    /**
     * show marker
     * @param latLng
     */
    renderMarker(latLng) {
      // this reference for callback functions
      const self = this;

      // check api
      if (!this.apiAvailable) {
        return false;
      }

      const _latLng = latLng || this.mainPointPosition;

      // init main marker
      if (this.marker == null) {
        this.marker = new mapboxgl.Marker()
          .setLngLat(this.map.getCenter())
          .addTo(this.map);
        this.marker.on('dragend', (e) => {
        });
      } else {
        this.marker.setLngLat(_latLng);
      }
      return true;
    },

    /**
     * set map and autocomplete listeners
     */
    setListeners() {
      // this reference for callback functions
      const self = this;

      // check api
      if (!this.apiAvailable) {
        return false;
      }

      // autocomplete listener
      this.geocoder.on('result', (e) => {
        self.geoCity = null;
        self.geoAddress = null;
        self.geoCountry = null;

        let contextItemType = null;
        let addressStringComponents = [];
        const {result} = e;
        const resultType = result.id.split('.')[0];

        switch (resultType) {
          case 'place':
            self.geoCity = result.text;
            break;
          case 'address':
            self.geoAddress = result.text + (result.address ? ` ${result.address}` : '');
            break;
          case 'poi':
            self.geoAddress = result.properties.address;
            break;
          case 'district':
            self.geoAddress = result.text;
            break;
        }

        result.context.forEach((item, index) => {
          contextItemType = item.id.split('.')[0];
          switch (contextItemType) {
            case 'place':
              self.geoCity = item.text;
              break;
            case 'country':
              self.geoCountry = item.text;
              break;
          }
        });

        if (self.geoCity) addressStringComponents.push(self.geoCity);
        if (self.geoAddress) addressStringComponents.push(self.geoAddress);
        if (self.geoCountry) addressStringComponents.push(self.geoCountry);

        self.mapAddress = addressStringComponents.join(', ');

        $('.mapboxgl-ctrl-geocoder--input').val(self.mapAddress);

        self.mainPointPosition = new mapboxgl.LngLat(result.geometry.coordinates[0], result.geometry.coordinates[1]);

        self.localStorageUpdate();
      });

      $('.mapboxgl-ctrl-geocoder--input').on('focus', (e) => {
        if (this.config.reverseGeocodeOnFocus === true) {
          if (!self.mapAddress || self.mapAddress === '') {
            // reverse geocode current coordinates only if no address has been fetched so far
            self.reverseGeocodeLatLng(self.mainPointPosition.lat, self.mainPointPosition.lng).done((address) => {
            }).fail(() => {
            });
          } else {
            $('.mapboxgl-ctrl-geocoder--input').val(self.mapAddress);
          }
        }
      });

      return true;
    },

    /**
     *
     * @param latitude
     * @param longitude
     * @returns {jQuery|*|{}}
     */
    reverseGeocodeLatLng(latitude, longitude) {
      // this reference for callback functions
      const self = this;
      const def = $.Deferred();

      // check api
      if (!this.apiAvailable) {
        def.reject();
        return def;
      }

      if (latitude && longitude) {
        const latlng = {lat: parseFloat(latitude), lng: parseFloat(longitude)};
        //console.log(`Mapbox API call: lat=${latlng.lat}, lng=${latlng.lng}`);

        const tmpLimit = this.geocoder.getLimit();
        const tmpTypes = this.geocoder.getTypes();
        this.geocoder.setLimit(1);
        this.geocoder.setTypes('address');
        this.geocoder.query(`${latlng.lng}, ${latlng.lat}`);
        this.geocoder.setLimit(tmpLimit);
        this.geocoder.setTypes(tmpTypes);
        def.resolve();
      } else {
        def.reject();
        throw new Error('No coordinates given');
      }
      return def;
    },

    /**
     * Store current values for address, latitude and longitude in local storage
     */
    localStorageUpdate() {
      // save new notifications in local storage
      const newData = {
        address: this.mapAddress,
        location: {
          lat: this.mainPointPosition.lat,
          lng: this.mainPointPosition.lng,
        },
      };
      window.localStorage.setItem('currentLocation', JSON.stringify(newData));
    },

    /**
     * Get current values for address, latitude and longitude from local storage
     * @returns {undefined|{}}
     */
    localStorageGet() {
      // save new notifications in local storage
      return JSON.parse(window.localStorage.getItem('currentLocation')) || {};
    },

    /**
     *  Compare the current browser location data with the one stored in local storage
     *  if they differ ask to update or use the stored one.
     */
    // checkLocationChanged() {
    //     const self = this;
    //     const triggerDistance = 5;
    //     if (this.browserLocation && this.storedLocation) {
    //         const distance = geometryUtil.distanceKm(this.browserLocation, this.storedLocation);
    //         if (distance > triggerDistance) {
    //             swal.fire({
    //                 title: 'Your location has changed',
    //                 text: `Your stored location "${this.storedAddress}" is more than ${triggerDistance} km away from your current location. Do you like to update your location or continue with "${this.storedAddress}"?`,
    //                 type: 'info',
    //                 showCloseButton: true,
    //                 confirmButtonClass: 'btn btn-secondary',
    //                 showCancelButton: true,
    //                 cancelButtonClass: 'btn btn-secondary',
    //                 confirmButtonText: 'Update location',
    //                 confirmButtonAriaLabel: 'Update location',
    //                 cancelButtonText: 'Keep the stored location',
    //                 cancelButtonAriaLabel: 'Keep the stored location',
    //             }).then((dismissReason) => {
    //                 switch (dismissReason.value) {
    //                     case true:
    //                         window.localStorage.setItem('currentLocation', null);
    //                         self.mapAddress = null;
    //                         self.mainPointPosition = new mapboxgl.LngLat(self.browserLocation.lng, self.browserLocation.lat);
    //                         self.localStorageUpdate();
    //                         $('.mapboxgl-ctrl-geocoder--input').val('');
    //                         break;
    //                     default:
    //                 }
    //             });
    //         }
    //     }
    // },

    // initializeMapLeaflet(config) {
    //     // this reference for callback functions
    //     let self = this;
    //     let def = $.Deferred();
    //
    //     // check api
    //     if (!this.apiAvailable) {
    //         def.reject('No API available');
    //         return def;
    //     }
    //
    //     // init position
    //     let latLng = L.latLng(this.initLocation.latitude, this.initLocation.longitude);
    //
    //     // init map object
    //     this.map = L.map(this.$refs['map-canvas']).setView(latLng, 15);
    //
    //     L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=' + this.mapboxApiKey, {
    //         maxZoom: 18,
    //         attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
    //             '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
    //             'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    //         id: 'mapbox.streets'
    //     }).addTo(this.map);
    //
    //     //this.mainPointPosition = latLng;
    //
    //     def.resolve();
    //
    //     return def;
    //
    // },

  },
};

</script>

<style>
</style>
