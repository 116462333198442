var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-scroller" }, [
    _c("div", { staticClass: "sidebar-container" }, [
      _c(
        "div",
        {
          staticClass: "sidebar-footer-offset",
          staticStyle: { "padding-bottom": "7rem" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-end align-items-center pt-4 px-4"
            },
            [
              _c("div", { staticClass: "hs-unfold" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "js-hs-unfold-invoker btn btn-icon btn-xs btn-soft-secondary",
                    attrs: {
                      href: "javascript:;",
                      "data-hs-unfold-options":
                        '{\n              "target": "#sidebarContent",\n              "type": "css-animation",\n              "animationIn": "fadeInRight",\n              "animationOut": "fadeOutRight",\n              "hasOverlay": "rgba(55, 125, 255, 0.1)",\n              "smartPositionOff": true\n             }'
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "10",
                          height: "10",
                          viewBox: "0 0 18 18",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d:
                              "M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "kt-quick-panel__nav" }, [
            _c(
              "ul",
              {
                ref: "quick-panel-tabs",
                staticClass:
                  "nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand  kt-notification-item-padding-x",
                attrs: { role: "tablist" }
              },
              [
                _c("li", { staticClass: "nav-item active" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link active",
                      attrs: {
                        "data-toggle": "tab",
                        href: "#quick_panel_tab_activities",
                        role: "tab"
                      },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.trans("quick-sidebar.tab.recent-activities")
                        )
                      }
                    },
                    [_vm._v("Recent Activities")]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        "data-toggle": "tab",
                        href: "#quick_panel_tab_notifications",
                        role: "tab"
                      },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.trans("quick-sidebar.tab.user-notifications")
                        )
                      }
                    },
                    [_vm._v("User Notifications")]
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "kt-quick-panel__content",
              attrs: { id: "kt-quick-panel__content" }
            },
            [
              _c("div", { staticClass: "tab-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade show kt-scroll active",
                    attrs: {
                      id: "quick_panel_tab_activities",
                      role: "tabpanel"
                    }
                  },
                  [
                    _c("div", { staticClass: "scrollbar sidebar-body" }, [
                      _c(
                        "div",
                        { staticClass: "sidebar-content py-4 px-7" },
                        [
                          _c("aside-panel-recent-activities", {
                            attrs: { "gui-block-id": "kt_quick_panel" }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade kt-scroll",
                    attrs: {
                      id: "quick_panel_tab_notifications",
                      role: "tabpanel"
                    }
                  },
                  [
                    _c("div", { staticClass: "scrollbar sidebar-body" }, [
                      _c(
                        "div",
                        { staticClass: "sidebar-content py-4 px-7" },
                        [
                          _c("aside-panel-user-notifications", {
                            attrs: { "gui-block-id": "kt_quick_panel" }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }