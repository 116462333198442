<template>
  <div class="text-primary subject-display-likes">
    <a v-if="userLikes" class="active"  href="javascript:;"
       :title="trans('gui.click_to_stop_liking')"
       @click="removeLike()">
      <svg class="mb-1 mr-3" id="like" xmlns="http://www.w3.org/2000/svg" width="17.124" height="14.983" viewBox="0 0 17.124 14.983">
        <path id="Path_418" data-name="Path 418" d="M0,1.07V9.632A1.07,1.07,0,0,0,1.07,10.7H4.281V0H1.07A1.07,1.07,0,0,0,0,1.07Z" transform="translate(0 4.281)" fill="#fff"/>
        <path id="Path_419" data-name="Path 419" d="M9.8,5.381H6.635a.535.535,0,0,1-.468-.795L7.281,2.579A1.6,1.6,0,0,0,6.267.242l-.786-.2a.534.534,0,0,0-.527.161L.686,4.948A2.674,2.674,0,0,0,0,6.738v5.6a2.679,2.679,0,0,0,2.676,2.676H8a2.682,2.682,0,0,0,2.58-1.968L11.722,7.8A1.976,1.976,0,0,0,9.8,5.381Z" transform="translate(5.351 -0.03)" fill="#fff"/>
      </svg>
    </a>
    <a v-if="!userLikes" href="javascript:;"
       :title="trans('gui.click_to_start_liking')"
       @click="addLike()">
      <svg class="mb-1 mr-3" id="like" xmlns="http://www.w3.org/2000/svg" width="17.124" height="14.983" viewBox="0 0 17.124 14.983">
        <path id="Path_418" data-name="Path 418" d="M0,1.07V9.632A1.07,1.07,0,0,0,1.07,10.7H4.281V0H1.07A1.07,1.07,0,0,0,0,1.07Z" transform="translate(0 4.281)" fill="#fff"/>
        <path id="Path_419" data-name="Path 419" d="M9.8,5.381H6.635a.535.535,0,0,1-.468-.795L7.281,2.579A1.6,1.6,0,0,0,6.267.242l-.786-.2a.534.534,0,0,0-.527.161L.686,4.948A2.674,2.674,0,0,0,0,6.738v5.6a2.679,2.679,0,0,0,2.676,2.676H8a2.682,2.682,0,0,0,2.58-1.968L11.722,7.8A1.976,1.976,0,0,0,9.8,5.381Z" transform="translate(5.351 -0.03)" fill="#fff"/>
      </svg>
    </a>
    <span v-cloak>{{ likeCount }}</span>
  </div>
</template>

<script>
import JsRouting from 'fos-jsrouting-bundle';
import guiUtil from 'js/app/utils/gui';
import HttpUtil from 'js/app/utils/http';
import {EventBus} from 'js/app/utils/EventBus';
import Translator from 'bazinga-translator';
import Axios from 'axios';
import 'babel-polyfill'; // needed to support "async + await"
import securityUtil from 'js/app/utils/security';
import SubjectStats from 'js/app/models/SubjectStats';

export default {
  name: 'SubjectLikes',
  components: {
  },
  props: {
    subjectClass: {
      type: String,
      default: '',
    },
    subjectId: {
      type: String,
      default: '',
    },
    subjectStats: {
      type: Object,
      default() {
        return null;
      },
    },
    statsMap: {
      type: Map,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      thisSubjectStats: new SubjectStats(),
    };
  },
  computed: {
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    },
    likeCount() {
      if (this.thisSubjectStats) {
        return this.thisSubjectStats.likes || 0;
      } else return 0;
    },
    userLikes() {
      if (this.thisSubjectStats) {
        return this.thisSubjectStats.user.likes;
      } else return 0;
    },
  },
  watch: {
    subjectStats(val) {
      this.thisSubjectStats = val;
    },
    statsMap(map) {
      if (map.has(this.subjectId)) {
        this.thisSubjectStats = map.get(this.subjectId);
      }
    },
  },
  created() {
  },
  mounted() {
    const self = this;
    if (this.subjectStats !== null) {
      this.thisSubjectStats = this.subjectStats;
    } else if (this.statsMap !== null && this.statsMap.size > 0 && this.subjectId) {
      this.thisSubjectStats = this.statsMap.get(this.subjectId);
    }
  },
  methods: {

    trans(key, domain, params, multipleCount) {
      if (multipleCount) {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'AppProduct');
      } else {
        return Translator.trans(key, params || {}, domain || 'AppProduct');
      }
    },

    addLike() {
      const self = this;
      const url = JsRouting.generate('webapi_marker_likes_add', {
        entityId: this.subjectId,
        entityClass: this.subjectClass,
      }, true);

      if (!this.isAuthenticated) {
        guiUtil.showLoginExclusiveFeatureInfo();
        return false;
      }

      Axios.put(url).then((response) => {
        if (response.status === 201) {
          self.$emit('user-like-changed', true);
          self.thisSubjectStats.setUserLikes();
        }
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
          guiUtil.showNotification('error',
            self.trans(errorString),
            Translator.trans('gui.error_occured'),
          );
        });
      }).then(() => {
      });
    },

    removeLike() {
      const self = this;
      const url = JsRouting.generate('webapi_marker_likes_remove', {
        entityId: this.subjectId,
        entityClass: this.subjectClass,
      }, true);

      if (!this.isAuthenticated) {
        guiUtil.showLoginExclusiveFeatureInfo();
        return false;
      }

      Axios.delete(url).then((response) => {
        if (response.status === 200) {
          self.$emit('user-like-changed', false);
          self.thisSubjectStats.unsetUserLikes();
        }
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
          guiUtil.showNotification('error',
            errorString,
            Translator.trans('gui.error_occured'),
          );
        });
      }).then(() => {
      });
    },

  },
};
</script>

<style lang="scss">

.favorites__handles {
  i {
    cursor: pointer;
  }
}

</style>
