import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const perstentStore = new Vuex.Store({
  state: {
    searchParams: {},
  },
  mutations: {
    searchParams(state, searchParams) {
      Object.assign(state.searchParams, searchParams);
    },
  },
  plugins: [createPersistedState({
    key: 'vuexStore',
  })],
});

export default perstentStore;
