<template>
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
      <div class="kt-portlet__head-label">
        <h3 class="kt-portlet__head-title">
          {{ 'product.search.filters.portlet_title' | trans({},'AppProduct') }}
        </h3>
      </div>
      <div class="m-portlet__head-tools">
      </div>
    </div>
    <div class="kt-portlet__body">
      <slot>here comes the prerendered symfony form see
        templates/product/section_accommodation/index.html.twig
        render(controller('App\\Controller\\Product\\AccommodationController::searchFiltersWidget'))
      </slot>
    </div>
  </div>
</template>

<script>
import KeyValuePairArrayUtil from 'js/app/utils/KeyValuePairArray';

export default {
  name: "search-filters",
  props: [],
  mounted() {
    this.init();
  },
  data() {
    return {
      filters: [{id: 0, name: 'filter1'}, {id: 1, name: 'filter2'}]
    }
  },
  methods: {
    init() {

      if ($(this.$el)[0].nodeName === 'FORM') {
        this.$form = $(this.$el);
      } else {
        this.$form = $(this.$el).find('form');
      }

      // check options already present in search query stateObj
      let stateObj = history.state;
      if (stateObj && stateObj.length > 0) {
        for (let pair of stateObj) {
          this.setField(pair[0], pair[1]);
        }
      }

      // attach a click event to each form element
      for (let element of this.$form[0].elements) {
        element.addEventListener("click", this.elementChangedEventHandler);
      }
    },


    /**
     *
     */
    processForm() {

      let self = this;
      let formData = new FormData(this.$form[0]);
      let stateObj = history.state;
      let queryData = [];

      for (let pair of formData.entries()) {
        KeyValuePairArrayUtil.elementSetIfNotFound(stateObj, pair[0], pair[1]);
      }

      $.each(stateObj, function (i, pair) {
        queryData.push(i + '=' + pair[1]);
      });

      history.pushState(stateObj, '', '?' + queryData.join('&'));

      this.performSearch(stateObj);

    },

    performSearch(stateObj) {
      let self = this;

      stateObj = stateObj || history.state;
      if (stateObj && stateObj.length > 0) {
        self.$emit('filter-initiated', stateObj);
      }

    },

    elementChangedEventHandler(e) {
      let item = e.target;
      let stateObj = history.state;

      if (item.nodeName === 'INPUT' && item.getAttribute('type') === 'checkbox') {
        if (item.checked === true) {
          KeyValuePairArrayUtil.elementSetIfNotFound(stateObj, item.getAttribute('name'), item.getAttribute('value'));
        } else {
          KeyValuePairArrayUtil.elementRemove(stateObj, item.getAttribute('name'), item.getAttribute('value'));
        }
      }
      if (item.nodeName === 'SELECT') {
        if (item.checked === true) {
          KeyValuePairArrayUtil.elementSetIfNotFound(stateObj, item.getAttribute('name'), item.getAttribute('value'));
        } else {
          KeyValuePairArrayUtil.elementRemove(stateObj, item.getAttribute('name'), item.getAttribute('value'));
        }
      }
      this.processForm();
    },

    setField(name, value) {
      let el = this.$form[0].elements[name];
      if (el) {
        if (el instanceof RadioNodeList) {
          // array type elements like multiple checkbox, multiple select
          for (var item of el) {
            if (item.nodeName === 'INPUT' && item.getAttribute('type') === 'checkbox') {
              this.setField__checkbox(item, value);
            }
          }
        } else if (el.nodeName === 'SELECT') {
          this.setField__select(el, value);
        }
      }
    },

    setField__checkbox(item, value) {
      if (item.getAttribute('value') === value) {
        item.checked = true;
      }
    },

    setField__select(item, value) {
      for (var option of item.options) {
        if (option.getAttribute('value') === value) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
