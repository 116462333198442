var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showCookieConsent,
          expression: "showCookieConsent"
        }
      ],
      staticClass:
        "ch-cookie-consent ch-cookie-consent--light-theme ch-cookie-consent--bottom ch-cookie-consent--simplified"
    },
    [
      _c("h3", { staticClass: "ch-cookie-consent__title" }, [
        _vm._v(
          "\n    " + _vm._s(_vm._f("trans")("ch_cookie_consent.title")) + "\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "ch-cookie-consent__intro" }, [
        _vm._v(
          "\n    " + _vm._s(_vm._f("trans")("ch_cookie_consent.intro")) + "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "ch-cookie-consent__read-more",
          attrs: { target: "_blank", href: _vm._f("path")("cms.privacy") }
        },
        [_vm._v(_vm._s(_vm._f("trans")("ch_cookie_consent.read_more")))]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "ch-cookie-consent__form",
          attrs: {
            id: "cookie_consent",
            name: "cookie_consent",
            method: "post"
          }
        },
        [
          _c(
            "div",
            { staticClass: "ch-cookie-consent__category-group" },
            [
              _vm._l(_vm.categories, function(category, index) {
                return _c(
                  "label",
                  {
                    staticClass: "toggle-switch d-flex",
                    attrs: { for: "cookie-consent-" + category }
                  },
                  [
                    _c("input", {
                      staticClass: "toggle-switch-input",
                      attrs: {
                        type: "checkbox",
                        value: "yes",
                        name: "cookie_consent[" + category + "]",
                        id: "cookie-consent-" + category
                      },
                      domProps: { checked: _vm.categoryIsChosen(category) },
                      on: {
                        click: function($event) {
                          return _vm.updateSettings()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm._m(0, true),
                    _vm._v(" "),
                    _c("span", { staticClass: "toggle-switch-content" }, [
                      _c("span", { staticClass: "d-block" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("trans")(
                              "ch_cookie_consent." + category + ".title"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("small", { staticClass: "d-block text-muted" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("trans")(
                              "ch_cookie_consent." + category + ".description"
                            )
                          )
                        )
                      ])
                    ])
                  ]
                )
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "hidden",
                  id: "cookie_consent__token",
                  name: "cookie_consent[_token]",
                  value: "m0FV6oIq0guJGP1VDIh709MjSIZNy8t1RE4oQof7pyM"
                }
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ch-cookie-consent__btn-group" }, [
            !_vm.detailSettingsAvailable
              ? _c("div", { staticClass: "ch-cookie-consent__btn-wrapper" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn ch-cookie-consent__btn ch-cookie-consent__btn--secondary",
                      attrs: {
                        type: "button",
                        id: "cookie_consent_use_only_functional_cookies",
                        name: "cookie_consent[use_only_functional_cookies]"
                      },
                      on: { click: _vm.enableFunctionalCookiesOnly }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("trans")(
                              "ch_cookie_consent.use_only_functional_cookies"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.detailSettingsAvailable
              ? _c("div", { staticClass: "ch-cookie-consent__btn-wrapper" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn ch-cookie-consent__btn ch-cookie-consent__btn--secondary",
                      attrs: {
                        type: "button",
                        id: "cookie_consent_use_selected_cookies",
                        name: "cookie_consent[use_selected_cookies]"
                      },
                      on: { click: _vm.updateSettings }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("trans")(
                              "ch_cookie_consent.use_selected_cookies"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "ch-cookie-consent__btn-wrapper" }, [
              _c(
                "button",
                {
                  staticClass: "btn ch-cookie-consent__btn",
                  attrs: {
                    type: "button",
                    id: "cookie_consent_use_all_cookies",
                    name: "cookie_consent[use_all_cookies]"
                  },
                  on: { click: _vm.enableAllCookies }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("trans")("ch_cookie_consent.use_all_cookies")
                      ) +
                      "\n        "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ch-cookie-consent__toggle-details" }, [
              _c(
                "span",
                { staticClass: "ch-cookie-consent__toggle-details-show" },
                [
                  _vm._v(
                    _vm._s(_vm._f("trans")("ch_cookie_consent.show_details"))
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "ch-cookie-consent__toggle-details-hide" },
                [
                  _vm._v(
                    _vm._s(_vm._f("trans")("ch_cookie_consent.hide_details"))
                  )
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "toggle-switch-label" }, [
      _c("span", { staticClass: "toggle-switch-indicator" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }