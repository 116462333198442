<template>
  <div class="search-detailed__holiday--results space-top-3 space-top-sm-4 space-top-md-4 space-top-lg-0">

    <!-- placeholder list -->
    <div v-if="isLoading">
      <search-results-placeholder v-for="(cnt, index) in [1,2,3,4,5]" :key="index" :item="cnt">
      </search-results-placeholder>
    </div>

    <!--begin::results list-->
    <div v-if="!isLoading" class="text-center row justify-content-center">
      <div class="col-lg-10">
      <h2 class="h2">Ciężko pracujemy nad dodawaniem ofert!</h2>
      <p class="mt-10">Już wkrótce, gdy nasza baza będzie zawierać wystarczającą ilość ofert, zobaczysz tutaj listę rezultatów w wybranej przez Ciebie okolicy.</p>
      <p class="mb-10">Do tego czasu prosimy jeszcze o odrobinę cierpliwości.</p>
      <div class="metronic-scope">

        <div v-if="isAuthenticated" class="card card-custom gutter-b bg-light-primary">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-center justify-content-lg-between p-2 p-lg-4 flex-wrap flex-lg-nowrap">
              <div class="d-flex flex-column mr-lg-5 mb-3">
                <a href="#" class="h4 text-dark text-hover-primary mb-5">
                  Zamiastowy Gospodarzu.<br>Potrzebujemy Twojej pomocy!
                </a>
                <div class="text-dark-50">
                  Publikując swoje oferty, pomożesz nam, jak najszybciej udostępnić pełną wyszukiwarkę ofert.<br>
                  Pomóż nam pokazać pełen potencjał tego ambitnego projektu.
                </div>
              </div>
              <div class="ml-lg-0 ml-xxl-6 flex-shrink-0">
                <a :href="path('app_account_provider_products_list')" target="" class="btn font-weight-bolder text-uppercase btn-primary py-4 px-6">
                  Do&nbsp;edycji&nbsp;ofert
                </a>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!isAuthenticated" class="card card-custom gutter-b bg-light-primary">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-center justify-content-lg-between p-2 p-lg-4 flex-wrap flex-lg-nowrap">
              <div class="d-flex flex-column mr-lg-5 mb-3">
                <a href="#" class="h4 text-dark text-hover-primary mb-5">
                  Zamiastowy Gospodarzu
                </a>
                <div class="text-dark-50">
                  Rejestrując się i publikując swoje oferty, pomożesz nam, jak najszybciej udostępnić pełną wyszukiwarkę ofert.<br>
                  Pomóż nam pokazać pełen potencjał tego ambitnego projektu.
                </div>
              </div>
              <div class="ml-lg-0 ml-xxl-6 flex-shrink-0">
                <a :href="path('fos_user_registration_register')" target="" class="btn font-weight-bolder text-uppercase btn-primary py-4 px-6">
                  Do&nbsp;rejestracji
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>

      </div>
    </div>
    <!--end::results list-->

  </div>
</template>

<script>
import JsRouting from 'fos-jsrouting-bundle';
import 'lity';
import 'css/lity.scss';
import 'babel-polyfill'; // needed to support "async + await"
//import Paginator from 'js/app/components/paginator';
import GeneralUtil from 'js/app/utils/general';
import SearchResultsItem from './search--results.item.vue';
import InfiniteScrollTrigger from 'js/app/components/infinite-scroll-trigger';
import SearchResultsPlaceholder from './search--results.placeholder.vue';
import securityUtil from 'js/app/utils/security';

export default {
  name: 'SearchResultsWipDummy',
  components: {
    //Paginator,
    SearchResultsItem,
    InfiniteScrollTrigger,
    SearchResultsPlaceholder,
  },
  props: {
    paginationInput: {
      type: Object,
      default: null,
    },
    searchResults: {
      type: Array,
      default: null,
    },
    total: {
      type: Number,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    maxPaginationCount: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      pagination: {
        page: parseInt(this.paginationInput.page),
        perpage: parseInt(this.paginationInput.perpage),
      },
      maxPossiblePaginationCountExceedsLimit: false,
      maxPaginationCountExceeded: false,
      sorting: 'default',
      infiniteScrollIsActive: true, // make it always true initially!
      showPlaceholder: true,
    };
  },
  computed: {
    resultSetNonEmpty() {
      return this.searchResults !== null && this.searchResults.length > 0;
    },
    maxPossibleResultCountToShow() {
      return this.maxPaginationCount * this.pagination.perpage;
    },
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    },
  },
  watch: {
    paginationInput: {
      handler(val) {
        this.pagination.page = parseInt(val.page);
        this.pagination.perpage = parseInt(val.perpage);
      },
      deep: true,
    },
    sorting(val) {
      this.$emit('sort', val);
    },
    'pagination.page': {
      handler(val) {
        if (val > this.maxPaginationCount) {
          this.maxPaginationCountExceeded = true;
        }
      },
      immediate: true, // react also on the initial change (setting from prop)
    },
    maxPaginationCountExceeded(val) {
      if (val === true) {
        // do not attempt fetching more results
        // stop infinite scroll
        this.infiniteScrollIsActive = false;
      }
    },
    searchResults: {
      handler(val) {
        if (Array.isArray(val) && this.total > 0) {
          if (this.total <= val.length) {
            // we reached all search results
            // stop infinite scroll
            this.infiniteScrollIsActive = false;
          } else {
            if (this.total > this.maxPossibleResultCountToShow) {
              this.maxPossiblePaginationCountExceedsLimit = true;
            }
            this.infiniteScrollIsActive = true;
          }
        }
      },
      deep: true,
      immediate: true, // react also on the initial change (setting from prop)
    },
  },
  created() {

  },
  async mounted() {
    let self = this;
    // fetch user position
    this.userPosition = null;
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition((position) => {
        self.userPosition = position;
      });
    }
  },
  methods: {

    trans(key, params, domain, multipleCount) {
      if (multipleCount) {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'AppProduct');
      } else {
        return Translator.trans(key, params || {}, domain || 'AppProduct');
      }
    },

    path(path, params) {
      const _params = params || {};
      return JsRouting.generate(path, _params, true);
    },

    // Needed only when paginator is being used instead of infinite scroll
    // paginationEventHandler(page, perpage) {
    //     this.pagination.page = page;
    //     this.pagination.perpage = perpage;
    //     // pass this event to parent container which has access to the search-detailed-form component
    //     this.$emit('paginate', page, perpage);
    // },

    /**
     * Load more results triggered by infinite scroll trigger
     */
    loadMoreResults() {
      if (this.resultSetNonEmpty === true) {
        this.pagination.page += 1;
        this.$nextTick(() => {
          // next tick to be sure maxPaginationCountExceeded has been calculated
          // after we raised this.pagination.page
          if (this.maxPaginationCountExceeded === false) {
            this.$emit('paginate', this.pagination.page, this.pagination.perpage);
          }
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
