<template>

</template>

<script>
import '@fancyapps/fancybox/dist/jquery.fancybox';
import 'theme/assets/js/hs.fancybox';
import SlickCarousel from 'js/app/components/slick-carousel.vue';

export default {
  name: 'ArticlesRelated',
  components: {
    SlickCarousel,
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // init bootstrap & Co.
      this.initPlugins();
    },
    initPlugins() {

    },
  },
};
</script>

<style scoped>

</style>
