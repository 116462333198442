<template>
  <!--begin: Notifications -->
  <div class="kt-notification" ref="kt-notifications-panel">
    TODO
  </div>
  <!--end: Notifications -->
</template>

<script>
import Activity from "js/app/models/Activity";
import ActivityCollection from "js/app/models/ActivityCollection";
import JsRouting from 'fos-jsrouting-bundle';
import Axios from "axios";
import securityUtil from "js/app/utils/security";
import globalConfig from 'js/app/config/app';
import guiUtil from "js/app/utils/gui";
import HttpUtil from 'js/app/utils/http';

export default {
  name: 'AsidePanelRecentActivities',
  data() {
    return {
      notifications: new ActivityCollection(),
    };
  },

  computed: {
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    },
    unreadCount() {
      let count = 0;
      $.each(this.notifications.models, function (key, val) {
        if (val && !val.is_read) count++;
      });
      return count;
    },
  },

  watch: {},

  created() {
  },

  mounted() {
    try {
      this.init();
    } catch (error) {
      throw new Error(error);
    }
  },

  methods: {
    init() {
      const self = this;

      if (this.isAuthenticated) {
        // this.fetchNotifications();
        //
        // // subscribe for mercure events
        // const topic = 'activities_' + securityUtil.getAuthenticatedUserHash();
        // const esUri = globalConfig.mercureHub + '?topic=' + encodeURIComponent(topic);
        // const es = new EventSource(esUri);
        // es.onmessage = e => {
        //     // Will be called every time an update is published by the mercure server
        //     self.fetchNotifications();
        // }
      }
    },

    trans(key, domain, params) {
      return Translator.trans(key, params || {}, domain || 'messages');
    },

    /**
     *
     */
    itemClicked(notification, e) {

    },

    /**
     * Fetch new notifications from remote
     */
    fetchNotifications() {
      let def = $.Deferred();
      const self = this;
      def.resolve();
      return def;
    },

    markNotificationAsRead(notification) {
      let def = $.Deferred();
      const self = this;
      def.resolve();
      return def;
    },

    getNotificationTitle(notification) {
      return this.trans(notification.title, 'messages', notification.data.translation_parameters.title);
    },

    getNotificationIcon(notification) {
      let icon = 'flaticon-alert';
      switch (notification.type) {
        default:
          icon = 'flaticon-alert';
      }
      return icon;
    },

    getActions(notification) {
      let actions = {};
      let cssClassMap = {
        accept: 'success',
        reject: 'danger'
      };
      if (typeof notification.data.actions !== 'undefined') {
        $.each(notification.data.actions, function (key, val) {
          actions[key] = {
            css_class: cssClassMap[key],
            url: val.url,
            ajax: val.ajax
          };
        });
      }
      return actions;
    },

    /**
     * An action button has been clicked,
     * do what it is supposed to do and mark this notification as read
     * @param action
     * @param notification
     */
    actionClick(action, notification) {
      let self = this;
      if (action.ajax) {
        guiUtil.blockUI(self.$refs['kt-notification-dropdown']);
        Axios.get(action.url).then((response) => {
          //self.notifications = this.localStorageDelete(notification);
        }).catch((error) => {
          HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
          });
        }).then(() => {
          guiUtil.unblockUI(self.$refs['kt-notification-dropdown']);
        });
      } else {
        //this.notifications = this.localStorageDelete(notification);
        document.location.replace(action.url);
      }
      this.markNotificationAsRead(notification);
    }

  }
}
</script>

<style scoped>

</style>
