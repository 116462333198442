var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isValid
    ? _c(
        "div",
        { staticClass: "z-widget-comments metronic-scope" },
        [
          _vm.isInitialized && _vm.comments.length === 0
            ? _c("div", [
                _c("h3", [
                  _vm._v(_vm._s(_vm.trans("comment.no-comments-yet")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.trans("comment.be-first-to-comment")))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.comments.length > 0
            ? _c(
                "div",
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.stats.total) +
                        " " +
                        _vm._s(
                          _vm.trans(
                            "comment.comments-counter",
                            null,
                            null,
                            _vm.stats.total
                          )
                        )
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.comments, function(comment) {
                    return _c("comment-section-entry", {
                      key: comment.hashid,
                      attrs: {
                        comment: comment,
                        "thread-id": _vm.thread.hashid
                      },
                      on: { "comment-deleted": _vm.onCommentDeleted }
                    })
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "separator separator-solid mt-2 mb-2"
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("infinite-scroll-trigger", {
            attrs: {
              "is-active": _vm.scrollIsActive,
              "is-loading": _vm.scrollIsLoading
            },
            on: { "trigger-intersected": _vm.loadMoreData }
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              staticClass: "position-relative",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.postComment.apply(null, arguments)
                }
              }
            },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.body,
                    expression: "body"
                  }
                ],
                ref: "message-body",
                staticClass: "form-control resize-none mb-2",
                attrs: {
                  name: "fos_comment_comment[body]",
                  rows: "1",
                  placeholder: "Dodaj twój komentarz ..."
                },
                domProps: { value: _vm.body },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.body = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "btn btn-primary w-100",
                attrs: { type: "submit", value: "Wyślij komentarz" }
              })
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }