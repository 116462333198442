var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-custom shadow-none gutter-b" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("div", { staticClass: "d-flex flex-column flex-grow-1" }, [
          _c(
            "a",
            {
              staticClass:
                "text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder",
              attrs: { href: "#" }
            },
            [_vm._v(_vm._s(_vm.label))]
          ),
          _vm._v(" "),
          _vm.categoryTranslated
            ? _c("span", { staticClass: "text-muted font-weight-bold" }, [
                _vm._v(_vm._s(_vm.categoryTranslated))
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pt-0" }, [
        _vm.description && _vm.description !== "..."
          ? _c(
              "p",
              {
                staticClass: "text-dark-75 font-size-lg font-weight-normal mb-2"
              },
              [
                _vm._v("\n        " + _vm._s(_vm.description) + "\n        "),
                _vm.copyright && _vm.description && _vm.description !== "..."
                  ? _c("span", { staticClass: "text-muted d-block mt-2" }, [
                      _vm._v("© " + _vm._s(_vm.copyright))
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex align-items-end justify-content-end" },
          [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-icon btn-xs btn-clean btn-hover-icon-primary",
                on: { click: _vm.triggerEdit }
              },
              [_c("i", { staticClass: "flaticon-edit icon-xs" })]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }