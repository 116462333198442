<template>
  <div class="text-center mt-n14 mt-md-0">
    <div v-if="priceMatrix.default">
      <!--h6 class="font-weight-bold text-primary text-line-through mb-1">
        Od {{ formatPrice(priceMatrix.default.value) }} / {{ ('channel-pricing.unit-display.' + priceMatrix.default.unit) | trans({}, 'AppProduct') }}
      </h6-->
      <h4 class="font-weight-bold text-secondary mb-0">
        Od {{ formatPrice(priceMatrix.default.value) }} / {{ ('channel-pricing.unit-display.' + priceMatrix.default.unit) | trans({}, 'AppProduct') }}
      </h4>
    </div>
  </div>
</template>

<script>
import Translator from 'bazinga-translator';

export default {
  name: 'PriceStartingFromDisplay',
  props: {
    prices: {
      type: Object,
      default() {
        return {};
      },
    },
    priceKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      priceMatrix: [],
    };
  },
  mounted() {
    if (typeof this.prices[this.priceKey] !== 'undefined') {
      this.priceMatrix = this.prices[this.priceKey];
    } else {
      this.priceMatrix = [];
    }
  },
  methods: {
    trans(key, params, domain, multipleCount) {
      if (multipleCount) {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'AppProduct');
      } else {
        return Translator.trans(key, params || {}, domain || 'AppProduct');
      }
    },

    formatPrice(price) {
      return this.$options.filters.currency(price);
    },
  },
};
</script>

<style scoped>

</style>
