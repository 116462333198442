<template>
  <div class="zero-section-map--listing-filters-wrap">
    <ul
      class="
        nav nav-multiselect
        section-listing-filters
        nav-pills nav-pills--general-filters
        shadow-sm
        mb-4 mb-lg-0
        mr-lg-2
        col-md-6
      "
    >
      <li class="zm-multiWrap">
        <select class="form-control selectpicker" multiple>
          <option v-for="filterVal in filters['product_type'].values" :value="filterVal.value">{{ filterVal.label }}</option>
        </select>
      </li>
    </ul>

    <ul
      class="
        nav
        section-listing-filters-distance
        nav-pills nav-pills--distance
        shadow-sm
        col-md-6
      ">
      <li v-for="filterVal in filters['spatial_d'].values" class="nav-item">
        <a :class="{'nav-link': true, 'active font-weight-bold': isActiveFilter('spatial_d', filterVal.value)}"
           @click.prevent="setActiveFilter('spatial_d', filterVal.value)"
           data-filter-key="spatial_d"
           :data-value="filterVal.value"
           href="#">{{ filterVal.label }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import KeyValuePairArrayUtil from 'js/app/utils/KeyValuePairArray';

export default {
  name: 'SearchGeocoderMapFilters',
  directives: {
    'filter-nav': {
      // directive definition
      bind(el, binding, vnode) {
        $(el).find('.nav-link').on('click', (e) => {
          $(el).find('.active').removeClass('active font-weight-bold');
          $(e.target).addClass('active font-weight-bold');
        });
      },
    },
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      filtersSet: [],
    };
  },
  watch: {
    filtersSet: {
      handler(newFilterSet) {
        this.$emit('filterset-changed', newFilterSet);
      },
      deep: true,
    },
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.initCheckboxMultivalue();
    },

    initCheckboxMultivalue() {
      const self = this;
      $('.selectpicker').each((index, el) => {
        $(el).selectpicker({
          title: 'Rodzaj oferty',
          selectedTextFormat: 'count',
          countSelectedText: 'Rodzaj oferty: {0}',
          style: 'btn-zamiastowi',
        }).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
          self.setActiveFilter('product_type', $(e.target).val());
        });
        ;
      });
    },

    isActiveFilter(filterKey, filterVal) {
      const val = KeyValuePairArrayUtil.elementGet(this.filtersSet, filterKey)[0];
      return val === filterVal;
    },

    setActiveFilter(filterKey, filterVal) {
      this.newFiltersSet = KeyValuePairArrayUtil.uniqueKeyElementSet(this.filtersSet, filterKey, filterVal, false);
      // BEGIN: hack to trigger mutated state on this.filtersSet
      // otherwise our watcher, even with deep=true does not get triggered!!!
      this.filtersSet = null;
      this.filtersSet = this.newFiltersSet;
      // END: hack to trigger mutated state on this.filtersSet
    },

    initFilters() {
      Object.entries(this.filters).forEach(([filterKey, filterConfig], index) => {
        filterConfig.values.forEach((filterData) => {
          if (filterData.default) {
            this.setActiveFilter(filterKey, filterData.value);
          }
        });
      });
    },

    getActiveFilter(filterKey) {
      return KeyValuePairArrayUtil.elementGet(this.filtersSet, filterKey)[0] || null;
    },
  },
};
</script>

<style scoped>

</style>
