/**
 * @see https://vuemc.io/#basic-usage
 */
import {Model, Collection} from 'vue-mc';
import Message from "./Message";

/**
 * MassageC collection
 */
class MessageCollection extends Collection {

  // Model that is contained in this collection.
  model() {
    return Message;
  }

  // Default attributes
  defaults() {
    return {
      orderBy: 'id',
    };
  }

}

export default MessageCollection;
