var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "ph-item ph-item-product z-widget-offer-item row no-gutters border-bottom mb-6 mb-md-6 pb-3"
      },
      [
        _c("div", { staticClass: "col-lg-3 mb-4 mb-lg-0" }, [
          _c("div", { staticClass: "ph-picture ph-h-3" })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-9 d-lg-flex flex-column pl-lg-4" }, [
          _c(
            "div",
            { staticClass: "row justify-content-between mb-5 mb-lg-0" },
            [
              _c("div", { staticClass: "col-md-9 mb-4 mb-md-0" }, [
                _c(
                  "div",
                  { staticClass: "ph-row font-weight-bold text-lh-sm mb-4" },
                  [_c("div", { staticClass: "ph-col-6 extra-big" })]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "ph-row text-nowrap align-items-center justify-content-betweeb mb-4"
                  },
                  [
                    _c("div", { staticClass: "ph-col-2 big" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ph-col-2 empty" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ph-col-2 big" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ph-col-1 empty" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ph-col-2 big" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ph-col-1 empty" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ph-col-2 big" })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ph-row" }, [
                  _c("div", { staticClass: "ph-col-10" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ph-col-8" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ph-col-12" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ph-col-9" })
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ph-row col-md-3 text-md-right pt-md-2" },
                [
                  _c("div", { staticClass: "ph-col-4 empty" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ph-col-8 extra-big" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ph-col-12 empty" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ph-col-4 empty" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ph-col-8 extra-big" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ph-col-12 empty" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ph-col-4 empty" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ph-col-8 extra-big" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ph-col-12 empty" })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "text-md-left border-top border-md-top-0 pt-11 pt-md-0 mb-n4 mb-md-0"
            },
            [
              _c("div", { staticClass: "ph-row mr-md-9" }, [
                _c("div", { staticClass: "ph-col-12 empty" }),
                _vm._v(" "),
                _c("div", { staticClass: "ph-col-12 empty" }),
                _vm._v(" "),
                _c("div", { staticClass: "ph-col-3 extra-big" })
              ])
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }