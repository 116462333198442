<template>
  <!--begin::Private thread-->
  <!--begin::Card-->
  <div class="card card-custom">
    <div class="card-header align-items-center px-4 py-3">
      <div class="text-left flex-grow-1">
      </div>
      <div class="text-center flex-grow-1">
        <div class="text-dark-75 font-weight-bold font-size-h5">Rozmowa z {{ recipient.full_name }}</div>
        <!--div>
          <span class="label label-dot label-success"></span>
          <span class="font-weight-bold text-muted font-size-sm">Active</span>
        </div-->
      </div>
      <div class="text-right flex-grow-1">
        <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-md" data-dismiss="modal">
          <i class="ki ki-close icon-1x"></i>
        </button>
      </div>
    </div>

    <div v-if="messages.models.length > 0" class="card-body">
      <!--begin::Scroll-->
      <div class="scroll scroll-pull" data-height="375" data-mobile-height="300">
        <div class="messages">
          <private-message-thread-entry
            v-for="message in messages.models"
            :key="message.id"
            :dir="(messageSenderIsMe(message.sender)?'out':'in')"
            :message="message">
          </private-message-thread-entry>
        </div>
      </div>
    </div>

    <div class="card-footer align-items-center">
      <p class="font-weight-bold text-primary mb-3">Nowa wiadomość:</p>
      <form ref="submit-form" @submit.prevent="processForm" :action="formAction" method="post">
        <input type="hidden" name="_method" value="POST">
        <slot name="form"></slot>
        <div class="d-flex justify-content-end pt-3">
          <button class="btn btn-primary btn-pill btn-sm py-2 px-6">{{ 'dict.send'|trans({},'FOSMessageBundle') }}</button>
        </div>
      </form>
    </div>
  </div>
  <!--end::Private thread-->
</template>

<script>
import Vue from 'vue';
import Axios from 'axios';
import JsRouting from 'fos-jsrouting-bundle';
import guiUtil from 'js/app/utils/gui';
import HttpUtil from 'js/app/utils/http';
import securityUtil from 'js/app/utils/security';
import Translator from 'bazinga-translator';
import Message from 'js/messaging/models/Message';
import MessageCollection from '../models/MessageCollection';
import {EventBus} from 'js/app/utils/EventBus';
import PrivateMessageThreadEntry from './private-message-thread-entry.vue';
import globalState from '../../app/store/GlobalStore.js';

export default {
  name: 'PrivateMessageThreadNew',
  store: globalState,
  components: {
    PrivateMessageThreadEntry,
  },
  props: {
    threadId: {
      type: String,
      default: '',
    },
    productId: {
      type: String,
      default: '',
    },
    recipientJson: {
      type: String,
      default: '',
    },
    showNavigation: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    waitForTriggerEvent: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      messages: new MessageCollection(),
      thread: null,
    };
  },
  computed: {
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    },
    recipient() {
      return JSON.parse(this.recipientJson);
    },
    formAction() {
      if (this.threadId) {
        return JsRouting.generate('api_app_messaging_thread_reply', {threadId: this.threadId});
      } else {
        return JsRouting.generate('api_app_messaging_thread_post');
      }
    },
    threadCreator() {
      if (this.thread && this.thread.created_by) {
        return `${this.thread.created_by.first_name} ${this.thread.created_by.last_name}`;
      } else return '';
    },
  },
  watch: {
    messages: {
      // special watcher for nested data - here vue-mc collection
      // https://vuejs.org/v2/api/#watch
      deep: true,
      handler(val) {
        const self = this;
        Vue.nextTick(() => {
          self._replaceHtmlMetaFormat();
          self._initChat();
        });
      },
    },
    '$store.state.mailboxChangeIncrement': {
      immediate: true,
      handler(val) {
        // a change in users mailboxes broadcasted, reload this thread
        this.loadThread(false).done(() => {
          // ! Do it on the next tick to be sure the DOM is being updated !
          Vue.nextTick(() => {
            this._replaceHtmlMetaFormat();
            this._initChat();
          });
        }).fail((error) => {
          console.error(error);
        });
      },
    },
  },
  created() {
    const self = this;
    // @see https://alligator.io/vuejs/global-event-bus/
    if (this.waitForTriggerEvent && this.waitForTriggerEvent > '') {
      EventBus.$on(this.waitForTriggerEvent, threadId => {
        if (threadId === this.threadId) {
          self.loadThread().done(() => {

          }).fail((error) => {
            console.error(error);
          });
        }
      });
    }
  },
  mounted() {
    const self = this;
    self.initVendors();
    // ! Do it on the next tick to be sure the DOM is being updated !
    //Vue.nextTick(() => {
      if (!self.waitForTriggerEvent) {
        self.loadThread().done(() => {
         // ! Do it on the next tick to be sure the DOM is being updated !
          Vue.nextTick(() => {
             self._replaceHtmlMetaFormat();
             self._initChat();
          });
        }).fail((error) => {
          console.error(error);
        });
      }
    //});
  },
  methods: {

    initVendors() {
      const self = this;
      // ! Do it on the next tick to be sure the DOM is being updated !
      Vue.nextTick(() => {
        // init bootstrap tooltips
        $(this).tooltip('dispose'); // dispose previous tooltip
        KTApp.initTooltip($(self.$el));
      });
    },

    trans(key, domain, vars) {
      return Translator.trans(key, vars || {}, domain || 'FOSMessageBundle');
    },

    messageSenderIsMe(userData) {
      if (userData) {
        return userData.hashid == securityUtil.getAuthenticatedUserHash();
      }
    },

    /**
     * Replace HTML meta format to real HTML
     * e.g. [html:br] => <br>
     * @private
     */
    _replaceHtmlMetaFormat() {
      let content = '';
      $(this.$el).find('.kt-chat__text').each((key, val) => {
        content = $(val).html();
        // replace [html:br] => <br>
        content = content.replace(/\[html:br\]/g, '<br>');
        // set new content
        $(val).html(content);
      });
    },

    showNotAuthenticatedInfo() {
      if (!this.isAuthenticated) {
        guiUtil.showLoginExclusiveFeatureInfo();
        return false;
      }
    },

    /**
     * load thread
     * @param feedback
     * @returns {*}
     */
    loadThread(feedback = true) {
      const self = this;
      const def = $.Deferred();

      if (!this.threadId) {
        def.resolve();
        return def;
      }
      const url = JsRouting.generate('api_app_messaging_thread_get', {
        threadId: this.threadId,
      });
      if (feedback) guiUtil.blockUI(self.$el);
      Axios.get(url).then((response) => {
        if (response.data.thread) {
          self.thread = response.data.thread;
          self.messages = new MessageCollection();
          $.each(response.data.thread.messages, (index, messageData) => {
            self.messages.add(new Message({
              id: messageData.id,
              body: messageData.body,
              created_at: messageData.created_at,
              is_read_by_active_user: messageData.is_read_by_active_user,
              metadata: messageData.metadata,
              sender: messageData.sender,
            }));
          });
        }
        def.resolve();
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
          guiUtil.showNotification('error',
            errorString,
            Translator.trans('gui.error_occured'),
          );
        });
        def.reject();
      }).then(() => {
        // 2nd then after catch is always executed - catch MUST be present for this to work
        if (feedback) guiUtil.unblockUI(self.$el);
      });

      return def;
    },

    _initChat() {
      const self = this;
      var scrollEl = KTUtil.find(this.$el, '.scroll');
      var cardBodyEl = KTUtil.find(this.$el, '.card-body');
      var cardHeaderEl = KTUtil.find(this.$el, '.card-header');
      var cardFooterEl = KTUtil.find(this.$el, '.card-footer');

      if (!scrollEl) {
        return;
      }

      // initialize perfect scrollbar(see:  https://github.com/utatti/perfect-scrollbar)
      KTUtil.scrollInit(scrollEl, {
        windowScroll: false, // allow browser scroll when the scroll reaches the end of the side
        mobileNativeScroll: true,  // enable native scroll for mobile
        desktopNativeScroll: false, // disable native scroll and use custom scroll for desktop
        resetHeightOnDestroy: true,  // reset css height on scroll feature destroyed
        handleWindowResize: true, // recalculate hight on window resize
        rememberPosition: true, // remember scroll position in cookie
        height: function () {  // calculate height
          var height;

          if (KTUtil.isBreakpointDown('lg')) { // Mobile mode
            return KTUtil.hasAttr(scrollEl, 'data-mobile-height') ? parseInt(KTUtil.attr(scrollEl, 'data-mobile-height')) : 400;
          } else if (KTUtil.isBreakpointUp('lg') && KTUtil.hasAttr(scrollEl, 'data-height')) { // Desktop Mode
            return parseInt(KTUtil.attr(scrollEl, 'data-height'));
          } else {
            height = KTLayoutContent.getHeight();

            if (scrollEl) {
              height = height - parseInt(KTUtil.css(scrollEl, 'margin-top')) - parseInt(KTUtil.css(scrollEl, 'margin-bottom'));
            }

            if (cardHeaderEl) {
              height = height - parseInt(KTUtil.css(cardHeaderEl, 'height'));
              height = height - parseInt(KTUtil.css(cardHeaderEl, 'margin-top')) - parseInt(KTUtil.css(cardHeaderEl, 'margin-bottom'));
            }

            if (cardBodyEl) {
              height = height - parseInt(KTUtil.css(cardBodyEl, 'padding-top')) - parseInt(KTUtil.css(cardBodyEl, 'padding-bottom'));
            }

            if (cardFooterEl) {
              height = height - parseInt(KTUtil.css(cardFooterEl, 'height'));
              height = height - parseInt(KTUtil.css(cardFooterEl, 'margin-top')) - parseInt(KTUtil.css(cardFooterEl, 'margin-bottom'));
            }
          }

          // Remove additional space
          height = height - 2;

          return height;
        }
      });

      // update scroller to take into account e.g. new post
      KTUtil.scrollUpdate(scrollEl);
      // scroll to bottom
      scrollEl.scrollTop = scrollEl.scrollHeight;
      KTUtil.scrollUpdate(scrollEl);
    },

    archiveThread() {

    },

    processForm() {
      const self = this;
      let url = null;
      const form = this.$refs['submit-form'];
      const formData = new FormData(form);

      guiUtil.blockUI($(self.$el));

      if (!self.thread) {
        // starting new thread
        url = JsRouting.generate('api_app_messaging_thread_post');
      } else {
        // reply to existing thread
        url = JsRouting.generate('api_app_messaging_thread_reply', {threadId: self.thread.hashid});
      }

      Axios.post(url, formData).then((response) => {
        if (response.data.message && response.data.message.hashid) {
          // we submitted a reply to existing thread
          self.$emit('thread-id-update', response.data.message.thread_id);
          self.messages.add(new Message({
            id: response.data.message.hashid,
            body: response.data.message.body,
            created_at: response.data.message.created_at,
            is_read_by_active_user: response.data.message.is_read_by_active_user,
            metadata: response.data.message.metadata,
            sender: response.data.message.sender,
          }));
        } else if (response.data.thread && response.data.thread.hashid) {
          // we submitted the first message to start a thread
          self.$emit('thread-id-update', response.data.thread.hashid);
          self.thread = response.data.thread;
          self.messages = new MessageCollection();
          $.each(response.data.thread.messages, (index, messageData) => {
            self.messages.add(new Message({
              id: messageData.id,
              body: messageData.body,
              created_at: messageData.created_at,
              is_read_by_active_user: messageData.is_read_by_active_user,
              metadata: messageData.metadata,
              sender: messageData.sender,
            }));
          });
        }
        // clear textarea
        $(self.$el).find('textarea').val('');
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
          let notificationTitle = self.trans('gui.error_occured', 'messages');
          let notificationMessage = errorString;
          if (error.response) {
            if (error.response.status === 400) {
              if (error.response.data.code === 2400001) {
                notificationTitle = self.trans('gui.operation_not_permitted', 'messages');
                notificationMessage = self.trans(`api-error.${error.response.data.code}`, 'messages');
              }
            }
          }
          swal.fire({
            title: notificationTitle,
            text: notificationMessage,
            type: 'error',
            buttonsStyling: false,
            confirmButtonText: self.trans('dict.close', 'messages'),
            confirmButtonClass: 'btn btn-danger',
          }).then((dismissReason) => {
            switch (dismissReason.value) {
              case true:
              case false:
            }
          });
        });
      }).then(() => {
        // 2nd then after catch is always executed - catch MUST be present for this to work
        guiUtil.unblockUI($(self.$el));
      });
    },
  }
}
</script>

<style scoped>

</style>
