var render, staticRenderFns
import script from "./recaptcha3-form-field.vue?vue&type=script&lang=js&"
export * from "./recaptcha3-form-field.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d5fdef",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57d5fdef')) {
      api.createRecord('57d5fdef', component.options)
    } else {
      api.reload('57d5fdef', component.options)
    }
    
  }
}
component.options.__file = "assets/js/app/components/recaptcha3-form-field.vue"
export default component.exports