import Vue from 'vue';
import ArticleList from './components/article-list.vue';
import ArticleSingle from './components/article-single.vue';
import ArticlesRelated from './components/articles-related.vue';
import CommentSection from '../comments/components/comment-section.vue';
import 'slick-carousel/slick/slick';
import 'theme/assets/js/hs.slick-carousel';

export default function (anchorIds) {
  anchorIds = anchorIds || [];
  /**
   * #view-public-press-article-list
   */
  ((id) => {
    if (!anchorIds.includes(id)) return false;
    const appContainer = document.getElementById(id);
    if (appContainer) {
      const app1 = new Vue({
        el: appContainer,
        components: {
          ArticleList,
        },
      });
    }
  })('view-public-press-article-list');

  /**
   * #view-public-press-article-single
   */
  ((id) => {
    if (!anchorIds.includes(id)) return false;
    const appContainer = document.getElementById(id);
    if (appContainer) {
      const app1 = new Vue({
        el: appContainer,
        components: {
          ArticleSingle,
          ArticlesRelated,
          CommentSection,
        },
      });
    }
  })('view-public-press-article-single');
}
