import {Model, Collection} from 'vue-mc';
import moment from 'moment';
import globalConfig from 'js/app/config/app';
import SubjectStats from '../../app/models/SubjectStats';

class PostItem extends Model {
  constructor(data) {
    super(data);
    this.init();
  }

  // Default attributes that define the "empty" state.
  defaults() {
    return {
      hashid: '',
      author: '',
      context: '',
      type: '',
      title: '',
      text: '',
      enabled: false,
      medias: [],
      products: [],
      createdAt: '',
      updatedAt: '',
      expiresAt: '',
      stats: new SubjectStats(),
    };
  }

  // Attribute mutations.
  mutations() {
    return {
      hashid: (hashid) => String(hashid) || null,
      // context: (val) => String(val) || 'default',
      // type: (val) => String(val) || 'post',
      title: (title) => {
        let result;
        if (!title || title === 'null') {
          result = '';
        } else result = title;
        return result;
      },
      text: (text) => {
        let result;
        if (!text || text === 'null') {
          result = '';
        } else result = text;
        return result;
      },
      createdAt: (dateTime) => {
        if (dateTime) {
          this.createdAtUnix = moment(dateTime).unix();
          return moment(dateTime).toISOString();
        }
        return null;
      },
      expiresAt: (dateTime) => {
        if (dateTime) {
          this.createdAtUnix = moment(dateTime).unix();
          return moment(dateTime).toISOString();
        }
        return null;
      },
    };
  }

  // Attribute validation
  validation() {
    return {
      hashid: string.and(required),
      context: string.and(required),
      type: string.and(required),
    };
  }

  // // Route configuration
  // routes() {
  //   return {
  //     fetch: '/task/{id}',
  //     save:  '/task',
  //   }
  // }

  init() {
    this.on('change', (options) => {
      switch (options.attribute) {
        case 'id':
          //console.log(options.previous + ' => ' + options.value);
          break;
        default:
          break;
      }
    });
  }
}

export default PostItem;
