import Cookies from 'js-cookie';
import HttpUtil from 'js/app/utils/http';
import CryptoJSCore from 'crypto-js/core';
import CryptoJsAES from 'crypto-js/aes';
import CryptoJSPbkdf2 from 'crypto-js/pbkdf2';
import CryptoJSSHA512 from 'crypto-js/sha512';
import CryptoJsEncUTF8 from 'crypto-js/enc-utf8';
import CryptoJsEncHex from 'crypto-js/enc-hex';

class SecurityUtil {

  constructor() {
    if (Cookies && Cookies.get('APP_SESSION')) {
      // read app cookie store to fetch logged in user data
      this.cookieStoreData = JSON.parse(Cookies.get('APP_SESSION'));
    } else {
      this.cookieStoreData = null;
    }
  }

  isAuthenticatedSession() {
    if (Cookies && Cookies.get('APP_SESSION')) {
      // read app cookie store to fetch logged in user data
      this.cookieStoreData = JSON.parse(Cookies.get('APP_SESSION'));
    } else {
      this.cookieStoreData = null;
    }
    return (this.cookieStoreData && this.cookieStoreData.user_hashid);
  }

  getAuthenticatedUserHash() {
    if (this.isAuthenticatedSession()) {
      return this.cookieStoreData.user_hashid;
    }
  }

  getAuthenticatedUserData() {
    if (this.isAuthenticatedSession()) {
      return this.cookieStoreData;
    } else {
      return null;
    }
  }

  getAcl() {
    let result;
    if (this.cookieStoreData && typeof this.cookieStoreData !== 'undefined') {
      result = this.cookieStoreData.acl;
    }
    return result;
  }

  /**
   * This function is able to decrypt AES encrypted data on the PHP side of our app
   * See \App\Component\Security\Aes::encryptForCryptoJS()
   * @see https://stackoverflow.com/questions/24337317/encrypt-with-php-decrypt-with-javascript-cryptojs
   * Try to replace CryptoJS with WebCrypto because of massively higher performance
   * @see https://medium.com/@encryb/comparing-performance-of-javascript-cryptography-libraries-42fb138116f3
   * @param passphrase
   * @param encryptedData
   * @returns {string}
   */
  phpAesDecrypt(passphrase, encryptedData) {
    const encrypted = encryptedData.ciphertext;
    const salt = CryptoJsEncHex.parse(encryptedData.salt);
    const iv = CryptoJsEncHex.parse(encryptedData.iv);
    const key = CryptoJSPbkdf2(passphrase, salt, { hasher: CryptoJSCore.algo.SHA512, keySize: 64 / 8, iterations: 99});
    const decrypted = CryptoJsAES.decrypt(encrypted, key, { iv: iv});
    return decrypted.toString(CryptoJsEncUTF8);
  }
}

export default new SecurityUtil();
