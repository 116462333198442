<script>
import ComplexifyMixin from 'js/app/mixins/complexify.mixin.js';

export default {
  name: 'FosResetPassword',
  directives: {},
  mixins: [ComplexifyMixin],
  data() {
    return {
      config: {},
    };
  },
  computed: {
  },
  watch: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
};

</script>

<style scoped>

</style>
