class HttpUtil {

  constructor() {

  }

  static allQueryParams(qs) {
    const _qs = qs || window.location.search;
    const __qs = _qs.split('+').join(' ');
    const params = {};
    const re = /[?&]?([^=]+)=([^&]*)/g;
    let tokens;
    while (tokens = re.exec(__qs)) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
  }

  static queryParam(name) {
    const parsedQs = HttpUtil.allQueryParams(window.location.search);
    return parsedQs[name] || null;
  }

  /**
   * Default handler for an axios request error
   * If we do not deal with the error explicitly we should at least pass it to this handler
   * @param axiosError
   */
  static axiosErroDefaultHandler(axiosError) {
    const def = $.Deferred();
    let errorStr = '';
    if (axiosError.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (axiosError.response.data) {
        if (axiosError.response.data.exception) {
          errorStr = axiosError.response.data.exception;
        } else if (axiosError.response.data.info) {
          errorStr = axiosError.response.data.info;
        } else if (axiosError.response.data.message) {
          errorStr = axiosError.response.data.message;
        } else if (axiosError.response.data.msg) {
          errorStr = axiosError.response.data.msg;
        } else {
          errorStr = axiosError.response.status;
        }
      } else {
        errorStr = axiosError.response.status;
      }
      def.resolve(errorStr);
    } else if (axiosError.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      def.resolve('the request was made but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      errorStr = axiosError.message;
      def.resolve(errorStr);
    }
    return def;
  }

  /**
   *
   * @param name
   * @param value
   * @param minutes
   */
  static setCookie(name, value, minutes) {
    let expires = '';
    if (minutes) {
      const date = new Date();
      date.setTime(date.getTime() + (minutes * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    const _value = `${value};secure`;
    document.cookie = name + '=' + (_value || '') + expires + '; path=/;SameSite=Strict';
  }

  /**
   *
   * @param name
   * @returns {string|null}
   */
  static getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    let c = null;
    for (let i = 0; i < ca.length; i++) {
      c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  /**
   *
   * @param name
   */
  static eraseCookie(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}

export default HttpUtil;
