var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c(
        "div",
        {
          staticClass:
            "z-widget-offer-item row no-gutters border-bottom mb-12 mb-md-6 pb-6"
        },
        [
          _c("div", { staticClass: "col-lg-3 mb-4 mb-lg-0" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.item.thumbnails.thumbnail_large,
                  "data-lity": ""
                }
              },
              [
                _c("img", {
                  staticClass: "img-fluid w-100",
                  attrs: {
                    src: _vm.item.thumbnails.thumbnail_medium,
                    alt: "image"
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-9 d-lg-flex flex-column pl-lg-4" }, [
            _c(
              "div",
              { staticClass: "row justify-content-between mb-2 mb-lg-0" },
              [
                _c("div", { staticClass: "col-md-9" }, [
                  _c(
                    "h3",
                    { staticClass: "font-weight-bold text-lh-sm mb-4" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "link-dark text-center",
                          attrs: {
                            href: _vm.localizedUrl(_vm.item),
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(_vm.item.name))]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "btn btn-pill btn-outline-primary btn-xs d-none d-lg-inline",
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openShowOnMap(_vm.item.hashid)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("trans")(
                                  "layout.show_the_location_on_map",
                                  {},
                                  "AppProduct"
                                )
                              )
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row text-nowrap align-items-center justify-content-betweeb mb-2 mb-lg-3"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-lg-5 d-lg-flex align-items-center mb-4 mb-lg-0 d-none d-lg-block"
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "javascript:;",
                                title: _vm.trans(
                                  "layout.show_the_location_on_map",
                                  {},
                                  "AppProduct"
                                )
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.openShowOnMap(_vm.item.hashid)
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "mr-3",
                                attrs: {
                                  src:
                                    "/theme/assets/img/icons/marker-primary.svg",
                                  alt: _vm.trans(
                                    "layout.show_the_location_on_map",
                                    {},
                                    "AppProduct"
                                  )
                                }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.item.address_zip) +
                                  "\n              " +
                                  _vm._s(_vm.item.address_city) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-lg-7 text-lg-right" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-md d-flex align-items-center mb-2 mb-lg-0 d-lg-none"
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "javascript:;",
                                    title: _vm.trans(
                                      "layout.show_the_location_on_map",
                                      {},
                                      "AppProduct"
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.openShowOnMap(_vm.item.hashid)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "mr-3",
                                    attrs: {
                                      src:
                                        "/theme/assets/img/icons/marker-primary.svg",
                                      alt: _vm.trans(
                                        "layout.show_the_location_on_map",
                                        {},
                                        "AppProduct"
                                      )
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.item.address_zip) +
                                      "\n                  " +
                                      _vm._s(_vm.item.address_city) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-4 col-md d-flex align-items-center mb-2 mb-lg-0"
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "javascript:;",
                                    title: _vm.trans(
                                      "layout.show_the_location_on_map",
                                      {},
                                      "AppProduct"
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.openShowOnMap(_vm.item.hashid)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "mr-3",
                                    attrs: {
                                      src:
                                        "/theme/assets/img/icons/address-primary.svg",
                                      alt: _vm.trans(
                                        "layout.show_the_location_on_map",
                                        {},
                                        "AppProduct"
                                      )
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatGeoDistance(_vm.item.distance)
                                      ) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-4 col-md d-flex align-items-center mb-2 mb-lg-0"
                            },
                            [
                              _c("img", {
                                staticClass: "mr-3",
                                attrs: {
                                  src:
                                    "/theme/assets/img/icons/star-primary.svg",
                                  alt: "Icon"
                                }
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.item.rating ? _vm.item.rating : "-"
                                  ) +
                                  "\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-4 col-md d-flex align-items-center mb-2 mb-lg-0"
                            },
                            [
                              _c("img", {
                                staticClass: "mr-3",
                                attrs: {
                                  src:
                                    "/theme/assets/img/icons/like-primary-2.svg",
                                  alt: "Icon"
                                }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.item.stats.favorites) +
                                  "\n              "
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "font-family-book" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("truncate")(_vm.item.description, 300, "...")
                        ) +
                        "\n        "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("search-results-item-stats", {
                  attrs: { stats: _vm.item.stats }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-md-flex mt-md-auto align-items-center text-center text-md-left border-top border-md-top-0 pt-11 pt-md-0 mb-n4 mb-md-0"
              },
              [
                _c("div", { staticClass: "mr-md-9" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-sm btn-wide btn-primary btn-pill font-weight-bold",
                      attrs: {
                        href: _vm.localizedUrl(_vm.item),
                        target: "_blank"
                      }
                    },
                    [_vm._v(_vm._s(_vm._f("trans")("dict.view")))]
                  )
                ]),
                _vm._v(" "),
                _c("price-starting-from-display", {
                  attrs: {
                    "price-key": "global",
                    prices: _vm.item.prices_min.default
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }