<script>
import Axios from "axios";
import guiUtil from "js/app/utils/gui";
import HttpUtil from 'js/app/utils/http';
import Translator from "bazinga-translator";
import JsRouting from 'fos-jsrouting-bundle';

export default {
  name: 'PoiItemForm',
  props: {
    coordinates: {
      type: String,
      default: '',
    },
    successCallback: {
      type: Function,
    },
    errorCallback: {
      type: Function,
    },
  },
  data() {
    return {
      category: null,
      title: null,
      description: null,
    };
  },
  computed: {
    formMethod() {
      return this.$form[0].method.toLowerCase();
    },
    formActionUrl() {
      return this.$form[0].action;
    },
    formName() {
      return this.$form[0].name;
    },
  },
  watch: {},
  created() {
  },
  beforeDestroy() {
    // remove listener to avoid multiple registrations
    this.$off('submit-form');
  },
  beforeCreate() {
  },
  mounted() {
    if ($(this.$el)[0].nodeName === 'FORM') {
      this.$form = $(this.$el);
    } else {
      this.$form = $(this.$el).find('form');
    }
    this.init();
  },
  methods: {
    init() {
      this.$on('submit-form', (options) => {
        this.handleSubmit();
      });
    },
    handleSubmit() {
      let self = this;

      let formData = new FormData(this.$form[0]);

      // always use post - in symfony method spoofing is used, PUT is sent as POST with additional parameter _method="put"
      Axios.post(self.formActionUrl, formData).then((response) => {
        // do not use self.$emit here in axios callbacks !!!
        // for some reason they do not fire and do not reach the parent although they would work
        // just before axios call !!!
        // @see https://forum.quasar-framework.org/topic/4099/solved-how-to-use-emit-inside-axios-response-then/6
        // So I use props of type Function as a workaround here
        // !!! but on the other side it works in other components !!! what the fuck?!
        // May be some side effect of b-modal component? noc fucking clue - check if tme allows
        // Indeed, I checked this in other child component (product-availability-calendar) of a b-modal. It does not work ther either.
        //self.$emit('success', responseData);
        self.successCallback(response.data);
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
        });
        // do not use self.$emit here in axios callbacks !!!
        // for some reason they do not fire and do not reach the parent although they would work
        // just before axios call !!!
        // @see https://forum.quasar-framework.org/topic/4099/solved-how-to-use-emit-inside-axios-response-then/6
        // So I use props of type Function as a workaround
        // !!! but on the other side it works in other components !!! what the fuck?!
        // May be some side effect of b-modal component? noc fucking clue - check if tme allows
        // Indeed, I checked this in other child component (product-availability-calendar) of a b-modal. It does not work ther either.
        //self.$emit('error', error);
        self.errorCallback(error);
      }).then(() => {
        // 2nd then after catch is always executed - catch MUST be present for this to work
      });
    },
  },
  template: '#vuePoiAddFormModalTemplate',
};
</script>

<style scoped>

</style>
