import Parsley from 'parsleyjs';

import 'jquery.complexify';

const ComplexifyMixin = {
  props: ['prefix'],
  data() {
    return {
      passwordFirst: '',
      passwordFirstOk: false,
      passwordSecond: '',
      passwordSecondOk: false,
      passwordSecondEnabled: false,
      enableSubmit: false,
    };
  },
  computed: {
    form_method() {
      return this.$form.method.toLowerCase();
    },
    form_action() {
      return this.$form.action;
    },
    passwordFirstClass() {
      let cssClass = null;
      if (this.passwordFirst.length > 0) {
        cssClass = `complexify-password__${this.passwordFirstOk ? 'is-ok' : 'is-not-ok'}`;
      } else {
        cssClass = '';
      }
      return cssClass;
    },
    passwordSecondClass() {
      let cssClass = null;
      if (this.passwordSecond.length > 0) {
        cssClass = `complexify-password__${this.passwordSecondOk ? 'is-ok' : 'is-not-ok'}`;
      } else {
        cssClass = '';
      }
      return cssClass;
    },
  },
  watch: {
    passwordFirstOk(newVal, oldVal) {
      if (!newVal) {
        this.passwordSecond = '';
        this.passwordSecondOk = false;
      }
    },
    passwordSecond(newVal, oldVal) {
      // Validate the second password field
      // Make sure its value equals the first's
      if (newVal > '' && this.passwordFirstOk && newVal === this.passwordFirst) {
        this.passwordSecondOk = true;
      } else {
        this.passwordSecondOk = false;
      }
    },
    $data: {
      handler(val, oldVal) {
        if (this.validator && this.validator.isValid()) {
          this.enableSubmit = true;
        } else {
          this.enableSubmit = false;
        }
      },
      deep: true,
    },
  },

  created() {
    this.validator = null;
    this.$form = null;
  },

  mounted() {
    this.initComplexifyStuff();
  },

  methods: {
    initComplexifyStuff(config) {
      const def = $.Deferred();

      if (typeof config === 'undefined') {
        this.config = config;
      }

      // find the form component
      if ($(this.$el)[0].nodeName === 'FORM') {
        this.$form = $(this.$el);
      } else {
        this.$form = $(this.$el).find('form');
      }

      this.initParsley();

      this.initComplexify(this.$form.find(`#${this.prefix}plainPassword_first`));

      def.resolve();
      return def;
    },

    /**
     *
     */
    initParsley() {
      const vm = this;

      if (!window.Parsley.hasValidator('complexEnough')) {
        window.Parsley
          .addValidator('complexEnough', {
            requirementType: 'string',
            validateString(value, requirement) {
              return vm[`${requirement}Ok`] === true;
            },
            messages: {
              en: 'Not complex enough!',
              pl: 'Not complex enough!',
            },
          });
      }
      if (!window.Parsley.hasValidator('equalTo')) {
        window.Parsley
          .addValidator('equalTo', {
            requirementType: 'string',
            validateString(value, requirement) {
              return value === vm[requirement];
            },
            messages: {
              en: 'Passwords are not equal!',
              pl: 'Passwords are not equal!',
            },
          });
      }

      // init parsley
      if (this.$form.length > 0) {
        this.validator = new Parsley.Factory(this.$form[0], window.ParsleyConfig);
      }
    },

    /**
     *
     * @param $jqPasswordField
     */
    initComplexify($jqPasswordField) {
      const self = this;

      const $barjQueryElementWrapper = $(this.$refs['pass-check-bar-wrapper']);
      const $barjQueryElementBlind = $barjQueryElementWrapper.find('.pass-check-bar-blind');
      const $barjQueryElement = $barjQueryElementWrapper.find('.pass-check-bar');

      // equalize width of the color bar with the wrapper
      const barElWidth = $barjQueryElementWrapper.width();
      $barjQueryElement.width(barElWidth);

      // set the with of the blind to 33% of the wrapper revealing the red bar by default
      $barjQueryElementBlind.width(barElWidth * 0.33);

      // Use the complexify plugin on the first password field
      const complex = $jqPasswordField.complexify(
        { minimumChars: 6, strengthScaleFactor: 0.7 },
        (valid, complexity) => {
          // var barWidth = (320 / 100) * complexity;
          // reveal the red bar by default
          let barWidth = (barElWidth / 100) * 33;
          if (valid) {
            if (complexity > 60) {
              // reveal the green bar if complexity good
              barWidth = (barElWidth / 100) * 100;
            } else {
              // reveal the yellow bar if complexity sufficient
              barWidth = (barElWidth / 100) * 66;
            }
          }

          $barjQueryElementBlind.width(barWidth);

          if (valid) {
            self.passwordSecondEnabled = true;
            self.passwordFirstOk = true;
          } else {
            self.passwordSecondEnabled = false;
            self.passwordFirstOk = false;
          }
        });
    },
  },

};

export default ComplexifyMixin;
