var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown" }, [
    _c(
      "div",
      {
        staticClass: "topbar-item",
        attrs: {
          "data-toggle": "dropdown",
          "data-offset": "10px,0px",
          role: "button"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "btn btn-icon btn-clean btn-dropdown btn-lg",
            class: { "pulse pulse-primary": _vm.attention }
          },
          [
            _c(
              "span",
              {
                staticClass: "svg-icon svg-icon-xs",
                class: {
                  "svg-icon-primary": _vm.attention,
                  "svg-icon-success": !_vm.attention
                }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      id: "mail",
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "12",
                      height: "13",
                      viewBox: "0 0 8.711 6.524"
                    }
                  },
                  [
                    _c("path", {
                      staticClass: "pulsate",
                      attrs: {
                        id: "Path_536",
                        "data-name": "Path 536",
                        d:
                          "M6.9,67.484a.858.858,0,0,0,.854,0h0L11.631,64.9a.94.94,0,0,0-.887-.633H3.91a.94.94,0,0,0-.887.633L6.9,67.484h0Z",
                        transform: "translate(-2.972 -64.267)",
                        fill: "#76ba40"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "pulsate",
                      attrs: {
                        id: "Path_537",
                        "data-name": "Path 537",
                        d:
                          "M5.066,137.924h0a1.366,1.366,0,0,1-1.42,0h0L0,135.494v4.374a.94.94,0,0,0,.939.938H7.772a.94.94,0,0,0,.939-.938v-4.374Z",
                        transform: "translate(0 -134.282)",
                        fill: "#76ba40"
                      }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.attention ? _c("span", { staticClass: "pulse-ring" }) : _vm._e()
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "dropdown-menu dropdown-menu-left dropdown-menu-xl py-5 kt-notification kt-scroll",
        attrs: {
          "data-scroll": "true",
          "data-height": "300",
          "data-mobile-height": "200"
        }
      },
      [
        _vm.messages.models.length === 0
          ? _c("div", { staticClass: "navi" }, [
              _c("div", { staticClass: "navi-item" }, [
                _c("span", { staticClass: "navi-link" }, [
                  _vm._v(_vm._s(_vm._f("trans")("messages.no_new_messages")))
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.messages.models.length > 0
          ? _c(
              "ul",
              { staticClass: "navi navi-hover navi-icon-center navi-active" },
              _vm._l(_vm.messages.models, function(message) {
                return _c(
                  "li",
                  {
                    key: message.id,
                    staticClass: "navi-item kt-notification__item"
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "navi-link",
                        class: {
                          read: message.is_read,
                          unread: !message.is_read
                        },
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function(e) {
                            return _vm.itemClicked(message, e)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "navi-icon text-center" }, [
                          _c("i", { class: _vm.getMessageIcon(message) })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "navi-text" }, [
                          _c("div", { staticClass: "d-block" }, [
                            _vm._v(_vm._s(message.sender.fullName()) + ": "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("truncate")(message.body, 50, " ...")
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              _vm._s(_vm._f("age")(message.created_at)) + " "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        !message.is_read
                          ? _c("span", { staticClass: "navi-label" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "label label-light-danger font-weight-bolder label-inline"
                                },
                                [_vm._v(_vm._s(_vm._f("trans")("dict.new")))]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "separator separator-solid mt-4" }),
        _vm._v(" "),
        _vm._m(0)
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "navi-footer pt-5 d-flex justify-content-center" },
      [
        _c(
          "a",
          {
            staticClass: "btn btn-md btn-outline-primary btn-pill",
            attrs: { href: "https://zamiastowi.cichosz.com/logout" }
          },
          [_vm._v("Skrzynka pocztowa")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }