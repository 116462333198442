var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown" }, [
    _c(
      "div",
      {
        staticClass: "topbar-item",
        attrs: {
          "data-toggle": "dropdown",
          "data-offset": "10px,0px",
          role: "button"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "btn btn-icon btn-clean btn-dropdown btn-lg",
            class: { "pulse pulse-primary": _vm.attention }
          },
          [
            _c(
              "span",
              {
                staticClass: "svg-icon svg-icon-xs",
                class: {
                  "svg-icon-success": !_vm.attention,
                  "svg-icon-primary": _vm.attention
                }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      id: "bell",
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "12",
                      height: "13",
                      viewBox: "0 0 6.943 7.5"
                    }
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Group_346",
                          "data-name": "Group 346",
                          transform: "translate(0)"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: { id: "Group_345", "data-name": "Group 345" }
                          },
                          [
                            _c("path", {
                              attrs: {
                                id: "Path_534",
                                "data-name": "Path 534",
                                d:
                                  "M22.595,4.521V2.753a2.748,2.748,0,1,0-5.5,0V4.521l-.714,1.411h6.943Z",
                                transform: "translate(-16.384)",
                                fill: "#76ba40"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Group_348",
                          "data-name": "Group 348",
                          transform: "translate(2.3 6.341)"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: { id: "Group_347", "data-name": "Group 347" }
                          },
                          [
                            _c("path", {
                              attrs: {
                                id: "Path_535",
                                "data-name": "Path 535",
                                d:
                                  "M151.552,372.736a1.159,1.159,0,0,0,2.317,0Z",
                                transform: "translate(-151.552 -372.736)",
                                fill: "#76ba40"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.attention ? _c("span", { staticClass: "pulse-ring" }) : _vm._e()
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "dropdown-menu dropdown-menu-left dropdown-menu-xl py-5 kt-notification"
      },
      [
        _vm.notifications.models.length === 0
          ? _c("div", { staticClass: "navi" }, [
              _c("div", { staticClass: "navi-item" }, [
                _c("span", { staticClass: "navi-link" }, [
                  _vm._v(
                    _vm._s(_vm._f("trans")("notification.no_new_notifications"))
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.notifications.models.length > 0
          ? _c(
              "ul",
              {
                staticClass: "navi navi-hover navi-icon-center navi-active",
                attrs: {
                  "data-scroll": "true",
                  "data-height": "300",
                  "data-mobile-height": "200"
                }
              },
              _vm._l(_vm.notifications.models, function(notification) {
                return _c(
                  "li",
                  {
                    key: notification.id,
                    staticClass: "navi-item kt-notification__item"
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "navi-link",
                        class: {
                          read: notification.is_read,
                          unread: !notification.is_read
                        },
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function(e) {
                            return _vm.itemClicked(notification, e)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "navi-icon text-center" }, [
                          _c("i", {
                            class: _vm.getNotificationIcon(notification)
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "navi-text" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "d-block",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.getNotificationTitle(notification)
                                  )
                                }
                              },
                              [_vm._v("---")]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                _vm._s(_vm._f("age")(notification.timestamp)) +
                                  " "
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.getActions(notification), function(
                              action,
                              index
                            ) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  class: "btn btn-label-" + action.css_class,
                                  on: {
                                    click: function($event) {
                                      return _vm.actionClick(
                                        action,
                                        notification
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("trans")(
                                        "notification.action." + index
                                      )
                                    )
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        !notification.is_read
                          ? _c("span", { staticClass: "navi-label" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "label label-light-danger font-weight-bolder label-inline"
                                },
                                [_vm._v(_vm._s(_vm._f("trans")("dict.new")))]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }