import {Model, Collection} from 'vue-mc';

class User extends Model {

  constructor(data) {
    super(data);
    this.init();
  }

  // Default attributes that define the "empty" state.
  defaults() {
    return {
      hashid: "",
      account_type: "",
      account_status: "",
      user_class: "",
      first_name: "",
      last_name: "",
      salutation: "",
      thumbnails: [],
      initials: ""
    };
  }

  // Attribute mutations.
  mutations() {
    return {
      id: (id) => Number(id) || null,
      code: String,
      type: String,
      first_name: (val) => {
        if (val) {
          this.set({initials: (this.get('first_name').charAt(0) + this.get('last_name').charAt(0)).toUpperCase()});
        }
        return val;
      }
    };
  }

  // Attribute validation
  validation() {
    return {
      id: integer.and(min(1)).or(equal(null)),
      code: string.and(required),
      type: string.and(required),
      label: string.and(required),
      hashid: string.and(required),
    };
  }

  // // Route configuration
  // routes() {
  //   return {
  //     fetch: '/task/{id}',
  //     save:  '/task',
  //   }
  // }

  init() {
    this.on('change', function (options) {
      switch (options.attribute) {
        case 'code':
          //console.log(options.previous + ' => ' + options.value);
          break;
      }
    });
  }

  fullName() {
    return this.get('first_name') + ' ' + this.get('last_name');
  }

}

export default User;
