<template>
  <div v-if="showStats" class="col-md-3 text-md-right pt-md-2">
    <div v-for="statItem in enumeratedStats" :key="statItem.type"
         class="text-primary text-center border-md-bottom mb-3 pb-2 d-flex d-md-block">
      <img class="mr-3 mr-md-0 mb-md-1" :src="statIcon(statItem.productType)" alt="Image decription"
           style="width: 21.5px;">
      <div><strong>{{ statItem.value }}</strong>
        {{ statItem.transKey | trans({},'AppProduct', statItem.value) }}
      </div>
      <!--price-starting-from-display
        :price-key="index"
        :prices="item.prices_min.default"
      ></price-starting-from-display-->
    </div>
  </div>
</template>

<script>
import SmileRating from 'js/app/components/smile-rating';
import PriceStartingFromDisplay from 'js/product-pricing/components/price-starting-from-display.vue';
import JsRouting from 'fos-jsrouting-bundle';
import 'lity';
import 'css/lity.scss';
import Translator from 'bazinga-translator';

export default {
  name: 'SearchResultsItemStats',
  components: {},
  props: {
    stats: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    enumeratedStats() {
      const data = [];
      let transKey = '';
      for (const [key, value] of Object.entries(this.stats)) {
        if (key.includes('subproduct_types_') && parseInt(value, 10) > 0) {
          transKey = `dict.product_type.${key.replace('subproduct_types_', '')}`;
          data.push({
            type: key,
            productType: key.replace('subproduct_types_', ''),
            transKey: transKey,
            value: value,
          });
        }
      }
      return data;
    },
    showStats() {
      return this.enumeratedStats.length > 0;
    },
  },
  mounted() {
  },
  methods: {
    trans(key, params, domain, multipleCount) {
      if (multipleCount) {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'AppProduct');
      } else {
        return Translator.trans(key, params || {}, domain || 'AppProduct');
      }
    },
    statIcon(productType) {
      const map = {
        'accommodation_room': 'house-primary.svg',
        'accommodation_apartment': 'apartament-primary.svg',
        'accommodation_bungalow': 'house-primary.svg',
        'accommodation_holiday_house': 'house-primary.svg',
        'accommodation_camping_house': 'campsite-primary.svg',
        'accommodation_tent': 'campsite-primary.svg',
      };
      const iconFile = (typeof map[productType] !== 'undefined' ? map[productType] : 'house-primary.svg');
      return `/theme/assets/img/icons/${iconFile}`;
    },
  },
};
</script>

<style scoped>

</style>
