<script>
import Vue from "vue";
import Axios from "axios/index";
import guiUtil from "js/app/utils/gui";
import HttpUtil from 'js/app/utils/http';
import Translator from "bazinga-translator";
import JsRouting from 'fos-jsrouting-bundle';
import Parsley from 'parsleyjs';
import ComplexifyMixin from 'js/app/mixins/complexify.mixin.js';

require('jquery.complexify');

export default {
  name: 'ChangePassword',
  directives: {},
  mixins: [ComplexifyMixin],
  props: ['prefix'],
  data() {
    return {
      config: {},
    };
  },
  computed: {
    formMethod() {
      return this.$form[0].method.toLowerCase();
    },
    formAction() {
      return this.$form[0].action;
    },
  },
  watch: {
  },

  created() {
  },

  mounted() {
  },

  /* BEGIN: METHODS*/
  methods: {

    /**
     * submit the form
     */
    processForm(event) {
      const self = this;
      if (this.validator.isValid()) {
        const formData = new FormData(this.$form[0]);
        guiUtil.blockUI(this.$form);
        Axios.post(this.formAction, formData).then((response) => {
          // the FOS REST endpoint for changing password sends a 302 redirect
          // so we do not need to do anything here
        }).catch((error) => {
          HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
            guiUtil.showNotification('error',
              errorString,
              Translator.trans('gui.error_occured')
            );
          });
        }).then(() => {
          // 2nd then after catch is always executed - catch MUST be present for this to work
          guiUtil.unblockUI(this.$form);
        });
      }
    },

    /**
     * submit the form
     */
    gotoProfile(event) {
      window.location.href = JsRouting.generate('fos_user_profile_edit');
    },
  },
  template: '#x-template-view-user-change_password',
};

</script>

<style scoped>

</style>
