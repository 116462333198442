var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "poi-location-manage", attrs: { id: "poi-location-manage" } },
    [
      _c("div", { ref: "map-canvas", attrs: { id: "map-canvas" } }),
      _vm._v(" "),
      _c("b-modal", {
        ref: "modal-poi-add",
        attrs: {
          id: "poi-add-modal",
          size: "xl",
          centered: "",
          static: "",
          lazy: "",
          title: _vm.trans("poi.manager.propose_poi")
        },
        on: { ok: _vm.submitPoiNewModalForm },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("poi-item-form", {
                  ref: "poi-item-form",
                  attrs: {
                    coordinates: _vm.newPoiCoordinates,
                    "success-callback": _vm.onNewFormSubmitSuccess,
                    "error-callback": _vm.onNewFormSubmitFailure
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("b-modal", {
        ref: "modal-poi-edit",
        attrs: {
          id: "poi-edit-modal",
          size: "xl",
          centered: "",
          static: "",
          lazy: "",
          title: _vm.trans("poi.manager.edit_poi")
        },
        on: { ok: _vm.submitPoiEditModalForm },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("poi-item-edit-form", {
                  ref: "poi-item-form",
                  attrs: {
                    "poi-item": _vm.currentPoiItem,
                    "success-callback": _vm.onEditFormSubmitSuccess,
                    "error-callback": _vm.onEditFormSubmitFailure
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }