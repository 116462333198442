<!--template>
inline-template used
</template-->

<script>
import 'babel-polyfill'; // needed to support "async + await"
import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

export default {
  name: 'Recaptcha3FormField',
  props: {
    recaptcha3Key: {
      type: String,
      default: '',
    },
    validJsId: {
      type: String,
      default: '',
    },
    actionKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      token: null,
    };
  },
  watch: {
    token(val) {

    },
  },
  created() {
    Vue.use(VueReCaptcha, { siteKey: this.recaptcha3Key });
  },
  mounted() {
    // init recaptcha as last action because of async nature
    this.execRecaptcha();
  },
  methods: {
    async execRecaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with given action key.
      this.token = await this.$recaptcha(this.actionKey);
      // Do stuff with the received token.
    },
  },
};
</script>

<style scoped>

</style>
