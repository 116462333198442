/**
 * Make a periodic call to the REST API to check whether a
 * valid login session is present.
 * This information will be stored in a client side cookie to
 * show/hide UI elements
 * Use this approach only if the backend logic from KernelListener and SecuritySubscriber fails
 */

import JsRouting from 'fos-jsrouting-bundle';
import Axios from 'axios';
import Cookies from 'js-cookie';
import HttpUtil from './http';
import securityUtil from './security';
import globalState from '../store/GlobalStore';

export default {
  data: {
    polling: null,
    frequencyMilliseconds: 30000,
  },
  created() {
    this.initPolling();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    initPolling() {
      const self = this;
      // further polling
      this.polling = setInterval(() => {
        self.pollData();
      }, self.frequencyMilliseconds);
    },
    pollData(force) {
      const def = $.Deferred();
      // check for authenticated session only if the cookie is already present
      // which means that the user has actually logged in himself
      if (force === true || securityUtil.isAuthenticatedSession()) {
        const url = JsRouting.generate('webapi_session_check_user_session');
        Axios.get(url).then((response) => {
          globalState.commit('anonymousVisit', false);
          def.resolve();
        }).catch((error) => {
          def.reject();
          HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
            globalState.commit('anonymousVisit', true);
            if (error.response.status === 401 || error.response.status === 403) {
              // no active user session, delete the cookie
              Cookies.remove('APP_SESSION');
            }
          });
        }).then(() => {
          // 2nd then after catch is always executed - catch MUST be present for this to work
        });
      }
      return def;
    },
  },
};
