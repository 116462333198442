<template>
  <!--begin: Messages -->
  <div class="dropdown">
    <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" role="button">
      <div class="btn btn-icon btn-clean btn-dropdown btn-lg" :class="{'pulse pulse-primary': attention}">
        <span class="svg-icon svg-icon-xs" :class="{'svg-icon-primary': attention, 'svg-icon-success': !attention}">
            <svg id="mail" xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 8.711 6.524">
              <path class="pulsate" id="Path_536" data-name="Path 536" d="M6.9,67.484a.858.858,0,0,0,.854,0h0L11.631,64.9a.94.94,0,0,0-.887-.633H3.91a.94.94,0,0,0-.887.633L6.9,67.484h0Z" transform="translate(-2.972 -64.267)" fill="#76ba40"/>
              <path class="pulsate" id="Path_537" data-name="Path 537" d="M5.066,137.924h0a1.366,1.366,0,0,1-1.42,0h0L0,135.494v4.374a.94.94,0,0,0,.939.938H7.772a.94.94,0,0,0,.939-.938v-4.374Z" transform="translate(0 -134.282)" fill="#76ba40"/>
            </svg>
        </span>
        <span v-if="attention" class="pulse-ring"></span>
      </div>
    </div>

    <div
      class="dropdown-menu dropdown-menu-left dropdown-menu-xl py-5 kt-notification kt-scroll"
      data-scroll="true"
      data-height="300"
      data-mobile-height="200"
    >
        <!--div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              {{ 'messages.user_messages'|trans }}
              <span v-if="messageCount > 0" class="label label-sm label-danger">
                {{ messageCount }} {{ 'dict.new'|trans }}</span>
            </h3>
          </div>
        </div-->
      <div v-if="messages.models.length === 0" class="navi">
        <div class="navi-item">
          <span class="navi-link">{{ 'messages.no_new_messages'|trans }}</span>
        </div>
      </div>
      <ul v-if="messages.models.length > 0" class="navi navi-hover navi-icon-center navi-active">
        <li class="navi-item kt-notification__item" v-for="message in messages.models" :key="message.id">
          <a
            class="navi-link"
            :class="{'read': message.is_read, 'unread': !message.is_read}"
            href="javascript:;"
            @click="function(e) {return itemClicked(message, e)}"
          >
            <div class="navi-icon text-center">
              <i :class="getMessageIcon(message)"></i>
            </div>
            <div class="navi-text">
              <div
                class="d-block"
              >{{ message.sender.fullName() }}: <span>{{ message.body | truncate(50,' ...') }}</span>
              </div>
              <span class="text-muted">{{ message.created_at | age }}&nbsp;</span>
            </div>
            <span v-if="!message.is_read" class="navi-label">
              <span class="label label-light-danger font-weight-bolder label-inline">{{ 'dict.new'|trans }}</span>
            </span>
          </a>
        </li>
      </ul>
      <div class="separator separator-solid mt-4"></div>
      <div class="navi-footer pt-5 d-flex justify-content-center">
        <a href="https://zamiastowi.cichosz.com/logout" class="btn btn-md btn-outline-primary btn-pill">Skrzynka pocztowa</a>
      </div>
    </div>
  </div>
  <!--end: Messages -->
</template>

<script>
import Message from 'js/messaging/models/Message';
import MessageCollection from 'js/messaging/models/MessageCollection';
import JsRouting from 'fos-jsrouting-bundle';
import Axios from 'axios';
import securityUtil from 'js/app/utils/security';
import globalConfig from 'js/app/config/app';
import guiUtil from 'js/app/utils/gui';
import HttpUtil from 'js/app/utils/http';
import MercureUtil from 'js/app/utils/MercureUtil';
import globalState from '../store/GlobalStore';
import {EventSourcePolyfill} from "event-source-polyfill";

export default {
  name: 'TopbarUnreadMessages',
  store: globalState,
  data() {
    return {
      messages: new MessageCollection(),
      jwtToken: '-empty-',
      useAuthorizationHeader: true,
    };
  },
  computed: {
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    },
    messageCount() {
      return this.messages.length;
    },
    attention() {
      return this.messages.length > 0;
    },
  },
  watch: {
    messages(curMessages) {
      this.$nextTick(() => {
        if (curMessages.length > 0) {
          guiUtil.notifyWindowTitle(`${curMessages.length} new messages`);
          guiUtil.playNotificationSound();
        }
      });
    },
  },
  created() {
  },
  mounted() {
    try {
      this.init();
    } catch (error) {
      throw new Error(error);
    }
  },
  beforeDestroy() {
    if (this.es) {
      this.es.close();
    }
  },
  methods: {
    init() {
      const self = this;

      if (this.isAuthenticated) {
        this.mercureSubscribe();
        this.fetchMessages();
      }

      this.initPlugins();
    },

    mercureSubscribe() {
      const self = this;
      let updateEventSource;
      this.jwtToken = HttpUtil.getCookie('mercureAuthorization');

      if (updateEventSource) {
        updateEventSource.close();
      }
      // subscribe for mercure events
      const topic = `zamiastowi.pl/mailbox_${securityUtil.getAuthenticatedUserHash()}`;
      const esUri = new URL(globalConfig.mercureHub);
      esUri.searchParams.append('topic', topic);
      if (self.lastEventId) {
        esUri.searchParams.append('Last-Event-ID', self.lastEventId);
      }

      if (this.useAuthorizationHeader && this.jwtToken) {
        updateEventSource = new EventSourcePolyfill(esUri, {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          },
        });
      } else {
        updateEventSource = new EventSource(esUri, {
          withCredentials: true,
        });
      }

      updateEventSource.onmessage = function (e) {
        // Will be called every time an update is published by the mercure server
        self.lastEventId = e.lastEventId;
        console.log('Mailbox update!');
        self.fetchMessages();
        globalState.commit('mailboxChangeIncrement', 1);
      };
      updateEventSource.onerror = console.log;
      updateEventSource.ontimeout = function (e) {
      };
    },

    initPlugins() {
    },

    trans(key, params, domain, multipleCount) {
      if (multipleCount) {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'FOSMessageBundle');
      } else {
        return Translator.trans(key, params || {}, domain || 'FOSMessageBundle');
      }
    },

    gotoInbox() {
      const url = JsRouting.generate('app_my_messages', {}, true);
      window.location.href = url;
    },

    /**
     * Fetch new notifications from remote
     */
    fetchMessages() {
      let def = $.Deferred();
      const self = this;
      let url = JsRouting.generate('api_app_messaging_inbox_status', {}, true);
      // Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
      // Axios.defaults.withCredentials = true;
      // Axios.defaults.credentials = 'same-origin';
      Axios.get(url).then((response) => {
        if (response.data) {
          let col = new MessageCollection();
          if (typeof response.data.unread == 'undefined') {
            return false;
          }
          $.each(response.data.unread, function (key, val) {
            col.add(new Message({
              id: val.id,
              created_at: val.created_at,
              created_by: val.created_by,
              body: val.body,
              sender: val.sender,
              metadata: val.metadata,
              is_read_by_active_user: val.is_read_by_active_user,
              thread_id: val.thread_id
            }));
          });
          self.messages = col;
        }
        def.resolve();
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
        });
        // do nothing
        def.reject();
      });
      return def;
    },

    getMessageIcon(message) {
      return 'flaticon2-chat-1 text-secondary';
    },

    /**
     *
     */
    itemClicked(message) {
      let url = null;
      if (typeof message.url !== 'undefined' && message.url > '') {
        url = message.url;
        // delete this message from local storage
        //this.notifications = this.localStorageDelete(message);
        return false;
      } else {
        url = JsRouting.generate('app_my_messages') + '#/inbox/thread/' + message.thread_id;
      }
      if (document.location.href === url) {
        console.log('reload');
        window.location.reload(false);
      } else {
        document.location.href = url;
      }
    },

    // /**
    //  * Update local storage notification bag
    //  * @param newNotifications
    //  */
    // localStorageUpdate(newNotifications) {
    //
    //   // save new notifications in local storage
    //   let currentItems = JSON.parse(window.localStorage.getItem('notifications')) || {};
    //   $.each(newNotifications, function(key,val) {
    //     if (!currentItems[val.id]) {
    //       currentItems[val.id] = val;
    //     }
    //   });
    //   window.localStorage.setItem('notifications', JSON.stringify(currentItems));
    //
    //   let col = new NotificationCollection();
    //   $.each(currentItems, function(key,val) {
    //     col.add(new Notification({
    //       'id': val.id,
    //       'url': val.url,
    //       'data': val.data,
    //       'title': val.title,
    //       'timestamp': val.created_at,
    //       'type': val.type
    //     }));
    //   });
    //
    //   return col;
    // },
    //
    // /**
    //  * Delete item from local storage
    //  * @param notification
    //  */
    // localStorageDelete(notification) {
    //   let currentItems = JSON.parse(window.localStorage.getItem('notifications')) || {};
    //   delete currentItems[notification.id];
    //   window.localStorage.setItem('notifications', JSON.stringify(currentItems));
    //
    //   let col = new NotificationCollection();
    //   $.each(currentItems, function(key,val) {
    //     col.add(new Notification({
    //       'id': val.id,
    //       'url': val.url,
    //       'data': val.data,
    //       'label': val.label,
    //       'timestamp': val.created_at,
    //       'type': val.type
    //     }));
    //   });
    //
    //   return col;
    // },
  },
};
</script>

<style scoped>

</style>
