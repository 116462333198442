/**
 * @see https://vuemc.io/#basic-usage
 */
import {Model, Collection} from 'vue-mc';
import Activity from "./Activity";

/**
 * Activity collection
 */
class ActivityCollection extends Collection {

  // Model that is contained in this collection.
  model() {
    return Activity;
  }

  // Default attributes
  defaults() {
    return {
      orderBy: 'code',
    };
  }

}

export default ActivityCollection;
