import params from './parameters';

const globalConfig = $.extend(params, {

  // default parsley config
  parsley: {
    defaultConfig: {
      successClass: 'is-valid',
      errorClass: 'is-invalid',
      classHandler(el) {
        //let $appendElement = el.$element.closest('.form-group');
        const $appendElement = el;
        return el;
      },
      errorsContainer(el) {
        let $appendElement = el;
        if (el.$element.attr('type') === 'checkbox') {
          $appendElement = el.$element.closest('.form-group');
        }
        return $appendElement;
      },
      errorsWrapper: '<div class="invalid-feedback"></div>',
      errorTemplate: '<div class="invalid-error"></div>',
    },
  },

});

export default globalConfig;
