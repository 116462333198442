var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.user
      ? _c(
          "div",
          {
            staticClass: "metronic-scope d-none d-lg-flex justify-content-end"
          },
          [
            _c(
              "div",
              {
                staticClass: "topbar-item",
                class: {
                  "user-border-right":
                    _vm.notificationsActive || _vm.messagingActive
                },
                attrs: { "data-offset": "10px,0px", role: "button" }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-icon btn-clean btn-lg px-2",
                    class: {
                      "text-success": !_vm.user.impersonated,
                      "text-danger": _vm.user.impersonated
                    },
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.gotoUserDashboard()
                      }
                    }
                  },
                  [
                    _vm.useAvatars && _vm.avatar
                      ? _c("div", [
                          _vm.avatar
                            ? _c("img", {
                                attrs: { src: _vm.avatar, id: "img-avatar-top" }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.avatar && _vm.user.salutation === "mr"
                            ? _c("img", {
                                attrs: {
                                  src: "/assets/global/img/heads-male.png",
                                  id: "img-avatar-top",
                                  alt: ""
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.avatar && _vm.user.salutation !== "mr"
                            ? _c("img", {
                                attrs: {
                                  src: "/assets/global/img/heads-female.png",
                                  id: "img-avatar-top",
                                  alt: ""
                                }
                              })
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "svg-icon svg-icon-xs mr-2",
                        class: {
                          "svg-icon-success": !_vm.user.impersonated,
                          "svg-icon-danger": _vm.user.impersonated
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "6.449",
                              height: "8.21",
                              viewBox: "0 0 6.449 8.21"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "user",
                                  transform: "translate(-80.04 -32)"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    id: "Path_12",
                                    "data-name": "Path 12",
                                    d:
                                      "M86.489,227.873a4.4,4.4,0,0,0-.952-2.776,2.9,2.9,0,0,0-4.544,0,4.4,4.4,0,0,0-.952,2.772,7.857,7.857,0,0,0,3.225.822A7.175,7.175,0,0,0,86.489,227.873Z",
                                    transform: "translate(0 -188.482)",
                                    fill: "#ffc700"
                                  }
                                }),
                                _vm._v(" "),
                                _c("circle", {
                                  attrs: {
                                    id: "Ellipse_1",
                                    "data-name": "Ellipse 1",
                                    cx: "1.613",
                                    cy: "1.613",
                                    r: "1.613",
                                    transform: "translate(81.652 32)",
                                    fill: "#ffc700"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.user.impersonated
                      ? _c("span", { staticClass: "topbar-item--username" }, [
                          _vm._v(
                            "Witaj " +
                              _vm._s(
                                _vm.user.firstName
                                  ? " " + _vm.user.firstName
                                  : ""
                              )
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.impersonated
                      ? _c("span", { staticClass: "topbar-item--username" }, [
                          _vm._v(
                            "Witaj " +
                              _vm._s(
                                _vm.user.firstName
                                  ? " [" + _vm.user.firstName + "]"
                                  : ""
                              )
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.notificationsActive
              ? _c("topbar-user-notifications")
              : _vm._e(),
            _vm._v(" "),
            _vm.messagingActive ? _c("topbar-unread-messages") : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown" }, [
              _c(
                "div",
                {
                  staticClass: "topbar-item",
                  attrs: {
                    "data-toggle": "dropdown",
                    "data-offset": "10px,0px",
                    role: "button"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-icon btn-clean btn-dropdown btn-lg"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "svg-icon svg-icon-success svg-icon-xs"
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticStyle: { transform: "rotate(180deg)" },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                width: "24px",
                                height: "24px",
                                viewBox: "0 0 24 24",
                                version: "1.1"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    stroke: "none",
                                    "stroke-width": "1",
                                    fill: "none",
                                    "fill-rule": "evenodd"
                                  }
                                },
                                [
                                  _c("rect", {
                                    attrs: {
                                      x: "0",
                                      y: "0",
                                      width: "24",
                                      height: "24"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M3.95428417,19 L20.0457158,19 C20.3218582,19 20.5457158,18.7761424 20.5457158,18.5 C20.5457158,18.3982978 20.5147019,18.2990138 20.4568119,18.215395 L12.4110961,6.59380547 C12.2539131,6.36676337 11.9424371,6.31013137 11.715395,6.46731437 C11.6659703,6.50153145 11.623121,6.54438079 11.5889039,6.59380547 L3.54318807,18.215395 C3.38600507,18.4424371 3.44263707,18.7539131 3.66967918,18.9110961 C3.75329796,18.968986 3.85258194,19 3.95428417,19 Z",
                                      fill: "#000000"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "dropdown-menu dropdown-menu-left dropdown-menu-md p-0"
                },
                [
                  _c(
                    "div",
                    { staticClass: "navi-header font-weight-bold py-5" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "btn btn-label-primary btn-sm btn-bold btn-font-md"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("trans")("profile.show.currentPlan")
                            ) + ": "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "text-uppercase font-weight-bold",
                              class: _vm.subscriptionColorClass
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.trans(
                                    "accounting.plan." +
                                      _vm.currentSubscription.type +
                                      ".label",
                                    [],
                                    "AppAccounting"
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.currentSubscription.type === "free"
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-clean btn-sm btn-bold",
                              attrs: {
                                href: _vm.path(
                                  "app_account.accounting.subscription"
                                )
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("trans")("profile.show.upgradePlan")
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "separator separator-solid mb-4" }),
                  _vm._v(" "),
                  _c("ul", {
                    staticClass: "navi",
                    domProps: { innerHTML: _vm._s(_vm.menu) }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "separator separator-solid mt-4" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "navi-footer py-5 d-flex justify-content-center"
                    },
                    [
                      !_vm.user.impersonated
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-outline-primary btn-pill",
                              attrs: {
                                href: _vm.path("fos_user_security_logout")
                              }
                            },
                            [_vm._v(_vm._s(_vm._f("trans")("dict.logout")))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user.impersonated
                        ? _c("div", [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-danger btn-pill",
                                attrs: {
                                  href: _vm.path("fos_user_account", {
                                    _switch_user: "_exit"
                                  })
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("trans")("layout.exit-impersonating")
                                  )
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              )
            ])
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.user
      ? _c("div", { staticClass: "d-none d-lg-block" }, [
          _c(
            "a",
            {
              staticClass: "header-login font-weight-medium",
              attrs: { href: _vm.path("fos_user_security_login") }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm._f("trans")(
                      "layout.link.my_account",
                      {},
                      "FOSUserBundle"
                    )
                  ) +
                  "\n    "
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }