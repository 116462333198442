var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-custom" }, [
    _c("div", { staticClass: "card-header align-items-center px-4 py-3" }, [
      _c("div", { staticClass: "text-left flex-grow-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "text-center flex-grow-1" }, [
        _c(
          "div",
          { staticClass: "text-dark-75 font-weight-bold font-size-h5" },
          [_vm._v("Rozmowa z " + _vm._s(_vm.recipient.full_name))]
        )
      ]),
      _vm._v(" "),
      _vm._m(0)
    ]),
    _vm._v(" "),
    _vm.messages.models.length > 0
      ? _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            {
              staticClass: "scroll scroll-pull",
              attrs: { "data-height": "375", "data-mobile-height": "300" }
            },
            [
              _c(
                "div",
                { staticClass: "messages" },
                _vm._l(_vm.messages.models, function(message) {
                  return _c("private-message-thread-entry", {
                    key: message.id,
                    attrs: {
                      dir: _vm.messageSenderIsMe(message.sender) ? "out" : "in",
                      message: message
                    }
                  })
                }),
                1
              )
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "card-footer align-items-center" }, [
      _c("p", { staticClass: "font-weight-bold text-primary mb-3" }, [
        _vm._v("Nowa wiadomość:")
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          ref: "submit-form",
          attrs: { action: _vm.formAction, method: "post" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.processForm.apply(null, arguments)
            }
          }
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: "_method", value: "POST" }
          }),
          _vm._v(" "),
          _vm._t("form"),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-end pt-3" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary btn-pill btn-sm py-2 px-6" },
              [
                _vm._v(
                  _vm._s(_vm._f("trans")("dict.send", {}, "FOSMessageBundle"))
                )
              ]
            )
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-right flex-grow-1" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-clean btn-sm btn-icon btn-icon-md",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_c("i", { staticClass: "ki ki-close icon-1x" })]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }