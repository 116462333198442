/**
 * Adds a blocking overlay
 * Taken from metronic admin theme
 * Uses jquery block UI plugin http://malsup.com/jquery/block/
 * @type {{data(): {}, computed: {}, created(): void, methods: {removeBlockingOverlay(*=): null, addBlockingOverlay(*=, *=): void}}}
 */
const FormBlockOverlayMixin = {
  data() {
    return {

    };
  },
  computed: {
  },
  created() {
    this.$overlayedElement = null;
  },
  methods: {
    /**
     *
     * @param $elementToOverlay jQuery wrapped element
     * @param clickHandler
     */
    addBlockingOverlay($elementToOverlay, clickHandler) {
      const options = {
        opacity: 0.5,
        overlayColor: '#fff',
        type: '',
        size: '',
        state: 'brand',
        centerX: true,
        centerY: true,
        message: '',
        shadow: true,
        width: 'auto',
      };
      const params = {
        message: '',
        centerY: options.centerY,
        centerX: options.centerX,
        css: {
          top: '30%',
          left: '50%',
          border: '0',
          padding: '0',
          backgroundColor: 'none',
          width: options.width,
        },
        overlayCSS: {
          backgroundColor: options.overlayColor,
          opacity: options.opacity,
          cursor: 'default',
          zIndex: '10',
        },
        onUnblock(el) {
          if (el && el[0]) {
            KTUtil.css(el[0], 'position', '');
            KTUtil.css(el[0], 'zoom', '');
          }
        },
        onClick: () => {},
      };
      let el = null;
      if (typeof $elementToOverlay === 'undefined') {
        el = $(this.$el);
      } else {
        el = $elementToOverlay;
      }
      if (typeof el.block === 'function') {
        el.block(params).click((event) => {
          if (typeof clickHandler === 'function') {
            clickHandler();
          }
          event.preventDefault();
          event.stopPropagation();
        });
      }
    },

    /**
     *
     * @param $elementToOverlay jQuery wrapped element
     */
    removeBlockingOverlay($elementToOverlay) {
      let el = null;
      if (typeof $elementToOverlay === 'undefined') {
        el = $(this.$el);
      } else {
        el = $elementToOverlay;
      }
      if (typeof el.unblock === 'function') {
        el.unblock().off('click');
      }
      return el;
    },
  },
};

export default FormBlockOverlayMixin;
