import {Model, Collection} from 'vue-mc';
import moment from 'moment';
import globalConfig from 'js/app/config/app';
import Translator from "bazinga-translator";

class Activity extends Model {

  constructor(data) {
    super(data);
    this.init();
  }

  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: '',
      url: '',
      data: {},
      title: '',
      timestamp: '',
      type: '',
      is_read: false
    };
  }

  // Attribute mutations.
  mutations() {
    return {
      id: String,
      title: String,
      url: String,
      type: String,
      timestamp: (val) => {
        if (val) {
          return val;

        }
      }
    };
  }

  // Attribute validation
  validation() {
    return {
      id: string.and(required),
      url: string.and(required),
      title: string.and(required),
      type: string.and(required),
    };
  }

  // // Route configuration
  // routes() {
  //   return {
  //     fetch: '/task/{id}',
  //     save:  '/task',
  //   }
  // }

  init() {
    this.on('change', function (options) {
      switch (options.attribute) {
        case 'code':
          //console.log(options.previous + ' => ' + options.value);
          break;
      }
    });
  }

}

export default Activity;
