var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "infinite-scroll-loading-indicator": 1, loading: _vm.isLoading }
    },
    [_vm._v(" ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }