<script>
import Parsley from 'parsleyjs';
import 'babel-polyfill'; // needed to support "async + await"
import HttpUtil from 'js/app/utils/http';
import Axios from 'axios';
import JsRouting from 'fos-jsrouting-bundle';
import Vue from 'vue';
import Recaptcha3FormField from 'js/app/components/recaptcha3-form-field.vue';
import ComplexifyMixin from 'js/app/mixins/complexify.mixin.js';
import _ from 'lodash';

require('jquery.complexify');

export default {
  name: 'FosRegister',
  directives: {},
  components: {
    Recaptcha3FormField,
  },
  mixins: [ComplexifyMixin],
  props: {
    prefix: {
      type: String,
      default: '',
    },
    forceEmailBased: {
      type: Boolean,
      default: false,
    },
    enableEmailAvailability: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      config: {},
      emailRegistration: this.forceEmailBased,
      email: '',
      emailAvailable: null,
      privacyAccepted: false,
      termsAccepted: false,
      isMounted: false,
    };
  },
  computed: {
  },
  watch: {
    email(newVal, oldVal) {
      // debounce this check
      if (this.enableEmailAvailability) {
        this.debouncedCheckEmailAvailability(newVal);
      }
    },
    emailRegistration(newVal) {
      if (newVal === true) {
        // init complexify and parsley but after one tick after we revealed the registration form !!!
        Vue.nextTick(() => {
          this.initComplexifyStuff();
        });
      }
    },
  },

  created() {
    if (this.enableEmailAvailability) {
      // _.debounce is a function provided by lodash to limit how
      // often a particularly expensive operation can be run.
      // In this case, we want to limit how often we access
      // yesno.wtf/api, waiting until the user has completely
      // finished typing before making the ajax request. To learn
      // more about the _.debounce function (and its cousin
      // _.throttle), visit: https://lodash.com/docs#debounce
      // debounce this check to be performed every 500 ms only
      this.debouncedCheckEmailAvailability = _.debounce(this.checkEmailAvailability, 500);
    }
  },

  mounted() {
    this.initPlugins();
    this.isMounted = true;
  },

  methods: {

    /**
     *
     */
    initPlugins() {
      const vm = this;

      if (!window.Parsley.hasValidator('emailAvailable')) {
        window.Parsley
          .addValidator('emailAvailable', {
            requirementType: 'string',
            validateString(value, requirement) {
              return vm.emailAvailable;
            },
            messages: {
              en: 'This email address is already in use',
              pl: 'Ten adres email jest już zajęty',
            },
          });
      }
    },

    /**
     * Checks if a given email is already stored within the user table
     */
    checkEmailAvailability(newVal) {
      const vm = this;

      if (!this.enableEmailAvailability) {
        vm.emailAvailable = true;
        return true;
      }
      const url = JsRouting.generate('api_app_users_check_email', {email: newVal}, true);

      Axios.get(url)
        .then((response) => {
          if (response.data.found === true) {
            vm.emailAvailable = false;
          } else {
            vm.emailAvailable = true;
          }
          vm.validator.validate();
        })
        .catch((error) => {
          HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
            vm.answer = `Error! Could not reach the API. ${errorString}`;
          });
        });
      return true;
    },

    toggleEmailRegistration() {
      this.emailRegistration = !this.emailRegistration;
    },
  },
};
</script>

<style scoped>

</style>
