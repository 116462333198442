<template>
  <div class="card card-custom shadow-none gutter-b">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column flex-grow-1">
          <a href="#" class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">{{ label }}</a>
          <span v-if="categoryTranslated" class="text-muted font-weight-bold">{{ categoryTranslated }}</span>
        </div>
      </div>
      <div class="pt-0">
        <p v-if="description && description !== '...'" class="text-dark-75 font-size-lg font-weight-normal mb-2">
          {{ description }}
          <span v-if="copyright && description && description !== '...'" class="text-muted d-block mt-2">&copy;&nbsp;{{ copyright }}</span>
        </p>
        <div class="d-flex align-items-end justify-content-end">
          <a @click="triggerEdit" class="btn btn-icon btn-xs btn-clean btn-hover-icon-primary">
            <i class="flaticon-edit icon-xs"></i>
          </a>
          <!--a @click="triggerDelete" class="btn btn-icon btn-xs btn-clean btn-hover-icon-primary">
            <i class="flaticon-delete icon-xs"></i>
          </a-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Translator from 'bazinga-translator';
import {EventBus} from 'js/app/utils/EventBus';

export default {
  name: 'LMarkerPoiPopup',
  props: {
    poiItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      label: '',
      description: this.poiItem.description,
      copyright: this.poiItem.copyright,
      category: this.poiItem.category,
    };
  },
  computed: {
    categoryTranslated() {
      return this.category ? this.trans(`category.${this.category}`) : '';
    },
  },
  created() {
    if (this.poiItem.review_status === 'review') {
      this.label = `${this.poiItem.name} (review)`;
    } else if (this.poiItem.category) {
      this.label = this.poiItem.name;
    } else {
      this.label = this.poiItem.name;
    }
  },
  mounted() {

  },
  methods: {
    trans(key, params, domain) {
      return Translator.trans(key, params || {}, domain || 'messages');
    },

    triggerEdit() {
      EventBus.$emit('trigger-poi-edit', this.poiItem);
    },
    triggerDelete() {
      EventBus.$emit('trigger-poi-delete', this.poiItem);
    },
  },
};
</script>

<style scoped>

</style>
