import {Model} from 'vue-mc';

class UserUserStats extends Model {

  constructor(data) {
    super(data);
    this.init(data);
  }

  // Default attributes that define the "empty" state.
  defaults() {
    return {
      isPopulated: false,
      threadId: null
    };
  }

  init(data) {
    if (data) {
      this.isPopulated = true;
    }
    ;
    this.on('change', function (options) {
      switch (options.attribute) {
        case 'code':
          //console.log(options.previous + ' => ' + options.value);
          break;
      }
    });
  }

  isPopulated() {
    return this.isPopulated;
  }
}

export default UserUserStats
