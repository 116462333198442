<template>
  <!--begin: User bar -->
  <div>
    <!-- the AUTHENTICATED case -->
    <div v-if="user" class="metronic-scope d-none d-lg-flex justify-content-end">
      <!-- begin: user label -->
      <div class="topbar-item" data-offset="10px,0px" role="button" :class="{'user-border-right': notificationsActive || messagingActive}">
        <a class="btn btn-icon btn-clean btn-lg px-2" :class="{
              'text-success': !user.impersonated,
              'text-danger': user.impersonated,
           }"
           href="javascript:;" @click.prevent="gotoUserDashboard()"
        >
          <div v-if="useAvatars && avatar">
            <img v-if="avatar" :src="avatar" id="img-avatar-top">
            <img v-if="!avatar && (user.salutation === 'mr')"
                 src="/assets/global/img/heads-male.png" id="img-avatar-top" alt="">
            <img v-if="!avatar && (user.salutation !== 'mr')"
                 src="/assets/global/img/heads-female.png" id="img-avatar-top" alt="">
          </div>

          <span class="svg-icon svg-icon-xs mr-2" :class="{'svg-icon-success': !user.impersonated, 'svg-icon-danger': user.impersonated}">
            <svg xmlns="http://www.w3.org/2000/svg" width="6.449" height="8.21" viewBox="0 0 6.449 8.21">
              <g id="user" transform="translate(-80.04 -32)">
                <path id="Path_12" data-name="Path 12" d="M86.489,227.873a4.4,4.4,0,0,0-.952-2.776,2.9,2.9,0,0,0-4.544,0,4.4,4.4,0,0,0-.952,2.772,7.857,7.857,0,0,0,3.225.822A7.175,7.175,0,0,0,86.489,227.873Z" transform="translate(0 -188.482)" fill="#ffc700" />
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.613" cy="1.613" r="1.613" transform="translate(81.652 32)" fill="#ffc700" />
              </g>
            </svg>
          </span>

          <span v-if="!user.impersonated" class="topbar-item--username">Witaj&nbsp;{{ (user.firstName ? '&nbsp;' + user.firstName : '') }}</span>
          <span v-if="user.impersonated" class="topbar-item--username">Witaj&nbsp;{{ (user.firstName ? '&nbsp;[' + user.firstName + ']' : '') }}</span>
        </a>
      </div>
      <!-- end: user label -->

      <topbar-user-notifications v-if="notificationsActive"></topbar-user-notifications>
      <topbar-unread-messages v-if="messagingActive"></topbar-unread-messages>

      <!-- begin: account menu -->
      <div class="dropdown">
        <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" role="button">
          <div class="btn btn-icon btn-clean btn-dropdown btn-lg">
                                  <span class="svg-icon svg-icon-success svg-icon-xs">
                                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" style="transform: rotate(180deg);" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24"/>
                                            <path d="M3.95428417,19 L20.0457158,19 C20.3218582,19 20.5457158,18.7761424 20.5457158,18.5 C20.5457158,18.3982978 20.5147019,18.2990138 20.4568119,18.215395 L12.4110961,6.59380547 C12.2539131,6.36676337 11.9424371,6.31013137 11.715395,6.46731437 C11.6659703,6.50153145 11.623121,6.54438079 11.5889039,6.59380547 L3.54318807,18.215395 C3.38600507,18.4424371 3.44263707,18.7539131 3.66967918,18.9110961 C3.75329796,18.968986 3.85258194,19 3.95428417,19 Z" fill="#000000"/>
                                        </g>
                                      </svg>
                                  </span>
          </div>
        </div>
        <div class="dropdown-menu dropdown-menu-left dropdown-menu-md p-0">
          <div class="navi-header font-weight-bold py-5">
            <span class="btn btn-label-primary btn-sm btn-bold btn-font-md">{{'profile.show.currentPlan'|trans}}: <span :class="subscriptionColorClass" class="text-uppercase font-weight-bold">{{ trans('accounting.plan.' + currentSubscription.type + '.label', [], 'AppAccounting') }}</span></span>
            <a v-if="currentSubscription.type === 'free'" :href="path('app_account.accounting.subscription')"
               class="btn btn-clean btn-sm btn-bold">{{ 'profile.show.upgradePlan'|trans }}</a>
          </div>
          <div class="separator separator-solid mb-4"></div>
          <ul class="navi" v-html="menu"></ul>
          <div class="separator separator-solid mt-4"></div>
          <div class="navi-footer py-5 d-flex justify-content-center">
            <a v-if="!user.impersonated" :href="path('fos_user_security_logout')"
               class="btn btn-outline-primary btn-pill">{{ 'dict.logout'|trans }}</a>
            <div v-if="user.impersonated">
              <a :href="path('fos_user_account', {'_switch_user': '_exit'})"
                 class="btn btn-danger btn-pill">{{ 'layout.exit-impersonating'|trans }}</a>
            </div>
          </div>
        </div>
      </div>
      <!-- end: account menu -->
    </div>
    <!-- the ANONYMOUS case -->
    <div v-if="!user" class="d-none d-lg-block">
      <a :href="path('fos_user_security_login')" class="header-login font-weight-medium">
        {{ 'layout.link.my_account' | trans({},'FOSUserBundle') }}
      </a>
    </div>
  </div>
  <!--end: User bar -->
</template>

<script>
import JsRouting from 'fos-jsrouting-bundle';
import Axios from 'axios';
import securityUtil from 'js/app/utils/security';
import globalConfig from 'js/app/config/app';
import guiUtil from 'js/app/utils/gui';
import HttpUtil from 'js/app/utils/http';
import Subscription from 'js/accounting/models/Subscription';
import Translator from 'bazinga-translator';
import HSUnfold from 'theme/assets/vendor/hs-unfold/dist/hs-unfold';
import globalState from '../store/GlobalStore';
import TopbarUserNotifications from './topbar-user-notifications';
import TopbarUnreadMessages from './topbar-unread-messages';
import HSShowAnimation from 'theme/assets/vendor/hs-show-animation/dist/hs-show-animation.min';

export default {
  name: 'TopbarUserProfile',
  components: {
    TopbarUserNotifications,
    TopbarUnreadMessages,
  },
  props: {
    useAvatars: {
      type: Boolean,
      default: false,
    },
    marketplaceActive: {
      type: Boolean,
      default: false,
    },
    notificationsActive: {
      type: Boolean,
      default: false,
    },
    messagingActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: {
        impersonated: null,
        hashid: 0,
      },
      menu: null,
      avatar: null,
      currentSubscription: new Subscription(),
      isImpersonated: false,
      acl: null,
    };
  },
  computed: {
    unreadMessagesCount() {
      return 5;
    },
    messageBoxUrl() {
      return JsRouting.generate('app_my_messages', {}, true);
    },
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    },
    subscriptionColorClass() {
      let cssClass = '';
      switch (this.currentSubscription.type) {
        case 'free': cssClass = 'text-success'; break;
        case 'basic': cssClass = 'text-secondary'; break;
        case 'premium': cssClass = 'text-primary'; break;
        default: cssClass = 'text-success';
      }
      return cssClass;
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
    try {
      this.init();
    } catch (error) {
      throw new Error(error);
    }
  },
  methods: {
    init() {
      const self = this;
      if (!globalState.state.anonymousVisit && globalState.state.userData == null) {
        this.fetchContent().done((remoteData) => {
          if (remoteData) {
            this.acl = securityUtil.getAcl();
            this.user = remoteData.user || null;
            this.currentSubscription = remoteData.current_subscription ? new Subscription(remoteData.current_subscription) : null;
            this.menu = remoteData.menu || null;
            this.avatar = remoteData.avatar || null;
            // commit everything into global state
            globalState.commit('user', this.user);
            globalState.commit('currentSubscription', this.currentSubscription);
            globalState.commit('menu', this.menu);
            globalState.commit('avatar', this.avatar);
            globalState.commit('acl', this.acl);
          }
          self.$nextTick(() => {
            self.initPlugins();
          });
        });
      } else {
        // fetch all user data from global state
        this.user = globalState.state.userData;
        this.currentSubscription = globalState.state.currentSubscription;
        this.menu = globalState.state.userMenu;
        this.avatar = globalState.state.userAvatar;
        this.acl = globalState.state.acl;
        self.$nextTick(() => {
          self.initPlugins();
        });
      }
    },

    initPlugins() {

    },

    trans(key, params, domain, multipleCount) {
      if (multipleCount) {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'FOSUserBundle');
      } else {
        return Translator.trans(key, params || {}, domain || 'FOSUserBundle');
      }
    },

    path(path, params) {
      const _params = params || {};
      return JsRouting.generate(path, _params, true);
    },

    gotoUserDashboard() {
      const url = JsRouting.generate('fos_user_account', {}, true);
      document.location.href = url;
    },

    /**
     * Fetch use session data from remote
     */
    fetchContent() {
      const def = $.Deferred();
      const self = this;
      const url = JsRouting.generate('webapi_session_get_init_data', {}, true);
      Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
      Axios.defaults.withCredentials = true;
      Axios.defaults.credentials = 'same-origin';
      Axios.get(url).then((response) => {
        def.resolve(response.data);
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
        });
        // do nothing
        def.reject();
      });
      return def;
    },

  }
}
</script>

<style scoped>

</style>
