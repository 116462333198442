<template>
    <!-- Slick Carousel -->
    <div class="js-slick-carousel js-slick-gallery slick slick-equal-height slick-blurex slick-blurex-cloned-opacity slick-gutters-3"
         :data-hs-slick-carousel-options='hsSlickOptionsJson()'>
      <slot name="slide-items"></slot>
    </div>
    <!-- End Slick Carousel -->
</template>

<script>
export default {
  name: 'SlickCarousel',
  props: {
    slidesToShow: {
      type: Number,
      default: 3,
    },
    initOnTrigger: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    infinite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isInitialized: false,
      hsSlickOptions: {
        //rows: 0,
        slidesToShow: this.slidesToShow,
        slidesToScroll: 1,
        autoplay: this.autoplay,
        autoplaySpeed: 3000,
        speed: 500,
        infinite: this.infinite,
        prevArrow: null,
        nextArrow: null,
        responsive: [
          {
            breakpoint: 2000,
            settings: {
              slidesToShow: 3,
              centerPadding: '18.75%',
            },
          },
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
              centerMode: false,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              centerMode: false,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              centerMode: false,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.initOnTrigger) {
        this.initCarousel();
      }
    },
    initCarousel() {
      const self = this;
      const $slick = $(this.$el);

      $slick.slick(this.hsSlickOptions);

      $slick.on('afterChange', () => {
        self.updateSlideOpacity();
        //$slick.removeClass('slick-blurex-cloned-opacity');
      });

      $slick.on('click', '.slick-slide', function (e) {
        e.stopPropagation();
        self.goToTargetSlide($(this));
      });

      this.isInitialized = true;
    },

    goToTargetSlide(el) {
      const $slick = $(this.$el);
      const i = el.data('slick-index');
      const currentSlideIndex = $slick.slick('slickCurrentSlide');
      const slidesToShow = $slick.slick('slickGetOption', 'slidesToShow');

      if (i === (currentSlideIndex - 1)) {
        $slick.slick('slickPrev');
      }
      if (i === (currentSlideIndex + slidesToShow)) {
        $slick.slick('slickNext');
      }
    },

    updateSlideOpacity() {
      const $slick = $(this.$el);
      const currentSlideIndex = $slick.slick('slickCurrentSlide');
      const slidesToShow = $slick.slick('slickGetOption', 'slidesToShow');
      const prevThumbIndex = currentSlideIndex - 1;
      const nextThumbIndex = currentSlideIndex + slidesToShow;
      $(this.$el).find('.slick-slide').each((index, el) => {
        if ($(el).data('slick-index') === prevThumbIndex || $(el).data('slick-index') === prevThumbIndex - 1) {
          $(el).addClass('slick-semi-visible');
        }
        if ($(el).data('slick-index') === nextThumbIndex || $(el).data('slick-index') === nextThumbIndex + 1) {
          $(el).addClass('slick-semi-visible');
        }
        if (index !== prevThumbIndex && index !== nextThumbIndex) {
          $(el).removeClass('slick-semi-visible');
        }
        // if (index >= prevThumbIndex && index <= nextThumbIndex) {
        //   $(el).removeClass('slick-hidden');
        // }
        // if (index < prevThumbIndex || index > nextThumbIndex) {
        //   $(el).addClass('slick-hidden');
        // }
      });
    },

    hsSlickOptionsJson() {
      return JSON.stringify(this.hsSlickOptions);
    },
  },
};
</script>

<style scoped>

</style>
