import { Model, Collection } from 'vue-mc';
// Validation rules
import {
  boolean,
  equal,
  integer,
  min,
  required,
  string,
} from 'vue-mc-validation';

class SubjectStats extends Model {
  constructor(data) {
    super(data);
    this.init();
  }

  // Default attributes that define the "empty" state.
  defaults() {
    return {
      hashId: '',
      favorites: 0,
      wishlist: 0,
      reviews: 0,
      connections: 0,
      likes: 0,
      followers: 0,
      user: {
        favorite: false,
        wishlist: false,
        reviewed: false,
        connected: false,
        likes: false,
        follows: false,
      },
    };
  }

  // Attribute mutations.
  mutations() {
    return {
      // start: (start) => {
      //   if (start) {
      //     return moment(start).format(globalConfig.moment.dateFormat);
      //   }
      // }
    };
  }

  // Attribute validation
  validation() {
    return {
      hashId: string.and(required),
      favorites: integer.and(required),
      wishlist: integer.and(required),
      reviews: integer.and(required),
      connections: integer.and(required),
      likes: integer.and(required),
      followers: integer.and(required),
    };
  }

  // // Route configuration
  // routes() {
  //   return {
  //     fetch: '/task/{id}',
  //     save:  '/task',
  //   }
  // }

  init() {
    this.on('change', function (options) {
      switch (options.attribute) {
        case 'code':
          //console.log(options.previous + ' => ' + options.value);
          break;
      }
    });
  }

  setUserLikes() {
    this.likes += 1;
    this.user.likes = true;
  }

  unsetUserLikes() {
    if (this.likes > 0) {
      this.likes -= 1;
      this.user.likes = false;
    }
  }
}

export default SubjectStats;
