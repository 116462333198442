var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "results-and-sorting-wrap" }, [
        _vm.total > 0
          ? _c("h2", { staticClass: "h3 font-weight-bold mb-1 mb-lg-0" }, [
              _vm._v(_vm._s(_vm.total) + " pasujące wyniki")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.total === 0
          ? _c("h2", { staticClass: "h3 font-weight-bold mb-1 mb-lg-0" }, [
              _vm._v(" ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "form-group d-flex justify-content-lg-end align-items-center mb-0"
          },
          [
            _c(
              "label",
              { staticClass: "small font-weight-bold text-primary pt-2" },
              [_vm._v("Sortuj: ")]
            ),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "dropdown dropdown-primary" }, [
                _c(
                  "a",
                  {
                    staticClass: "text-primary dropdown-toggle",
                    attrs: {
                      type: "button",
                      id: "options-sorting",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false"
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.getSortingLabel(_vm.sorting)) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-menu dropdown-menu-right",
                    attrs: { "aria-labelledby": "options-sorting" }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#", "data-value": "default" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.sorting = "default"
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.getSortingLabel("default")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#", "data-value": "distance" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.sorting = "distance"
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.getSortingLabel("distance")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#", "data-value": "rating_intS" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.sorting = "rating_intS"
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.getSortingLabel("rating_intS")))]
                    )
                  ]
                )
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm.resultSetNonEmpty && _vm.maxPossiblePaginationCountExceedsLimit
        ? _c("div", { staticClass: "text-center mt-5 mb-6" }, [
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm._f("trans")(
                    "search.possible-total-exceeds-display-limit-info",
                    { maxCount: _vm.maxPossibleResultCountToShow },
                    "AppProduct"
                  )
                )
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            _vm._l([1, 2, 3, 4, 5], function(cnt, index) {
              return _c("search-results-placeholder", {
                key: index,
                attrs: { item: cnt }
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.resultSetNonEmpty
        ? _c(
            "div",
            [
              _vm._l(_vm.searchResults, function(resultItem, index) {
                return _c("search-results-item", {
                  key: index,
                  attrs: { item: resultItem }
                })
              }),
              _vm._v(" "),
              _vm.maxPaginationCountExceeded
                ? _c("div", { staticClass: "text-center" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm._f("trans")(
                            "search.total-exceeds-display-limit-info",
                            {},
                            "AppProduct"
                          )
                        )
                      )
                    ])
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("infinite-scroll-trigger", {
        attrs: {
          "is-active": _vm.infiniteScrollIsActive,
          "is-loading": _vm.isLoading
        },
        on: { "trigger-intersected": _vm.loadMoreResults }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }