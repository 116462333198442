import {Model, Collection} from 'vue-mc';
import moment from 'moment';
import globalConfig from 'js/app/config/app';
import Comment from './Comment';

class Thread extends Model {
  constructor(data) {
    super(data);
    this.init();
  }

  // Default attributes that define the "empty" state.
  defaults() {
    return {
      hashid: '',
      author: null,
      type: '',
      is_commentable: false,
      comments: [],
      num_comments: 0,
      created_at: '',
      last_comment_at: '',
    };
  }

  // Attribute mutations.
  mutations() {
    return {
      hashid: (hashid) => String(hashid) || null,
      createdAt: (dateTime) => {
        if (dateTime) {
          this.createdAtUnix = moment(dateTime).unix();
          return moment(dateTime).toISOString();
        }
        return null;
      },
      lastCommentAt: (dateTime) => {
        if (dateTime) {
          this.createdAtUnix = moment(dateTime).unix();
          return moment(dateTime).toISOString();
        }
        return null;
      },
      comments: (items) => {
        if (items.length > 0) {
          const comments = [];
          for (var itemIndex in items) {
            comments.push(new Comment(items[itemIndex]));
          }
          return comments;
        }
        return null;
      },
    };
  }

  // Attribute validation
  validation() {
    return {
      hashid: string.and(required),
    };
  }

  // // Route configuration
  // routes() {
  //   return {
  //     fetch: '/task/{id}',
  //     save:  '/task',
  //   }
  // }

  init() {
    this.on('change', (options) => {
      switch (options.attribute) {
        case 'id':
          //console.log(options.previous + ' => ' + options.value);
          break;
        default:
          break;
      }
    });
  }
}

export default Thread;
