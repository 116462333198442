var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row mapbox-geocoder mapbox-geocoder--search-map" },
    [
      _c("div", { staticClass: "col-sm-12 col-lg-12" }, [
        _c("div", {
          ref: "geocoder-map",
          staticClass: "geocoder--map",
          staticStyle: { width: "100%" }
        }),
        _vm._v(" "),
        _c("div", { ref: "geocoder", staticClass: "geocoder--input" })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }