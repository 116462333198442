var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "kt-notifications-panel", staticClass: "kt-notification" },
    [
      _vm.notifications.models.length == 0
        ? _c("span", { staticClass: "kt-notification__item" }, [
            _c("div", { staticClass: "kt-notification__item-details" }, [
              _vm._v(
                _vm._s(_vm._f("trans")("notification.no_new_notifications"))
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.notifications.models, function(notification) {
        return _vm.notifications.models.length > 0
          ? _c(
              "a",
              {
                class:
                  "kt-notification__item kt-notification__item--" +
                  (notification.is_read ? "read" : "unread"),
                attrs: { href: "#" },
                on: {
                  click: function(e) {
                    return _vm.itemClicked(notification, e)
                  }
                }
              },
              [
                _c("div", { staticClass: "kt-notification__item-icon" }, [
                  _c("i", {
                    class:
                      _vm.getNotificationIcon(notification) + " kt-font-success"
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "kt-notification__item-details" }, [
                  _c("div", { staticClass: "kt-notification__item-title" }, [
                    _c(
                      "span",
                      {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getNotificationTitle(notification)
                          )
                        }
                      },
                      [_vm._v("---")]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "kt-notification__item-time" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm._f("age")(notification.timestamp)) +
                          " \n        "
                      ),
                      _vm._l(_vm.getActions(notification), function(
                        action,
                        index
                      ) {
                        return _c(
                          "span",
                          {
                            class: "btn btn-label-" + action.css_class,
                            on: {
                              click: function($event) {
                                return _vm.actionClick(action, notification)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("trans")("notification.action." + index)
                              )
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ])
              ]
            )
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }