var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kt-portlet kt-portlet--mobile" }, [
    _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("trans")(
                  "product.search.filters.portlet_title",
                  {},
                  "AppProduct"
                )
              ) +
              "\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-portlet__head-tools" })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "kt-portlet__body" },
      [
        _vm._t("default", function() {
          return [
            _vm._v(
              "here comes the prerendered symfony form see\n      templates/product/section_accommodation/index.html.twig\n      render(controller('App\\\\Controller\\\\Product\\\\AccommodationController::searchFiltersWidget'))\n    "
            )
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }