var render, staticRenderFns
import script from "./fos-register.vue?vue&type=script&lang=js&"
export * from "./fos-register.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12b6d163",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12b6d163')) {
      api.createRecord('12b6d163', component.options)
    } else {
      api.reload('12b6d163', component.options)
    }
    
  }
}
component.options.__file = "assets/js/user/components/fos-register.vue"
export default component.exports