<script>
import Axios from "axios";
import guiUtil from "js/app/utils/gui";
import HttpUtil from 'js/app/utils/http';
import Translator from "bazinga-translator";
import JsRouting from 'fos-jsrouting-bundle';
import xsrfUtils from 'js/app/utils/xsrf-utils';
import Vue from 'vue';

export default {
  name: 'PoiItemEditForm',
  props: {
    coordinates: {
      type: String,
      default: '',
    },
    poiItem: {
      type: Object,
      default() {
        return {};
      },
    },
    successCallback: {
      type: Function,
    },
    errorCallback: {
      type: Function,
    },
  },
  data() {
    return {
      hashid: this.poiItem.hashid,
      category: null,
      title: this.poiItem.name,
      description: this.poiItem.description,
    };
  },
  computed: {
    formMethod() {
      return this.$form[0].method.toLowerCase();
    },
    formActionUrl() {
      return this.$form[0].action;
    },
    formName() {
      return this.$form[0].name;
    },
  },
  watch: {
  },
  created() {
    const self = this;
    this.$on('submit-form', (options) => {
      self.handleSubmit();
    });
    // a hack, this are values derived from the database
    // without better solution now
    // each time you add a POI category this here must be matched
    this.categoryIdCodeMapping = {
        culture: 18,
        landmarked_building: 19,
        public_utility_object: 20,
        landmark: 21,
        other: 22,
    };
  },
  beforeDestroy() {
    // remove listener to avoid multiple registrations
    this.$off('submit-form');
  },
  beforeCreate() {
  },
  mounted() {
    this.category = this.categoryIdCodeMapping[this.poiItem.category];
    if ($(this.$el)[0].nodeName === 'FORM') {
      this.$form = $(this.$el);
    } else {
      this.$form = $(this.$el).find('form');
    }
    this.init();
  },
  methods: {
    init() {
      let self = this;
    },
    handleSubmit() {
      const self = this;
      const formActionUrl = JsRouting.generate('api_poi_entry_patch', {hashid: this.hashid});

      let formData = new FormData(this.$form[0]);

      xsrfUtils.fetchCsrfToken('poi').done((token) => {
        formData.append('poi_entry_minimal_type[_token]', token);
        // !!! remove address location here since it is empty anyway und we do not need it
        // otherwise on the other side validation does not work for that property and we and up
        // with a point in nirvana !!!
        formData.delete('poi_entry_minimal_type[addressLocation]');
        // always use post - in symfony method spoofing is used, PUT is sent as POST with additional parameter _method="put"
        Axios.post(formActionUrl, formData).then((response) => {
          // do not use self.$emit here in axios callbacks !!!
          // for some reason they do not fire and do not reach the parent although they would work
          // just before axios call !!!
          // @see https://forum.quasar-framework.org/topic/4099/solved-how-to-use-emit-inside-axios-response-then/6
          // So I use intermediate reactive variable and watch as a workaround here
          // !!! but on the other side it works in other components !!! what the fuck?!
          // May be some side effect of b-modal component? noc fucking clue - check if tme allows
          // Indeed, I checked this in other child component (product-availability-calendar) of a b-modal. It does not work ther either.
          //self.$emit('success', responseData);
          self.successCallback(response.data);
        }).catch((error) => {
          HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
          });
          // do not use self.$emit here in axios callbacks !!!
          // for some reason they do not fire and do not reach the parent although they would work
          // just before axios call !!!
          // @see https://forum.quasar-framework.org/topic/4099/solved-how-to-use-emit-inside-axios-response-then/6
          // So I use intermediate reactive variable and watch as a workaround here
          // !!! but on the other side it works in other components !!! what the fuck?!
          // May be some side effect of b-modal component? noc fucking clue - check if tme allows
          // Indeed, I checked this in other child component (product-availability-calendar) of a b-modal. It does not work ther either.
          //self.$emit('error', error);
          self.errorCallback(error);
        }).then(() => {
          // 2nd then after catch is always executed - catch MUST be present for this to work
        });
      }).fail(() => {
        console.error('token fetch failed');
      });
    },
  },
  template: '#vuePoiEditFormModalTemplate',
};
</script>

<style scoped>

</style>
