import 'css/user.scss';
import ChangePassword from './components/change-password.vue';
import FosRegister from './components/fos-register.vue';
import FosResetPassword from './components/fos-reset-password.vue';
import UserThread from './components/user-thread.vue';
import securityUtil from 'js/app/utils/security';
import HttpUtil from 'js/app/utils/http';
import 'babel-polyfill'; // needed to support "async + await"
import UserUserStats from './models/UserUserStats';
import Vue from 'vue';
import Axios from 'axios';
import Parsley from 'parsleyjs';
import JsRouting from 'fos-jsrouting-bundle';
import PoiLocationManage from "../poi/components/poi-location-manage";

require('jquery.complexify');
require('lodash');

export default function (anchorIds) {
  anchorIds = anchorIds || [];
  /**
   * #view-public-user-registration
   */
  ((id) => {
    if (!anchorIds.includes(id)) return false;
    const appContainer = document.getElementById(id);
    if (appContainer) {
      const app3 = new Vue({
        el: appContainer,
        components: {
          FosRegister,
        },
      });
    }
  })('view-public-user-registration');

  /**
   * #view-public-resetting_password
   */
  ((id) => {
    if (!anchorIds.includes(id)) return false;
    const appContainer = document.getElementById(id);
    if (appContainer) {
      const app5 = new Vue({
        el: appContainer,
        components: {
          FosResetPassword,
        },
      });
    }
  })('view-public-resetting_password');

  /**
   * #view-user-change_password
   */
  ((id) => {
    if (!anchorIds.includes(id)) return false;
    const appContainer = document.getElementById(id);
    if (appContainer) {
      const app1 = new Vue({
        el: appContainer,
        components: {
          ChangePassword,
        },
        computed: {
          isAuthenticated() {
            return securityUtil.isAuthenticatedSession();
          },
        },
      });
    }
  })('view-user-change_password');

  /**
   * #view-public-user-profile
   */
  ((id) => {
    if (!anchorIds.includes(id)) return false;
    const appContainer = document.getElementById(id);
    if (appContainer) {
      const app2 = new Vue({
        el: appContainer,
        components: {
          UserThread,
        },
        data: {
          userUserStats: new UserUserStats(),
          recipientJson: null,
        },
        computed: {
          isAuthenticated() {
            return securityUtil.isAuthenticatedSession();
          },
        },
        mounted() {
          if (viewConfig && viewConfig.recipientJson) {
            this.recipientJson = JSON.parse(viewConfig.recipientJson);
          }
          this.init();
        },
        methods: {
          init() {
            this.fetchUserUserStatus();
          },

          async fetchUserUserStatus() {
            let self = this;
            if (!this.isAuthenticated) {
              this.userUserStats = new UserUserStats({
                isFavorite: 0,
                isFeatureTarget: 0,
                isRated: 0,
              });
              return;
            }

            // fetch current marker status
            let url = JsRouting.generate('api_user_visitor_stats', {
              // TODO: get the visited user hash here
              userId: self.recipientJson.hashid
            }, true);
            await Axios.get(url).then((response) => {
              self.userUserStatusChanged(response.data);
            }).catch((error) => {
              HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
              });
              // do nothing
            });
          },

          userUserStatusChanged(data) {
            this.userUserStats = new UserUserStats({
              threadId: data.thread_id,
            });
          },

          onThreadIdUpdate(newValue) {

          },
        },
      });
    }
  })('view-public-user-profile');

  /**
   * #view-public-user-register_oauth
   */
  ((id) => {
    if (!anchorIds.includes(id)) return false;
    const appContainer = document.getElementById(id);
    if (appContainer) {
      const app4 = new Vue({
        el: appContainer,
        data: {
          email: '',
          emailAvailable: null,
          passwordFirst: '',
          passwordFirstOk: false,
          passwordSecond: '',
          passwordSecondOk: false,
          passwordSecondEnabled: false,
          privacyAccepted: false,
          termsAccepted: false
        },
        directives: {},
        computed: {},
        watch: {
          passwordSecond: function (newVal, oldVal) {
            // Validate the second password field
            // Make sure its value equals the first's
            if (newVal === this.passwordFirst) {
              this.passwordSecondOk = true;
            } else {
              this.passwordSecondOk = false;
            }
          },
          email: function (newVal, oldVal) {
            // debounce this check
            this.debouncedCheckEmailAvailability(newVal);
          }
        },
        created: function () {
          // _.debounce is a function provided by lodash to limit how
          // often a particularly expensive operation can be run.
          // In this case, we want to limit how often we access
          // yesno.wtf/api, waiting until the user has completely
          // finished typing before making the ajax request. To learn
          // more about the _.debounce function (and its cousin
          // _.throttle), visit: https://lodash.com/docs#debounce
          // debounce this check to be performed every 500 ms only
          this.debouncedCheckEmailAvailability = _.debounce(this.checkEmailAvailability, 500)
        },

        /* BEGIN: METHODS*/
        methods: {
          /**
           *
           * @param config
           * @returns {*}
           */
          init: function (config) {
            var self = this;
            var d1 = $.Deferred();

            this.config = config;
            if (typeof this.config == 'undefined') {
              this.config = {};
            }

            this.initPlugins();

            d1.resolve();
            return d1;

          },

          /**
           *
           */
          initPlugins: function () {
            const vm = this;

            // init complexify
            this.initComplexify($('#UserOauthPostregisterStepTwo_plainPassword_first'));

            // init parsley
            this.validator = new Parsley.Factory($('#UserOauthPostregisterStepTwo')[0], window.ParsleyConfig);

            window.Parsley
              .addValidator('emailAvailable', {
                requirementType: 'string',
                validateString: function (value, requirement) {
                  return vm.emailAvailable;
                },
                messages: {
                  en: 'This email address is already in use',
                  pl: 'Ten adres email jest już zajęty'
                }
              });

            window.Parsley
              .addValidator('complexEnough', {
                requirementType: 'string',
                validateString: function (value, requirement) {
                  return vm[requirement + 'Ok'];
                },
                messages: {
                  en: 'Not complex enough!',
                  pl: 'Not complex enough!'
                }
              });

            window.Parsley
              .addValidator('equalTo', {
                requirementType: 'string',
                validateString: function (value, requirement) {
                  return value === vm[requirement];
                },
                messages: {
                  en: 'Passwords are not equal!',
                  pl: 'Passwords are not equal!'
                }
              });

          },

          /**
           *
           * @param $jqPasswordField
           */
          initComplexify($jqPasswordField) {

            var self = this;

            var $barjQueryElement = $('.pass-check-bar');

            // Use the complexify plugin on the first password field
            var complex = $jqPasswordField.complexify(
              {minimumChars: 6, strengthScaleFactor: 0.7},
              function (valid, complexity) {
                var barWidth = (370 / 100) * complexity;
                $barjQueryElement.width(barWidth);

                if (valid) {
                  self.passwordSecondEnabled = true;
                  self.passwordFirstOk = true;
                } else {
                  self.passwordSecondEnabled = false;
                  self.passwordFirstOk = false;
                }

              }
            );

          },

          /**
           * Checks if a given email is already stored within the user table
           */
          checkEmailAvailability: function (newVal) {
            var vm = this;
            var url = JsRouting.generate('api_app_users_check_email', {email: newVal}, true);

            Axios.get(url)
              .then((response) => {
                if (response.data.found == true) {
                  vm.emailAvailable = false;
                } else {
                  vm.emailAvailable = true;
                }
                vm.validator.validate()
              })
              .catch((error) => {
                HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
                  vm.answer = 'Error! Could not reach the API. ' + errorString;
                });
              });

          }
        }
      });
      app4.init({});
    }
  })('view-public-user-register_oauth');
}
