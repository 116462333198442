// always include js/app/Main as first
import mainApp from './app/Main';
import publicSearch from './search/public.search';
import publicUser from './user/public.user';
import publicPress from './press/public.press';
import publicSectionIndex from './app/public.section-index';

mainApp.init().done(() => {
  publicSearch([
    'view-public-search-common',
    'view-public-search-accommodation',
    'view-public-search-food',
    'view-public-search-handicraftandservices',
  ]);
  publicUser([
    'view-public-user-registration',
    'view-public-resetting_password',
    'view-user-change_password',
    'view-public-user-profile',
    'view-public-user-register_oauth',
  ]);
  publicPress([
    'view-public-press-article-single',
    'view-public-press-article-list',
  ]);
  publicSectionIndex([
    'view-public-section-index',
  ]);
  return true;
}).fail(() => {
  console.error('Main app initialization failed!');
});
