var render, staticRenderFns
import script from "./home-tag-based-entry.vue?vue&type=script&lang=js&"
export * from "./home-tag-based-entry.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a6184a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24a6184a')) {
      api.createRecord('24a6184a', component.options)
    } else {
      api.reload('24a6184a', component.options)
    }
    
  }
}
component.options.__file = "assets/js/app/components/home-tag-based-entry.vue"
export default component.exports