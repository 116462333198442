<template>
<div v-if="isValid" class="z-widget-comments metronic-scope">
  <div v-if="isInitialized && comments.length === 0">
    <h3>{{ trans('comment.no-comments-yet') }}</h3>
    <p>{{ trans('comment.be-first-to-comment') }}</p>
  </div>
  <div v-if="comments.length > 0">
    <h3>{{ stats.total }} {{ trans('comment.comments-counter', null, null, stats.total) }}</h3>
    <comment-section-entry
      v-for="comment in comments"
      :comment="comment"
      :thread-id="thread.hashid"
      :key="comment.hashid"
      @comment-deleted="onCommentDeleted"
    ></comment-section-entry>
    <!--begin::Separator-->
    <div class="separator separator-solid mt-2 mb-2"></div>
    <!--end::Separator-->
  </div>

  <infinite-scroll-trigger
    :is-active="scrollIsActive"
    :is-loading="scrollIsLoading"
    @trigger-intersected="loadMoreData"
  ></infinite-scroll-trigger>

  <!--begin::Editor-->
  <form ref="form" class="position-relative" @submit.prevent="postComment">
    <textarea ref="message-body" v-model="body" name="fos_comment_comment[body]" class="form-control resize-none mb-2" rows="1" placeholder="Dodaj twój komentarz ...">
    </textarea>
    <input type="submit" class="btn btn-primary w-100" value="Wyślij komentarz" />
  </form>
  <!--edit::Editor-->

</div>
</template>

<script>
import 'css/comment.scss';
import CommentSectionEntry from './comment-section--entry.vue';
import Thread from '../models/Thread';
import Comment from '../models/Comment';
import JsRouting from 'fos-jsrouting-bundle';
import securityUtil from 'js/app/utils/security';
import globalState from 'js/app/store/GlobalStore';
import Axios from 'axios';
import guiUtil from 'js/app/utils/gui';
import HttpUtil from 'js/app/utils/http';
import Translator from 'bazinga-translator';
import xsrfUtils from 'js/app/utils/xsrf-utils';
import autosize from 'autosize';
import InfiniteScrollTrigger from 'js/app/components/infinite-scroll-trigger';
import InfiniteScrollMixin from 'js/app/mixins/infinite-scroll-mixin';
import Post from "../../promotion/models/Post";
import FormBlockOverlayMixin from 'js/app/mixins/form-block-overlay.mixin';

export default {
  name: 'CommentSection',
  components: {
    CommentSectionEntry,
    InfiniteScrollTrigger,
  },
  mixins: [InfiniteScrollMixin, FormBlockOverlayMixin],
  props: {
    threadJson: {
      type: String,
      default: '[]',
    },
  },
  data() {
    return {
      thread: new Thread(JSON.parse(this.threadJson)),
      isInitialized: false,
      comments: [],
      stats: {
        total: 0,
      },
      token: null,
      body: null,
      scrollLimit: 10, // override limit set in mixin
    };
  },
  computed: {
    isValid() {
      return this.thread != null && this.thread.hashid > '';
    },
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    },
  },
  watch: {
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const self = this;

      autosize(self.$refs['message-body']);

      if (!this.isAuthenticated) {
        // disable all simple form fields in the form to be double sure
        $(this.$refs['form']).find(':input').prop('disabled', true);
        // generate a blocking overlay using our mixin
        this.addBlockingOverlay($(this.$refs['form']), () => {
          guiUtil.showLoginExclusiveFeatureInfo();
        });
      }
    },

    loadMoreData() {
      const self = this;
      const url = JsRouting.generate('fos_comment_get_thread_comments', {
        id: this.thread.hashid,
        limit: this.scrollLimit,
        offset: this.scrollOffset,
        sort: 'asc',
      }, true);

      // call more data method from scroll mixin
      this.scrollNextPage(url).done((dataRows, stats) => {
        if (typeof stats !== 'undefined') {
          self.stats = stats;
        }
        // process returned data locally
        if (dataRows && dataRows.length > 0) {
          let newComment = null;
          $.each(dataRows, (index, value) => {
            newComment = new Comment(value);
            self.comments.push(newComment);
          });
        }
        self.isInitialized = true;
      });
    },

    postComment() {
      if (!this.thread.hashid) {
        return false;
      }

      const self = this;
      const url = JsRouting.generate('fos_comment_post_thread_comment', {id: this.thread.hashid}, true);
      const formData = new FormData(self.$refs['form']);

      xsrfUtils.fetchCsrfToken('comment.add').done((token) => {
        // append the retrieved xsrf token to form data for the actual query
        formData.append('fos_comment_comment[_token]', token);
        guiUtil.blockUI($(this.$el));
        Axios.post(url, formData).then((response) => {
          if (response.data && response.data.body) {
            self.comments.push(new Comment(response.data));
            self.stats.total += 1;
            self.body = '';
          }
        }).catch((error) => {
          HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
          });
        }).then(() => {
          // 2nd then after catch is always executed - catch MUST be present for this to work
          guiUtil.unblockUI($(self.$el));
        });
      }).fail(() => {
        console.error('token fetch failed');
      });
    },

    trans(key, params, domain, multipleCount) {
      if (multipleCount) {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'messages');
      } else {
        return Translator.trans(key, params || {}, domain || 'messages');
      }
    },

    onCommentDeleted(deletedComment) {
      // remove the deleted comment from current list
      const newComments = [];
      for (var index in this.comments) {
        if (this.comments[index].hashid !== deletedComment.hashid) {
          newComments.push(this.comments[index]);
        }
      }
      if (this.stats.total > 0) {
        this.stats.total -= 1;
      }
      this.comments = newComments;
    },
  },
};
</script>

<style scoped>

</style>
