var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center mt-n14 mt-md-0" }, [
    _vm.priceMatrix.default
      ? _c("div", [
          _c("h4", { staticClass: "font-weight-bold text-secondary mb-0" }, [
            _vm._v(
              "\n      Od " +
                _vm._s(_vm.formatPrice(_vm.priceMatrix.default.value)) +
                " / " +
                _vm._s(
                  _vm._f("trans")(
                    "channel-pricing.unit-display." +
                      _vm.priceMatrix.default.unit,
                    {},
                    "AppProduct"
                  )
                ) +
                "\n    "
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }