import 'slick-carousel/slick/slick';
import 'jquery-validation/dist/jquery.validate';
import Vue from 'vue';

// watch the order of the imports below: import mainApp from './Main' always comes first!
import SlickCarousel from './components/slick-carousel.vue';

import 'theme/assets/js/hs.slick-carousel';
import 'theme/assets/js/hs.validation';
import ZeroSectionGeocoderStandalone from './components/zero-section-geocoder-standalone.vue';
import HomeTagBasedEntry from './components/home-tag-based-entry.vue';
import persistentStore from './store/PersistentStore';

export default function (anchorIds) {
  anchorIds = anchorIds || [];
  ((id) => {
    if (!anchorIds.includes(id)) return false;
    const appContainer = document.getElementById(id);
    if (appContainer) {
      const app = new Vue({
        el: appContainer,
        components: {
          SlickCarousel,
          ZeroSectionGeocoderStandalone,
          HomeTagBasedEntry,
        },
        created() {
          const self = this;
        },
        mounted() {
          const self = this;
          self.init();
        },
        methods: {
          init() {
            if ($(window).width() >= 992) {
              // adjust the heights only on large and above
              // on smaller screens, when the elements are one after another it is not necessary
              this.equal2neighborsImg();
            }
          },
          equal2neighborsImg() {
            if ($('.js-equal2neighbors-img')) {
              const neighborImgHeight = $('.js-neighbors-img').height();
              $('.js-equal2neighbors-img').css('height', neighborImgHeight + 2);
            }
          },
          handleGeocode(lat, lng, place) {
            persistentStore.commit('searchParams', {
              place: place,
              spatial_pt: `${lat},${lng}`,
            });
          },
        },
      });
    }
  })('view-public-section-index');
}
