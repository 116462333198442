// import html stream theme first

//import 'css/_integration/metronic-7/style.scss'; // is integrated in zamiastowi theme!
// our custom styling on top
import 'css/app.scss';
import 'css/calendar/fullcalendar.scss';

import jquery from 'jquery';
// import htmlsteram theme global libraries
//import 'jquery-migrate';
import 'bootstrap';

// backport some metronic 7 stuff
import 'js/_integration/metronic-7/integration';


import '@fancyapps/fancybox/dist/jquery.fancybox';
import HSHeader from 'theme/assets/vendor/hs-header/dist/hs-header.min';
import HSUnfold from 'theme/assets/vendor/hs-unfold/dist/hs-unfold.min';
import 'theme/assets/js/hs.core';
import 'theme/assets/js/hs.fancybox';

import './extends/vue-config';
import './extends/vue-filters';
import './extends/vue-directives';
import XsrfUtils from './utils/xsrf-utils';
import guiUtil from './utils/gui';
import utils from './utils/general';
import HttpUtil from './utils/http';
import securityUtil from './utils/security';
import globalConfig from './config/app';
import Vue from 'vue';
import TopbarUserProfile from './components/topbar-user-profile.vue';
import AsidePanel from './components/aside-panel.vue';
import CookieConsent from './components/cookie-consent.vue';
import userSessionPolling from './utils/user-session-polling';
import FooterNewsletterSubscription
  from 'js/promotion/components/footer-newsletter-subscription.vue';
import globalState from './store/GlobalStore';

import Translator from 'bazinga-translator';

import JsRouting from 'fos-jsrouting-bundle';
import jsRoutes from 'js/_res/fos_js_routes.json';
// parsley import and init
import 'parsleyjs';
import 'parsleyjs/dist/i18n/pl';
// import htmlsteram theme global libraries
import HSShowAnimation from 'theme/assets/vendor/hs-show-animation/dist/hs-show-animation.min';
// global axios configuration
import Axios from 'axios';
import Subscription from '../accounting/models/Subscription';

window.jQuery = jquery;
window.$ = jquery;
window.Translator = Translator;

const axiosDefaults = require('axios/lib/defaults');
// necessary to be able to reach the API (/webapi/...) from webpack dev server (cross domain request)
// and pass the session cookie along
axiosDefaults.withCredentials = true;

class Main {
  /**
   * init the main app
   */
  init() {
    const self = this;
    const $masterDef = $.Deferred();
    const $userDef = $.Deferred();

    $(document).ready(() => {

      self._initJsRouter();

      globalState.commit('features', GLOBAL.features);

      self._initTranslations();

      // make request to set the api client key in browser
      // we do not do it in the main symfony rendering process because
      // setting cookies from PHP would prevent http caching by nginx
      self._initClientApiKey().done((result) => {
        // initialize frontend session polling
        const sessionPolling = new Vue(userSessionPolling);
        sessionPolling.pollData(true).always(() => {
          self._initUserData().always(() => {
            $userDef.resolve();
            // initialize the header part which contains
            self._initHeader();
            //self._initAside();
          });
          self._initCookieConsent();
          self._initMainCta();
          self._initFooter();
        });
      }).fail((error) => {
        // without this cookie we are unable to reach the API
        // what to do next ???
        $userDef.reject();
      });

      self._initPlugins();

      XsrfUtils.initXsrfCookies();
      self._showFlashbagNotifications();
      self._initCORS();

      // for local development only
      //let $def1 = this._initDevOauthToken();

      $.when($userDef).then(( data, textStatus, jqXHR ) => {
        $masterDef.resolve();
      });
    });

    return $masterDef;
  }

  _initUserData() {
    const def = $.Deferred();
    const url = JsRouting.generate('webapi_session_get_init_data', {}, true);

    if (globalState.state.anonymousVisit === true) {
      // no need to fetch user data since we already know we have a anonymous session
      def.resolve();
      return def;
    }

    Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    Axios.defaults.withCredentials = true;
    Axios.defaults.credentials = 'same-origin';
    Axios.get(url).then((response) => {
      if (response.data && response.data.user) {
        globalState.commit('user', response.data.user);
      }
      if (response.data && response.data.current_subscription) {
        globalState.commit('currentSubscription', new Subscription(response.data.current_subscription));
      }
      if (response.data && response.data.waiting_subscription) {
        globalState.commit('waitingSubscription', new Subscription(response.data.waiting_subscription));
      }
      if (response.data && response.data.menu) {
        globalState.commit('menu', response.data.menu);
      }
      if (response.data && response.data.avatar) {
        globalState.commit('avatar', response.data.avatar);
      }
      globalState.commit('acl', securityUtil.getAcl());
      def.resolve();
    }).catch((error) => {
      HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
      });
      // do nothing
      def.reject();
    });

    return def;
  }

  /**
   *
   * @private
   */
  _initCORS() {
    // set global default for $.ajax calls
    $.ajaxSetup({
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      xhrFields: {
        withCredentials: true,
      },
      crossDomain: false,
    });
    Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    Axios.defaults.withCredentials = true;
    Axios.defaults.credentials = 'same-origin';
  }

  /**
   * make request to set the api client key in browser
   * we do not do it in the main symfony rendering process because it
   * would prevent http caching by nginx
   *
   * @private
   */
  _initClientApiKey() {
    const $def = $.Deferred();
    const url = JsRouting.generate('webapi_session_init_frontend_session', null, true);
    Axios.get(url).then((result) => {
      $def.resolve(result);
    }).catch((error) => {
      HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {});
      $def.reject(error);
    }).then(() => {
    });
    return $def;
  }

  _initJsRouter() {
    // inject current locale into defaults for all routes which need it
    // because its value is not set correctly/at all by the JsRoutingbundle
    for (var routeName in jsRoutes.routes) {
      if (jsRoutes.routes.hasOwnProperty(routeName)) {
        if (jsRoutes.routes[routeName].hasOwnProperty('defaults')) {
          if (jsRoutes.routes[routeName].defaults.hasOwnProperty('_locale')) {
            jsRoutes.routes[routeName].defaults._locale = document.documentElement.lang;
          }
        }
      }
    }

    // initialize FosJsRouter
    JsRouting.setRoutingData(jsRoutes);

    // BEGIN: IMPORTANT custom Routing.generate implementation to inject _local parameter transparently
    // change name of initial method
    JsRouting.generateImpl = JsRouting.generate;
    // override generate function by adding a default _locale from html lang attribute
    JsRouting.generate = function (route, params, absolute) {
      let paramsExt = {};
      if (params) {
        paramsExt = params;
      }
      // if (!paramsExt._locale){
      //   paramsExt._locale = document.documentElement.lang;
      // }
      let url = null;
      try {
        url = JsRouting.generateImpl(route, paramsExt, absolute);
      } catch (err) {
        // try localized route
        delete paramsExt._locale;
        url = JsRouting.generateImpl(route + '.' + document.documentElement.lang, paramsExt, absolute);
      }
      return url;
    };
    // END: IMPORTANT custom Routing.generate implementation to inject _local parameter transparently
  }

  /**
   * Load bazingas js translation bundle translations
   * https://github.com/willdurand/BazingaJsTranslationBundle/blob/master/Resources/doc/index.md#loading-via-json
   * @param files
   * @private
   */
  static _loadTranslations(files) {
    for (const filePath of files) {
      fetch(filePath)
        .then((response) => response.json())
        .then((data) => Translator.fromJSON(data));
    }
  }

  _initTranslations() {
    Main._loadTranslations([
      '/js/translations/config.json',
    ]);
    for (const langKey of ['en', 'pl']) {
      for (const bundleKey of [
        'messages', 'FOSUserBundle', 'FOSMessageBundle',
        'SonataMediaBundle', 'AppAccounting', 'AppCalendar', 'AppProduct'
      ]) {
        Main._loadTranslations([
          `/js/translations/${bundleKey}/${langKey}.json`,
        ]);
      }
    }
  }

  /**
   * init various JS plugins
   */
  _initPlugins() {
    const self = this;
    // init parsley
    window.Parsley.setLocale(document.documentElement.lang);
    window.ParsleyConfig = utils.deepClone(globalConfig.parsley.defaultConfig);

    // Initialize KTApp class on document ready
    window.KTApp.init(window.KTAppOptions);

    function initFancybox() {
      // initialization of fancybox
      $('#content').find('.js-fancybox').each((key, el) => {
        $.HSCore.components.HSFancyBox.init($(el));
      });

      $('[data-fancybox]').fancybox({
        toolbar: false,
        smallBtn: true,
        buttons: [
          'fullScreen',
          'close',
        ],
        iframe: {
          preload: true,
        },
      });
    }

    function initTabindex() {
      // init a field with tabindex=1 if found
      $('[tabindex=1]').focus();
    }

    function initHsUnfold() {
      // initialization of unfold
      let unfold = null;
      $('.js-hs-unfold-invoker').each((key, el) => {
        unfold = new HSUnfold($(el)).init();
      });
    }

    initFancybox();
    initTabindex();
    initHsUnfold();
  }

  _showFlashbagNotifications() {
    if (window.GLOBAL.notifications.length > 0) {
      // process current symfony flash notifications - may be refactored to AJAX request in future
      $.each(window.GLOBAL.notifications, function (key, notification) {
        if (notification.type > '') {
          notification.type = (notification.type === 'notice' ? 'info' : notification.type);
          guiUtil.showNotification(notification.type,
            notification.message,
            null,
            {
              timeOut: '8000',
              onHidden: function () {
              },
            },
          );
        }
      });
    }

  }

  /**
   * Fetch an Oauth2 access token from the main app
   * to be able to access the /webapi/ endpoints from mockup/frontend-dev
   * in context of eg encore dev server
   * @private
   */
  _initDevOauthToken() {
    let date = new Date();
    let vm = this;
    let accessCookie;
    let $def = $.Deferred();
    let $innerDef = $.Deferred();

    /**
     * Oauth dev token for frontend GUI work
     */
    accessCookie = new RegExp('(?:^|; )' + encodeURIComponent('DEV-ACCESS-TOKEN') + '=([^;]*)').exec(document.cookie);
    if (!accessCookie) {
      let accessTokenUrl = JsRouting.generate('fos_oauth_server_basic_auth_backdoor', null, true);
      Axios.get(accessTokenUrl)
        .then((response) => {
          if (response.data.access_token > '') {
            // set oauth access token as cookie
            let expires = response.data.expires_at;
            date.setTime(expires * 1000);
            expires = '; expires=' + date.toUTCString();
            document.cookie = 'DEV-ACCESS-TOKEN=' + (response.data.access_token || '') + expires + '; path=/';
            $innerDef.resolve({
              access_token: response.data.access_token,
            });
          } else {
            console.warning('Could not get Oauth2 access data');
            $innerDef.resolve();
          }
        })
        .catch((error) => {
          HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
            vm.answer = 'Error! Could not reach the API. ' + errorString;
            $innerDef.resolve(); // resolve even in error case - we might work on frontend without the need of authenticated API access
          });
        });
    } else {
      $innerDef.resolve(accessCookie[1]);
    }

    $.when($innerDef).done((accessToken) => {
      if (accessToken) {
        // set global default for $.ajax calls
        $.ajaxSetup({
          headers: {
            'Authorization': 'Bearer ' + accessToken,
          },
        });
        // set global default for Axios calls
        Axios.defaults.headers.common = {
          'Authorization': 'Bearer ' + accessToken,
        };
      }

      $def.resolve();  // resolve even in error case - we might work on frontend without the need of authenticated API access
    });

    return $def;
  }

  /**
   *
   * @private
   */
  _initHeader() {
    /**
     * #header
     */
    ((id) => {
      const appContainer = document.getElementById(id);
      if (appContainer) {
        const app1 = new Vue({
          el: appContainer,
          components: {
            TopbarUserProfile,
          },
          mounted() {
            const self = this;
            self.init();
          },
          methods: {
            init() {
              // BEGIN initialize metronic elements (again)
              // here after vue has mounted the #kt_header container
              // the first time this initializations happen in scripts.bundle.js
              // but we must do it again after vue has processed the HTML code

              // initialization of header
              const header = new HSHeader($('#header')).init();
            },
          },
        });
      }
    })('header');
  }

  /**
   *
   * @private
   */
  _initAside() {
    /**
     * #sidebarContent
     */
    ((id) => {
      const appContainer = document.getElementById(id);
      if (appContainer) {
        const app1 = new Vue({
          el: appContainer,
          components: {
            AsidePanel,
          },
          mounted() {
            const self = this;
            self.init();
          },
          methods: {
            init() {
            },
          },
        });
      }
    })('sidebarContent');
  }

  /**
   *
   * @private
   */
  _initMainCta() {
    /**
     * #main-cta
     */
    ((id) => {
      const appContainer = document.getElementById(id);
      if (appContainer) {
        const app1 = new Vue({
          el: appContainer,
          components: {
            FooterNewsletterSubscription,
          },
          methods: {
            blockUi(flag) {
              if (flag) {
                guiUtil.blockUI($(`#${id}`));
              } else {
                guiUtil.unblockUI($(`#${id}`));
              }
            },
          },
        });
      }
    })('main-cta');
  }

  /**
   *
   * @private
   */
  _initCookieConsent() {
    ((id) => {
      const appContainer = document.getElementById(id);
      if (appContainer) {
        const app1 = new Vue({
          el: appContainer,
          components: {
            CookieConsent,
          },
          computed: {
            cookieConsentTools() {
              return window.cookieConsentTools;
            },
          },
          methods: {
            cookieConsentGiven() {
              if (!globalState.state.cookieConsentCategories) {
                globalState.commit('cookieConsent', this.cookieConsentTools.getAllowedCategories());
              }
              // final try to call registered callbacks after given,
              // they will not trigger if according cookie consent not given
              // they will also only trigger once
              this.cookieConsentTools.triggerAllHeaderCallbacks();
            },
          },
        });
      }
    })('cookie-consent');
  }

  /**
   *
   * @private
   */
  _initFooter() {
    /**
     * #footer
     */
    ((id) => {
      const appContainer = document.getElementById(id);
      if (appContainer) {
        const app1 = new Vue({
          el: appContainer,
          components: {
            FooterNewsletterSubscription,
          },
        });
      }
    })('footer');
  }
}

export default new Main();
