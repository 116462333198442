import Vue from 'vue';
import moment from 'moment';
import globalConfig from 'js/app/config/app';
import Translator from 'bazinga-translator';
import MarkdownIt from 'markdown-it';
import JsRouting from 'fos-jsrouting-bundle';

Vue.filter('age', (dateTimeIso) => {
  let age = moment.duration(moment().diff(moment(dateTimeIso)));
  let age_in_seconds = Math.round(age.asSeconds());
  let age_in_minutes = Math.round(age.asMinutes());
  let number = null;
  let label = '';

  if (age_in_seconds < 60) {
    number = age_in_seconds;
    return Translator.transChoice('age.seconds_ago', number, {'num': number}, 'messages');
  } else if (age_in_minutes < 60) {
    number = age_in_minutes;
    return Translator.transChoice('age.minutes_ago', number, {'num': number}, 'messages');
  } else if (age_in_minutes < (60 * 24)) {
    number = Math.floor(age_in_minutes / 60);
    return Translator.transChoice('age.hours_ago', number, {'num': number}, 'messages');
  } else {
    number = Math.floor(age_in_minutes / (60 * 24));
    return Translator.transChoice('age.days_ago', number, {'num': number}, 'messages');
  }
});

Vue.filter('truncate', (text, length, clamp) => {
  if (typeof text == 'undefined' || text.length <= length) {
    return text;
  }
  clamp = clamp || ' ...';
  text = text.replace(/<br>/g, " ");
  let node = document.createElement('div');
  node.innerHTML = text;
  let content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.filter('standardDateTime', (dateTimeIso) => {
  return moment(dateTimeIso).format(globalConfig.moment.datetimeFormat);
});

Vue.filter('standardDate', (dateTimeIso, skipDay) => {
  const doSkipDay = skipDay || false;
  if (dateTimeIso) {
    return moment(dateTimeIso).format(doSkipDay ? globalConfig.moment.dateFormatWithoutDay : globalConfig.moment.dateFormat);
  }
});

Vue.filter('distanceKm', (distance) => {
  distance = Math.round(parseInt(distance * 10) / 10);
  return distance + '&nbsp;km';
});

Vue.filter('capitalize', (value) => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('trans', (key, params, domain, multipleCount) => {
  if (typeof multipleCount !== 'undefined') {
    return Translator.transChoice(key, multipleCount, params || {}, domain || 'messages');
  } else {
    return Translator.trans(key, params || {}, domain || 'messages');
  }
});

Vue.filter('markdown', (value) => {
  if (!value) return '';
  value = value.toString();
  let md = new MarkdownIt();
  return md.render(value);
});

Vue.filter('trimLines', (text) => {
  let lines = text.split("\n");
  $.each(lines, (key, value) => {
    lines[key] = value.trim();
  });
  return lines.join("\n");
});

Vue.filter('currency', (value, currency = 'zł') => {
  let num;
  if (value) {
    num = parseFloat(value)
      .toFixed(2) // always two decimal digits
      .replace('.', globalConfig.decimalPoint) // replace decimal point character with desired locale character
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' ' + currency;
  }
  return num;
});

Vue.filter('path', (key, params, absolute) => {
  return JsRouting.generate(key, params, absolute);
});
