<template>
  <!--inline-template is used here. look for <search inline-template>-->
</template>

<script>
import 'babel-polyfill'; // needed to support "async + await"
import JsRouting from 'fos-jsrouting-bundle';
import guiUtil from 'js/app/utils/gui';
import HttpUtil from 'js/app/utils/http';
import generalUtil from 'js/app/utils/general';
import Axios from 'axios/index';
import KeyValuePairArrayUtil from 'js/app/utils/KeyValuePairArray';
import SearchForm from './search--form.vue';
import SearchGeocoderMap from './search--geocoder-map.vue';
import SearchResults from './search--results.vue';
import SearchMap from './search--map.vue';
import SearchFilters from './search--filters.vue';
import xsrfUtils from 'js/app/utils/xsrf-utils';
import {EventBus} from '../../app/utils/EventBus';
import Post from '../../promotion/models/Post';
import SearchResultsWipDummy from './search--results-wip-dummy.vue';
import persistentStore from 'js/app/store/PersistentStore';

export default {
  name: 'Search',
  directives: {
    'filter-nav': {
      // directive definition
      bind(el, binding, vnode) {
        $(el).find('.nav-link').on('click', (e) => {
          $(el).find('.active').removeClass('active font-weight-bold');
          $(e.target).addClass('active font-weight-bold');
        });
      },
    },
  },
  components: {
    SearchGeocoderMap,
    SearchResults,
    SearchResultsWipDummy,
    SearchMap,
    SearchFilters,
  },
  props: {
    searchRoute: {
      type: String,
      default: '',
    },
    referenceLocation: {
      type: Object,
      default() {
        return {
          latitude: 51.079019,
          longitude: 16.996882,
        };
      },
    },
  },
  data() {
    return {
      isLoading: false,
      searchResults: [],
      pagination: {
        perpage: 10,
        page: 1,
      },
      total: 0,
      searchLocation: {
        lat: this.referenceLocation.latitude,
        lng: this.referenceLocation.longitude,
      },
      place: '',
      sortBy: 'default',
      //searchRadius: 100,
      filters: {
        spatial_d: {
          values: [
            {value: 20, label: '20 km'},
            {value: 50, label: '50 km', default: true},
            {value: 100, label: '100 km'},
            {value: 200, label: '200 km'},
          ],
        },
        product_type: {
          values: [
            {value: 'accommodation', label: 'Noclegi'},
            {value: 'food', label: 'Naturalna żywność'},
            {value: 'handicraftandservices', label: 'Rzemiosło i rękodzieło'},
          ],
        },
        attribute_agrotourism_farm_type: {
          values: [
            {value: 'animal_husbandry', label: 'animal_husbandry'},
            {value: 'fruit_vegetables_cultivation', label: 'fruit_vegetables_cultivation'},
            {value: 'wine_production', label: 'wine_production'},
          ],
        },
        logic_accommodation_type: {
          values: [
            {value: 'accommodation_room', label: 'Room'},
            {value: 'accommodation_apartment', label: 'Apartment'},
            {value: 'accommodation_holiday_house', label: 'Holiday house'},
            {value: 'accommodation_camping_house', label: 'Camping house'},
            {value: 'accommodation_tent', label: 'Tent'},
            {value: 'accommodation_bungalow', label: 'Bungalow'},
          ],
        },
        attribute_accommodation_board_type: {
          values: [
            {value: 'self_catering', label: 'Self catering'},
            {value: 'breakfast', label: 'Breakfast'},
            {value: 'half_board', label: 'Half board'},
            {value: 'full_board', label: 'Full board'},
          ],
        },
      },
      filtersSet: [],
    };
  },
  computed: {
    showForm() {
      return !this.showResults;
    },
    showResults() {
      return this.searchResults !== null && this.searchResults.length > 0;
    },
    showFilters() {
      return this.showResults;
    },
    searchRadius() {
      return KeyValuePairArrayUtil.elementGet(this.filtersSet, 'spatial_d')[0];
    },
  },
  watch: {
    total(val) {
      if (val < (this.pagination.page * this.pagination.perpage)) {
        this.pagination.page = 1;
      }
    },
  },
  created() {
    this.searchUrl = JsRouting.generate(this.searchRoute);
  },
  mounted() {
    // component initialization
    this.init();
  },
  methods: {

    handleFiltersetChanged(newFilterSet) {
      this.filtersSet = null;
      this.filtersSet = newFilterSet;
      // after the initial query has been performed react on each filter value change
      // with a fresh query
      //if (this.showResults === true) {
        // now perform the new search with new filter values
        this.performSearch(null, true);
      //}
    },

    async init() {
      const self = this;
      let stateObj = [];

      // handling of browser back/forward buttons
      window.onpopstate = (event) => {
        this.pagination.page = 1;
        this.performSearch(event.state);
      };

      try {
        await this.detectInitialSearchLocation();
        await this.performInitialSearch();
      } catch (err) {
        console.error(err); // TypeError: failed to fetch
      }
    },

    async detectInitialSearchLocation() {
      const self = this;
      // determine the location for the search
      if (persistentStore.state.searchParams.spatial_pt) {
        const queryParameters = persistentStore.state.searchParams;
        const queryLocation = queryParameters['spatial_pt'];
        const [lat, lng] = queryLocation.split(',');
        self.searchLocation = {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        };
        self.place = queryParameters['place'];
      } else if (navigator.geolocation) {
        // fetch user position from the browser
        await navigator.geolocation.getCurrentPosition((position) => {
          self.searchLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });
      } else {
        // static fallback
        console.info('No geolocation data available');
        self.searchLocation = {
          lat: this.referenceLocation.latitude,
          lng: this.referenceLocation.longitude,
        };
      }
    },

    async performInitialSearch() {
      const self = this;
      let stateObj = [];
      // initial search if we hit the search page with search parameters from history API
      if (window.history.state && window.history.state.length > 0) {
        // begin: fetch search and pagination values from history stateObject
        stateObj = window.history.state;
        const paginationData = {
          rows: parseInt(KeyValuePairArrayUtil.elementGet(stateObj, 'q[rows]')),
          start: parseInt(KeyValuePairArrayUtil.elementGet(stateObj, 'q[start]')),
        };
        if (paginationData.start || paginationData.rows) {
          this.pagination.perpage = parseInt(paginationData.rows);
          this.pagination.page = parseInt((paginationData.start / paginationData.rows) + 1);
        }
        // end: fetch search and pagination values from history
        // perform search based on the current history state object
        try {
          await this.performSearch();
        } catch (err) {
          console.error(err); // TypeError: failed to fetch
        }
      } else if (persistentStore.state.searchParams) {
        // begin: fetch search and pagination values from initial query coming from an aggregator
        // page like the home page or start page of a theme section
        const queryParameters = persistentStore.state.searchParams;
        this.place = queryParameters['place'] || '-';
        const paginationData = {
          rows: 10,
          start: 0,
        };
        if (paginationData.start || paginationData.rows) {
          this.pagination.perpage = parseInt(paginationData.rows);
          this.pagination.page = parseInt((paginationData.start / paginationData.rows) + 1);
        }
        // end: fetch search and pagination values from history
        // perform search with stateObj initialized with the given string value for place to be shown in page header
        stateObj = [];
        stateObj = KeyValuePairArrayUtil.uniqueKeyElementSet(stateObj, 'place', this.place, true);
        try {
          await this.performSearch(stateObj);
        } catch (err) {
          console.error(err); // TypeError: failed to fetch
        }
      }
    },

    /**
     * Performs the search query and returns a premise
     * @param inputStateObj
     * @param freshQuery
     * @returns {*}
     */
    performSearch(inputStateObj, freshQuery) {
      const searchReady = $.Deferred();
      const self = this;
      const queryData = [];

      // should we perform a fresh query with current filters
      // in case pagination or infinite scroll is warking already?
      if (freshQuery && freshQuery === true) {
        this.searchResults = [];
        this.total = 0;
        // always begin on first page when some filter value changes
        this.pagination.page = 1;
      }

      // 1. Update state object

      let stateObj = inputStateObj || window.history.state;

      stateObj = KeyValuePairArrayUtil.uniqueKeyElementSet(stateObj, 'q[spatial_pt]', `${this.searchLocation.lat},${this.searchLocation.lng}`, true);
      stateObj = KeyValuePairArrayUtil.uniqueKeyElementSet(stateObj, 'q[sort]', this.sortBy, true);
      for (const filterValueKey in this.filtersSet) {
        if (Array.isArray(this.filtersSet[filterValueKey][1])) {

            stateObj = KeyValuePairArrayUtil.uniqueKeyElementSet(
              stateObj,
              `q[${this.filtersSet[filterValueKey][0]}][]`,
              this.filtersSet[filterValueKey][1],
            );

        } else {
          stateObj = KeyValuePairArrayUtil.uniqueKeyElementSet(
            stateObj,
            `q[${this.filtersSet[filterValueKey][0]}]`,
            this.filtersSet[filterValueKey][1],
          );
        }
      }

      // 2. Update history URL for the state object

      $.each(stateObj, (i, pair) => {
        if (pair[0] === 'q[spatial_pt]') {
          // obfuscate geo coordinates in the history state url to avoid violating
          // terms of use for mapping service which might prohibit showing the geocoding
          // results to the end user in the GUI
          queryData.push(`${i}=${generalUtil.simpleHash(pair[1])}`);
        } else {
          if (Array.isArray(pair[1])) {
            pair[1].forEach((pairVal) => {
              queryData.push(`${i}=${pairVal}`);
            });
          } else {
            queryData.push(`${i}=${pair[1]}`);
          }
        }
      });
      window.history.pushState(stateObj, '', `?${queryData.join('&')}`);

      // 3. Do the search

      if (this.maxPaginationCountReached) {
        // we reached max page count,
        // for performance reasons do not continue fetching more data
        searchReady.resolve();
      } else if (stateObj && stateObj.length > 0) {
        // BEGIN: build form data object to be sent within query
        const formData = new FormData();
        for (const pair of stateObj) {
          if (Array.isArray(pair[1])) {
            pair[1].forEach((pairVal) => {
              formData.append(pair[0], pairVal);
            });
          } else {
            formData.append(pair[0], pair[1]);
          }
        }
        formData.append('q[rows]', this.pagination.perpage);
        formData.append('q[start]', ((this.pagination.page - 1) * this.pagination.perpage));
        // END: build form data object to be sent within query

        this.isLoading = true;
        xsrfUtils.fetchCsrfToken('search', false).done((token) => {
          // append the retrieved xsrf token to form data for the actual query
          formData.append('q[_token]', token);
          // send the search query
          Axios.post(self.searchUrl, formData).then((response) => {
            $.each(response.data.rows, (index, value) => {
              self.searchResults.push(value);
            });
            self.total = parseInt(response.data.total);
          }).catch((error) => {
            HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
            });
            searchReady.reject();
          }).then(() => {
            // fetch the reverse geocoder place string to be displayed in the page header
            const queryParameters = persistentStore.state.searchParams;
            this.place = queryParameters['place'] || '-';
            // 2nd then after catch is always executed - catch MUST be present for this to work
            this.isLoading = false;
            searchReady.resolve();
          });
        }).fail(() => {
          console.error('token fetch failed');
          this.isLoading = false;
        });
      } else {
        searchReady.resolve();
      }
      return searchReady;
    },

    /**
     * Do a fresh query
     * @param stateObj
     */
    searchInitiatedEventHandler(stateObj) {
      this.performSearch(stateObj, true);
    },

    /**
     * Do a fresh query
     * @param stateObj
     */
    filterInitiatedEventHandler(stateObj) {
      this.performSearch(stateObj, true);
    },

    /**
     * Do a fresh query
     */
    backToSearchFormEventHandler() {
      this.searchResults = [];
      this.pagination.page = 1;
      this.searchResults = null;
    },

    paginationEventHandler(page, perpage) {
      this.pagination.page = parseInt(page);
      this.pagination.perpage = parseInt(perpage);
      this.performSearch();
    },

    /**
     * Do a new sorted query
     */
    sortEventHandler(sortField) {
      this.sortBy = sortField;
      this.performSearch(null, true);
    },

    geocodeEventHandler(lat, lng, place) {
      this.searchLocation.lat = lat;
      this.searchLocation.lng = lng;
      persistentStore.commit('searchParams', {
        place: place,
        spatial_pt: `${lat},${lng}`,
      });
    },

    resetSearch() {
      // TODO reset the search form
      // alert('TODO reset the search form');
      // get full URL
      const currURL = window.location.href; // get current address

      // Get the URL between what's after '/' and before '?'
      // 1- get URL after'/'
      const afterDomain = currURL.substring(currURL.lastIndexOf('/') + 1);
      // 2- get the part before '?'
      const beforeQueryString = afterDomain.split('?')[0];

      this.searchResults = [];
      window.history.replaceState([], '', beforeQueryString);
    },

  },
};
</script>

<style scoped>
</style>
