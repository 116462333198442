var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-custom gutter-b mb-2" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("div", { staticClass: "d-flex flex-column flex-grow-1" }, [
            _vm.comment.author
              ? _c(
                  "span",
                  {
                    staticClass:
                      "text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder"
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.comment.author.first_name) +
                        " " +
                        _vm._s(_vm.comment.author.last_name)
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.comment.author
              ? _c(
                  "span",
                  {
                    staticClass:
                      "text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder"
                  },
                  [_vm._v("Komentarz anonimowy (lub usunięte konto)")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "text-muted font-size-xs" }, [
              _vm._v(_vm._s(_vm._f("age")(_vm.comment.created_at)))
            ])
          ]),
          _vm._v(" "),
          _vm.isAuthenticated
            ? _c(
                "div",
                {
                  staticClass: "dropdown dropdown-inline ml-2",
                  attrs: {
                    "data-toggle": "tooltip",
                    title: "Quick actions",
                    "data-placement": "left"
                  }
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right"
                    },
                    [
                      _c("ul", { staticClass: "navi navi-hover" }, [
                        _vm.isOwner
                          ? _c("li", { staticClass: "navi-item" }, [_vm._m(1)])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isOwner
                          ? _c("li", { staticClass: "navi-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "navi-link",
                                  attrs: { href: "#" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "navi-text",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.deleteComment.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Usuń komentarz")]
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isOwner
                          ? _c("li", { staticClass: "navi-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "navi-link",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.reportMisuse()
                                    }
                                  }
                                },
                                [
                                  _c("span", { staticClass: "navi-text" }, [
                                    _vm._v("Zgłoś nadużycie")
                                  ]),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "flaticon2-information icon-md text-muted",
                                    attrs: {
                                      "data-toggle": "tooltip",
                                      "data-placement": "right",
                                      title: "Click to learn more..."
                                    }
                                  })
                                ]
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "p",
            {
              staticClass:
                "text-dark-75 font-size-lg font-weight-normal pt-2 mb-1"
            },
            [_vm._v("\n          " + _vm._s(_vm.comment.body) + "\n        ")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-hover-light-primary btn-xs btn-icon",
        attrs: {
          href: "#",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "ki ki-bold-more-hor" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "navi-link", attrs: { href: "#" } }, [
      _c("span", { staticClass: "navi-text" }, [_vm._v("Edytuj komentarz")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }