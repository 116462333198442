<script>
import 'jqvmap/dist/jquery.vmap';
import 'js/_res/jquery.vmap.poland-touristic-regions';

export default {
  name: 'JqvmapTouristicRegions',
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    expanded: {
      type: Boolean,
      default: true
    },
    hiddenField: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  directives: {},
  data() {
    return {}
  },
  created() {
    this.map = null;
    this.jqvmapColors = {
      'lubelszczyzna': '#9AC1FE',
      'malopolska': '#9CC2FE',
      'kurpie': '#9FC4FE',
      'podkarpacie': '#A2C5FE',
      'warmia': '#A5C7FE',
      'pomorze': '#A8C9FE',
      'dolny-slask': '#AACAFE',
      'wielkopolska': '#ADCCFE',
      'podlasie': '#B0CDFE',
      'warmia-i-mazury': '#B3CFFE',
      'region-swietokrzyski': '#B6D1FE',
      'mazowsze': '#B9D2FE',
      'slask-opolski': '#BBD4FE',
      'suwalszczyzna': '#BED5FE',
      'kaszuby': '#C1D7FE',
      'ziemia-lubuska': '#C4D9FE',
      'kujawy-i-pomorze': '#C7DAFE',
      'pomorze-zachodnie': '#CADCFE',
      'ziemia-klodzka': '#CCDDFE',
      'gorny-slask': '#CFDFFE',
      'ziemia-lowicka': '#D2E1FE',
      'polesie': '#D5E2FE',
      'podhale': '#D8E4FE',
      'roztocze': '#DBE6FF',
    };
  },
  mounted() {
    this.init();
  },
  computed: {},
  methods: {
    init() {
      let self = this;
      this.map = $('#vmap').vectorMap({
        map: 'poland-touristic-regions',
        backgroundColor: '#fff',
        borderColor: '#fff',
        borderOpacity: 1,
        borderWidth: 3,
        color: '#f4f3f0',
        enableZoom: false,
        hoverColor: '#4B1BD7',
        colors: this.jqvmapColors,
        normalizeFunction: 'linear',
        scaleColors: ['#b6d6ff', '#005ace'],
        selectedColor: '#12dfde',
        selectedRegions: [],
        showTooltip: true,
        multiSelectRegion: this.multiple,
        onRegionClick: function (element, code, region) {
          var message = 'You clicked "'
            + region
            + '" which has the code: '
            + code.toUpperCase();
          //console.log(message);
        },
        onRegionSelect: function (event, code, region) {
          self.checkRegion(code);
        },
        onRegionDeselect: function (event, code, region) {
          self.uncheckRegion(code);
        },
        onLabelShow: function (event, label, code) {
          let old = self.trans('attribute.touristic_region.poland.' + label.text(), {});
          label.text(old);
        }
      });

      if (self.hiddenField) {
        self.map.select(this.getInitialValue().replace("poland.", ""));
      } else if (self.expanded) {
        $(this.$el).find('input[type="checkbox"]').each((key, val) => {
          let optionValue = null;
          $(val).click(() => {
            optionValue = $(val).val().replace("poland.", "");
            if ($(val).is(':checked') && !self.map.isSelected(optionValue)) {
              self.map.select(optionValue);
            } else if (self.map.isSelected(optionValue)) {
              self.map.deselect(optionValue);
            }
          });
        });
      } else {
        $(this.$el).find('select')[0].addEventListener('change', function (e) {
          let optionValue = null;
          // Its very important to first deselect ols value in one loop
          // and THEN select the new value in the SECOND loop !!!
          // If we try to do it in one loop it WILL cause multiple regions to stay selected
          // at the same time when the new entry comes before the old one in the loop

          // so here the first deselection loop
          for (let option of e.target) {
            optionValue = $(option).val().replace("poland.", "");
            if (optionValue > '' && self.map.isSelected(optionValue)) {
              self.map.deselect(optionValue);
            }
          }

          // so here the second selection loop
          for (let option of e.target) {
            optionValue = $(option).val().replace("poland.", "");
            if (optionValue > '' && option === e.target.options[e.target.selectedIndex] && !self.map.isSelected(optionValue)) {
              self.map.select(optionValue);
            }
          }

        });
      }
    },

    trans(key, params, domain) {
      let trans = Translator.trans(key, params || {}, domain || 'AppProduct');
      return trans;
    },

    getInitialValue() {
      if (this.hiddenField) {
        return $(this.$el).find('input[type="hidden"]').val();
      } else if (this.expanded) {
        $(this.$el).find('input[type="checkbox"]').each((key, val) => {
          if ($(this).val() === "poland." + regionCode) {
            if (!$(this).is(':checked')) {
              $(this).prop("checked", true);
            }
          } else {
            if (!this.multiple) {
              // uncheck all other choices
              $(this).prop("checked", false);
            }
          }
        });
      } else {
        $(this.$el).find('select option').each((key, val) => {
          if ($(val).val() === "poland." + regionCode) {
            if (!$(val).is(':selected')) {
              $(val).prop("selected", true);
            }
          } else {
            if (!this.multiple) {
              // uncheck all other choices
              $(val).prop("selected", false);
            }
          }
        });
      }
    },

    checkRegion(regionCode) {
      let self = this;

      if (self.hiddenField) {
        $(this.$el).find('input[type="hidden"]').val('poland.' + regionCode);
      } else if (self.expanded) {
        $(this.$el).find('input[type="checkbox"]').each((key, val) => {
          if ($(this).val() === "poland." + regionCode) {
            if (!$(this).is(':checked')) {
              $(this).prop("checked", true);
            }
          } else {
            if (!self.multiple) {
              // uncheck all other choices
              $(this).prop("checked", false);
            }
          }
        });
      } else {
        $(this.$el).find('select option').each((key, val) => {
          if ($(val).val() === "poland." + regionCode) {
            if (!$(val).is(':selected')) {
              $(val).prop("selected", true);
            }
          } else {
            if (!self.multiple) {
              // uncheck all other choices
              $(val).prop("selected", false);
            }
          }
        });
      }

    },

    uncheckRegion(regionCode) {
      let self = this;

      if (self.hiddenField) {
        // do nothing;
      } else if (self.hiddenField) {
        $(this.$el).find('input[type="hidden"]').val(null);
      } else if (self.expanded) {
        $(this.$el).find('input[type="checkbox"]').each((key, val) => {
          if ($(val).val() === "poland." + regionCode) {
            if ($(val).is(':checked')) {
              $(val).prop("checked", false);
            }
          }
        });
      } else {
        $(this.$el).find('select option').each((key, val) => {
          if ($(val).val() === "poland." + regionCode) {
            $(val).prop("selected", false);
          }
        });
      }
    }

  },
}

</script>

<style scoped>

</style>
