<template>
  <div>
    <!--inline-template is used here. look for <search-form inline-template>-->
  </div>
</template>

<script>
import JqvmapTouristicRegions from './jqvmap-touristic-regions.vue';
//import CurrentLocationFinderLeaflet from 'js/app/components/current-location-finder-leaflet.vue';
import CurrentLocationFinderMapboxGl from 'js/app/components/current-location-finder-mapbox-gl.vue';

export default {
  name: 'search-form',
  components: {
    JqvmapTouristicRegions,
    //CurrentLocationFinderLeaflet,
    CurrentLocationFinderMapboxGl,
  },
  directives: {},
  props: ['initLocation'],
  data() {
    return {
      locationIsSet: false,
    };
  },
  computed: {},

  watch: {},

  created() {
  },

  mounted() {
    if ($(this.$el)[0].nodeName === 'FORM') {
      this.$form = $(this.$el);
    } else {
      this.$form = $(this.$el).find('form');
    }

    this.init();
  },
  beforeDestroy() {
    // remove listener to avoid multiple registrations
    this.$off('repaint-map');
  },

  methods: {

    init() {
      this.$on('repaint-map', () => {
        // pass the event further down to a subcomponent
        // very important (at least for mapboxgl) to trigger a map resize/repaint sometimes
        // eg. on tab switch
        this.$refs['location-finder-map'].$emit('repaint-map');
      });
    },

    /**
     *
     * @param event
     */
    processForm(event) {
      const self = this;
      let formData = new FormData(this.$form[0]);
      let queryData = [];
      let stateObj = [];

      for (const pair of formData.entries()) {
        stateObj.push([pair[0], pair[1]]);
      }

      $.each(stateObj, (i, pairItem) => {
        queryData.push(`${i}=${pairItem[1]}`);
      });

      window.history.pushState(stateObj, '', `?${queryData.join('&')}`);

      this.performSearch(stateObj);
    },

    performSearch(stateObj) {
      const self = this;

      stateObj = stateObj || window.history.state;
      if (stateObj && stateObj.length > 0) {
        self.$emit('search-initiated', stateObj);
      }
    },

    geocodeEventHandler(latitude, longitude) {
      this.$emit('geocode', latitude, longitude);
    },

  },
};
</script>

<style scoped>

</style>
