import Translator from 'bazinga-translator';

/**
 * Created by artur on 6/27/14.
 */

class Utils {
  constructor() {

    /**
     * Init plugins
     * @type {{qtip: Function}}
     */
    // var initPlugins = {
    //
    //   /**
    //    * init qtip
    //    * @param $rootContainer
    //    */
    //   qtip: function($rootContainer) {
    //     $rootContainer = $rootContainer  || $('body').first();
    //     if (jQuery().qtip) {
    //       $rootContainer.find('a[data-hasqtip="simple"]').each((index, el) => {
    //         $(el).qtip({
    //           position: {
    //             viewport: $(window),
    //             at: 'bottom right'
    //           },
    //           style: {
    //             classes: 'qtip-light'
    //           }
    //         });
    //       });
    //       $rootContainer.find('a[data-hasqtip="mouse"]').each((index, el) => {
    //         $(el).qtip({
    //           content: $(this).attr('title'),
    //           position: {
    //             viewport: $(window),
    //             target: 'mouse', // Track the mouse as the positioning target
    //             adjust: { x: 5, y: 5 } // Offset it slightly from under the mouse
    //           }
    //         });
    //       });
    //     }
    //   },
    //
    //   /**
    //    *
    //    * @param $rootContainer
    //    */
    //   touchspin: function($rootContainer) {
    //     $rootContainer = $rootContainer  || $('body').first();
    //     if (jQuery().TouchSpin) {
    //       $rootContainer.find('.touchspin').each((index, el) => {
    //         $(el).TouchSpin({
    //           verticalbuttons: true
    //         });
    //       });
    //     }
    //   },
    //
    //   /**
    //    *
    //    * @param $rootContainer
    //    */
    //   datePicker: function($rootContainer) {
    //     $rootContainer = $rootContainer  || $('body').first();
    //     var self = this;
    //     // init date pickers
    //     if (jQuery().datepicker) {
    //       $rootContainer.find('.date-picker').each((index, el) => {
    //         $(el).datepicker({
    //           orientation: "left",
    //           format: globalConfig.picker.dateFormat,
    //           autoclose: true
    //         });
    //       });
    //       //$('body').removeClass("modal-open"); // fix bug when inline picker is used in modal
    //     }
    //     /* Workaround to restrict daterange past date select: http://stackoverflow.com/questions/11933173/how-to-restrict-the-selectable-date-ranges-in-bootstrap-datepicker */
    //   },
    //
    //   /**
    //    *
    //    * @param $rootContainer
    //    */
    //   datetimePicker: function($rootContainer) {
    //     $rootContainer = $rootContainer  || $('body').first();
    //     var self = this;
    //     var isRTL = false;
    //
    //     // init date pickers
    //     if (jQuery().datetimepicker) {
    //       $rootContainer.find('.form_datetime').each((index, el) => {
    //         $(el).datetimepicker({
    //           autoclose: true,
    //           isRTL: isRTL,
    //           format: globalConfig.picker.datetimeFormat,
    //           pickerPosition: (isRTL ? "bottom-right" : "bottom-left")
    //         });
    //       });
    //       //$('body').removeClass("modal-open"); // fix bug when inline picker is used in modal
    //     }
    //   },
    //
    //   /**
    //    * Init uniform pasing a list of elements to metronic handler
    //    * @param $rootContainer
    //    */
    //   uniform: function($rootContainer) {
    //     $rootContainer = $rootContainer  || $('body').first();
    //     // init date pickers
    //     if (jQuery().uniform) {
    //       var elements = $rootContainer.find("input[type=checkbox]:not(.toggle, .kt-check, .md-radiobtn, .make-switch, .icheck), input[type=radio]:not(.toggle, .kt-check, .md-radiobtn, .star, .make-switch, .icheck)");
    //       if (elements.size() > 0) {
    //         App.initUniform(elements);
    //       }
    //     }
    //   },
    //
    //   /**
    //    *
    //    */
    //   bootstrapConfirmation: function($rootContainer) {
    //     $rootContainer = $rootContainer  || $('body').first();
    //     if (!$().confirmation) {
    //       return;
    //     }
    //     $rootContainer.find('[data-toggle=confirmation]').each((index, el) => {
    //       $(el).confirmation({
    //         btnOkClass: 'btn btn-sm btn-success',
    //         btnCancelClass: 'btn btn-sm btn-danger'
    //       });
    //     });
    //   },
    //
    //   /**
    //    * Init client side image placeholders
    //    * @param $rootContainer
    //    */
    //   holder: function($rootContainer) {
    //     $rootContainer = $rootContainer  || $('body').first();
    //     // init date pickers
    //     if (window.Holder) {
    //       $rootContainer.find("img").each((index, el) => {
    //         Holder.run({
    //           images: el
    //         });
    //       });
    //     }
    //   }
    //
    // }

  }

  /**
   * custom loging method as a wrapper for console.log()
   * @param msg
   * @param stackLevel
   */
  log(msg, stackLevel) {

    // 1. Convert args to a normal array
    var args = Array.prototype.slice.call(arguments);

    // 2. Prepend log prefix log string
    args.unshift('App ');

    // 3. Pass along arguments to console.log
    console.log.apply(console, args);

  }

  /**
   * simple hashing for strings
   * @param stringToHash
   * @returns {number}
   */
  simpleHash(stringToHash) {
    let hash = 0;
    let char = '';
    if (stringToHash.length === 0) {
      return hash;
    }
    for (let i = 0; i < stringToHash.length; i++) {
      char = stringToHash.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

  /**
   * Make a deep copy/clone of an array or object
   * This is useful in some cases where we want to really copy
   * a complex datatype and modify it later without affecting
   * the original copied variable.
   * We use jQueries $.extend which performs a deep copy
   * under the hood.
   * @param input
   * @returns {*}
   */
  deepClone(input) {
    let output;
    if (Object.prototype.toString.call(input) === '[object Array]') {
      output = $.extend(true, [], input);
    } else if (typeof input === 'object') {
      output = $.extend(true, {}, input);
    } else {
      // not a complex type
      // so return the value
      // which is not a pointer in case of primitive types
      output = input;
    }
    return output;
  }

  /**
   * Check if accessible and iterable properties are equal in two objects
   * @param a
   * @param b
   * @param toExclude
   * @returns {boolean}
   */
  isObjectPropertiesEqual(a, b, toExclude = []) {
    const aEntries = Object.entries(a);
    const bEntries = Object.entries(b);
    if (aEntries.length >= bEntries.length) {
      return aEntries.every((entry) => {
        if (toExclude.includes(entry[0])) {
          return true;
        } else {
          return b.hasOwnProperty(entry[0]) && b[entry[0]] === a[entry[0]];
        }
      });
    } else {
      return bEntries.every((entry) => {
        if (toExclude.includes(entry[0])) {
          return true;
        } else {
          return a.hasOwnProperty(entry[0]) && b[entry[0]] === a[entry[0]];
        }
      });
    }
  }

  /**
   * Crop a string to a given length without cutting words
   * @param input
   * @param maxCharacters
   */
  stringWordCrop(input, maxCharacters) {
    if (input.length <= maxCharacters) {
      return input;
    }
    var trimmedString = input.substr(0, maxCharacters);
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
    return trimmedString + '...';
  }

  /**
   * Remove given element from an array
   * @param array
   * @param element
   */
  arrayRemove(array, element) {
    array.splice(array.indexOf(element), 1);
  }

  /**
   * save a geocode cache entry
   */
  addCacheGeocode(lat, lon, countryISO, city) {

    /*
     * Throw the geo data to the server!
     */
    $.ajax({
      type: 'POST',
      url: Routing.generate('app_system_cachegeodata'),
      data: {
        longitude: lon,
        latitude: lat,
        countryISO: countryISO,
        city: city
      },
      beforeSend: function () {
        //utils.spin.show();
      },
      success: function (data, textStatus, jqXHR) {
        //utils.spin.hide();
      }
    });

  }

  /**
   * get a symfony session value
   */
  getSessionValue(key, callback) {

    $.ajax({
      type: 'POST',
      url: Routing.generate('app_system_getsessionvalue'),
      async: true,
      data: {
        key: key
      },
      beforeSend: function () {
        //utils.spin.show();
      },
      success: function (data, textStatus, jqXHR) {
        callback(data.value);
        //utils.spin.hide();
      }
    });

  }

  /**
   * get a symfony session value
   */
  setSessionValue(key, value, callback) {

    var returnData = null;

    $.ajax({
      type: 'POST',
      url: Routing.generate('app_system_setsessionvalue'),
      async: false,
      data: {
        key: key,
        value: value
      },
      beforeSend: function () {
        //utils.spin.show();
      },
      success: function (data, textStatus, jqXHR) {
        returnData = data.value;
        //utils.spin.hide();
      }
    });

    return returnData;

  }

  /**
   * append the locale paremeter to an url
   * @param url
   * @returns {string}
   */
  localizeUrl(url) {
    return url + '?_locale=' + document.documentElement.lang;
  }

  /**
   * Get browser geolocation data
   */
  browserGeolocation() {
    let def = $.Deferred();
    // fetch user position
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        def.resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      }, error => {
        def.resolve(null);
      });
    } else {
      def.resolve(null);
    }
    return def;
  }

  uniqid(a = '', b = false) {
    const c = Date.now() / 1000;
    let d = c.toString(16).split('.').join('');
    while (d.length < 14) d += '0';
    let e = '';
    if (b) {
      e = '.';
      e += Math.round(Math.random() * 100000000);
    }
    return a + d + e;
  }

  sanitizeApiError(responseData) {
    const res = {};
    if (typeof responseData.errors !== 'undefined') {
      if (responseData.errors[0]['code']) {
        res.message = Translator.trans(`api-error.${responseData.errors[0]['code']}`);
      } else {
        res.message = responseData.errors[0];
      }
    }
    return res;
  }

}

// return model
export default new Utils();
