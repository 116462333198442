import Vue from 'vue';
import Vuex from 'vuex';
import Subscription from 'js/accounting/models/Subscription';

Vue.use(Vuex);

const globalStore = new Vuex.Store({
  state: {
    anonymousVisit: true,
    currentSubscription: null,
    waitingSubscription: null,
    userData: null,
    userMenu: null,
    userAvatar: null,
    cookieConsentCategories: null,
    enabledFeatures: null,
    acl: null,
    mailboxChangeIncrement: 0,
  },
  mutations: {
    anonymousVisit(state, flag) {
      state.anonymousVisit = flag;
    },
    currentSubscription(state, subscriptionObject) {
      if (subscriptionObject.constructor !== Subscription.constructor) {
        state.currentSubscription = subscriptionObject;
      }
    },
    waitingSubscription(state, subscriptionObject) {
      if (subscriptionObject.constructor !== Subscription.constructor) {
        state.waitingSubscription = subscriptionObject;
      }
    },
    acl(state, acl) {
      if (acl) {
        state.acl = acl;
      }
    },
    user(state, userData) {
      if (userData) {
        state.userData = userData;
      }
    },
    menu(state, menuHtml) {
      if (menuHtml) {
        state.userMenu = menuHtml;
      }
    },
    avatar(state, avatarData) {
      if (avatarData) {
        state.userAvatar = avatarData;
      }
    },
    cookieConsent(state, categories) {
      state.cookieConsentCategories = categories;
    },
    features(state, enabledFeatures) {
      if (enabledFeatures) {
        state.enabledFeatures = enabledFeatures;
      }
    },
    mailboxChangeIncrement(state, increment) {
      state.mailboxChangeIncrement = state.mailboxChangeIncrement + increment;
    },
  },
});

export default globalStore;
