<!--
 !!! DO NOT use any other mapping provider then OpenStreetMap !!!
 We use coordinates derived from the map t ostore them along our POI records
 in our own database to build a POI register
 This is not allowed with most of the map providers but OpenStreetMap !!!
-->

<template>
  <div id="poi-location-manage" ref="poi-location-manage">
    <div ref="map-canvas" id="map-canvas" />
    <!-- Modal Component - add POI -->
    <!-- very important to set static and lazy flags otherwise we won't see this modal while in map fullscreen
     see also other comments in this file related to 'map fullscreen' -->
    <b-modal ref="modal-poi-add" id="poi-add-modal" size="xl" centered static lazy
             :title="trans('poi.manager.propose_poi')"
             @ok="submitPoiNewModalForm"
    >
      <template v-slot:default>
        <poi-item-form ref="poi-item-form"
                       :coordinates="newPoiCoordinates"
                       :success-callback="onNewFormSubmitSuccess"
                       :error-callback="onNewFormSubmitFailure"
        />
      </template>
    </b-modal>

    <!-- Modal Component - edit POI -->
    <!-- very important to set static and lazy flags otherwise we won't see this modal while in map fullscreen
     see also other comments in this file related to 'map fullscreen' -->
    <b-modal ref="modal-poi-edit" id="poi-edit-modal" size="xl" centered static lazy
             :title="trans('poi.manager.edit_poi')"
             @ok="submitPoiEditModalForm"
    >
      <template v-slot:default>
        <poi-item-edit-form ref="poi-item-form"
                       :poi-item="currentPoiItem"
                       :success-callback="onEditFormSubmitSuccess"
                       :error-callback="onEditFormSubmitFailure"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import JsRouting from 'fos-jsrouting-bundle';
import Axios from 'axios';
import 'babel-polyfill'; // needed to support "async + await"
import {BModal, VBModal, BButton} from 'bootstrap-vue';
import PoiItemForm from './poi-item-form';
import PoiItemEditForm from './poi-item-edit-form';
import securityUtil from 'js/app/utils/security';
import guiUtil from 'js/app/utils/gui';
import HttpUtil from 'js/app/utils/http';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-extra-markers';
import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.min.css';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import Translator from 'bazinga-translator';
import generalUtil from 'js/app/utils/general';
import globalConfig from 'js/app/config/app';
import globalState from 'js/app/store/GlobalStore';
import polandWojewodztwa from 'js/_res/poland-woj.json.json';
import * as turf from '@turf/helpers';
import { booleanPointInPolygon } from '@turf/turf';
import _ from 'lodash';
import LMarkerPoiPopup from './leaflet/l-marker-poi-popup.vue';
import {EventBus} from 'js/app/utils/EventBus';
import Vue from 'vue';
import PoiEntry from '../models/PoiEntry';

// // BEGIN: workaround issue with wrong marker icon path in webpack
// // @see https://github.com/PaulLeCam/react-leaflet/issues/255
// // // edit 02.01.2021 seems not to be needed anymore, keep code here for future reference
// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//     iconUrl: require('leaflet/dist/images/marker-icon.png'),
//     shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// });
// // END: workaround issue with wrong marker icon path in webpack

export default {
  name: 'PoiLocationManage',
  components: {
    BModal,
    PoiItemForm,
    PoiItemEditForm,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    longitude: {
      type: String,
      default: '',
    },
    latitude: {
      type: String,
      default: '',
    },
    poiRadius: {
      type: Number,
      default: 150,
    },
    // which wojewodztwo from the geojson
    // (js/_res/poland-woj.json.json) want we edit now in admin mode?
    currentAreaFeatureName: {
      type: String,
      default: 'Dolnoslaskie',
    },
    mapboxApiKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mapInitialized: false,
      markers: [],
      newPoiCoordinates: null,
      zoomStartLevel: null,
      minZoomLevel: null,
      refCoordinates: {
        longitude: this.longitude,
        latitude: this.latitude,
      },
      poiMarkerArray: [],
      currentPoiItem: null,
    };
  },
  computed: {
    apiAvailable() {
      return !(typeof L === 'undefined');
    },
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    },
    isEditorSession() {
      return (
        globalState.state.acl
        && (globalState.state.acl.admin === 1 || globalState.state.acl.frontendEditor.poi === 1)
      );
    },
    categoryTranslated() {
      return this.trans(`category.${this.category}`);
    },
  },
  watch: {
    /**
     * see https://github.com/Leaflet/Leaflet.markercluster
     * !!! do not use deep: true here !!! underlying objects to complex
     * which leeds to to many iterations and crash
     * @param newArray
     */
    poiMarkerArray(newArray) {
      if (!this.map.hasLayer(this.poiClusterLayerGroup)) {
        this.map.addLayer(this.poiClusterLayerGroup);
      }
      // hasLayer() check does not work for some reason, so we are forced to clear all layers
      // and add them eiegn
      this.poiClusterLayerGroup.clearLayers();
      this.poiClusterLayerGroup.addLayers(newArray);
    },
  },
  created() {
    // non reactive variable declaration
    this.map = null;
    this.marker = null;
    this.defaultZoom = 10;
    this.poiClusterLayerGroup = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: true,
      disableClusteringAtZoom: 16,
    });
    this.markerTypes = {
      new_poi: {
        icon: 'fa-asterisk',
        markerColor: 'rgba(0, 255, 0, 0.3)',
        shape: 'circle',
        prefix: 'fa',
      },
      reference_position: {
        icon: 'fa-user',
        markerColor: 'blue',
        shape: 'circle',
        prefix: 'fa',
      },
      'category:landmarked_building': {
        icon: 'fa-landmark',
        markerColor: 'red',
        shape: 'square',
        prefix: 'fa',
      },
      'category:landmark': {
        icon: 'fa-mountain',
        markerColor: 'green',
        shape: 'square',
        prefix: 'fa',
      },
      'category:culture': {
        icon: 'fa-theater-masks',
        markerColor: 'cyan',
        shape: 'square',
        prefix: 'fa',
      },
      'category:public_utility_object': {
        icon: 'fa-hotel',
        markerColor: 'orange',
        shape: 'square',
        prefix: 'fa',
      },
      'category:other': {
        icon: 'fa-asterisk',
        markerColor: 'yellow',
        shape: 'square',
        prefix: 'fa',
      },
    };
    this.newPoiMarker = null;
    this.rectangularSectorsBoundariesLatLng = [];
    this.mainPointPosition = null;

    // @see https://alligator.io/vuejs/global-event-bus/
    EventBus.$on('trigger-poi-edit', (poiItem) => {
      this.openEditPoiForm(poiItem);
    });

    // @see https://alligator.io/vuejs/global-event-bus/
    EventBus.$on('trigger-poi-delete', (poiItem) => {
      this.deletePoiForm(poiItem);
    });
  },

  mounted() {
    const self = this;

    if (!(this.refCoordinates.longitude > '' && this.refCoordinates.latitude > '')) {
      // use current location or system fallback location
      generalUtil.browserGeolocation().done((coordinates) => {
        if (typeof coordinates !== 'undefined') {
          self.refCoordinates.latitude = coordinates.lat;
          self.refCoordinates.longitude = coordinates.lng;
        }
      }).fail(() => {
        self.refCoordinates.latitude = globalConfig.fallbackCoordinates.latitude;
        self.refCoordinates.longitude = globalConfig.fallbackCoordinates.longitude;
      }).always(() => {
        self.init();
      });
    } else {
      this.init();
    }
  },

  methods: {
    init() {
      const self = this;

      /**
       * init leaflet map
       */
      this.initializeMap().done(() => {
        self.mapInitialized = true;
        self.renderMarker();
        self.initializeRectangularClusteringSectors();
        self.populatePoiLayerGroups();
        self.drawAdminHelperGrid();
      }).fail((error) => {
        console.error(`Leaflet Map could not be initialized: ${error}`);
      });
    },

    trans(key, params, domain) {
      return Translator.trans(key, params || {}, domain || 'messages');
    },

    initializeMap(config) {
      // this reference for callback functions
      const self = this;
      const def = $.Deferred();

      // check api
      if (!this.apiAvailable) {
        def.reject('No API available');
        return def;
      }

      const latLng = L.latLng(this.refCoordinates.latitude, this.refCoordinates.longitude);

      this.map = L.map('map-canvas', {
        zoomControl: false,
        contextmenu: true,
        contextmenuWidth: 220,
        contextmenuItems: [{
          text: `<button type="button" class="btn btn-primary btn-sm">${this.trans('poi.manager.propose_poi_here')}</button>`,
          callback: self.contextMenuCallback_newPoiOnMapLocation,
        }],
      }).setView(latLng, this.defaultZoom);

      // context menu event listener
      self.map.on('contextmenu.show', (e) => {
        self.renderNewPoiMarker(e.contextmenu._showLocation.latlng);
      });
      self.map.on('contextmenu.hide', (e) => {
        self.newPoiMarker.removeFrom(self.map);
      });

      self.map.on('mouseover', (e) => {

      });

      L.Control.Textbox = L.Control.extend({
        onAdd(map) {
          const text = L.DomUtil.create('div');
          text.id = 'map-infotext';
          text.innerHTML = `<div class="leaflet-control__map-infotext">${self.trans('poi.manager.click_anywhere_in_the_map_to_add_poi')}</div>`;
          return text;
        },
        onRemove(map) {
          // Nothing to do here
        },
      });
      L.control.textbox = (opts) => new L.Control.Textbox(opts);
      L.control.textbox({position: 'topleft'}).addTo(self.map);

      // show context menu additionally on left click
      this.map.on('click', (e) => {
        self.map.contextmenu.showAt(e.latlng);
      });

      // add zoom control
      new L.Control.Zoom({position: 'bottomleft'}).addTo(this.map);

      this.mainPointPosition = latLng;

      /**
       * !!! DO NOT use any other mapping provider then OpenStreetMap !!!
       * We use coordinates derived from the map to store them along our POI records
       * in our own database to build a POI register
       * This is not allowed with most of the map providers but OpenStreetMap !!!
       */
      const osmAttribution = 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, '
        + '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, '
        + 'Imagery © <a href="https://www.openstreetmap.org/">OpenStreetMap</a>';

      const layerStreets = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        minZoom: 4,
        maxZoom: 18,
        attribution: osmAttribution,
        id: 'osm.streets',
      });

      // base layer
      this.map.addLayer(layerStreets);

      if (this.isEditorSession) {
        // add satellite map if our editor is logged in
        this.initializeMapSatelliteLayer(layerStreets);
      }

      // add a scale to the map
      L.control.scale({position: 'bottomright'}).addTo(this.map);

      // add fullscreen control to the map
      L.control.fullscreen({
        position: 'bottomleft',
        content: null, // change the content of the button, can be HTML, default null
        forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
        // very important to set the fullscreenElement to the outer container of this component
        // in order to see the modals and swal notifications while in map fullscreen
        // see also other comments in this file related to 'map fullscreen'
        fullscreenElement: this.$refs['poi-location-manage'],
      }).addTo(this.map);

      this.map.on('zoomstart', (e) => {
        if (!this.minZoomLevel) {
          this.minZoomLevel = this.map.getZoom();
        }
        this.zoomStartLevel = this.map.getZoom();
      });

      this.map.on('zoomend', (e) => {
        if (this.map.getZoom() < this.zoomStartLevel) {
          if (this.map.getZoom() < this.minZoomLevel) {
            this.minZoomLevel = Math.min(this.minZoomLevel, this.map.getZoom());
            this.populatePoiLayerGroups();
          }
        }
      });

      this.map.on('dragend', (e) => {
        this.populatePoiLayerGroups();
      });

      def.resolve();

      return def;
    },

    /**
     * provide satellite view only for our internal workers
     * because of licensing reasons.
     * Most mapping imaginery providers terms of use do not allow to save coordinates derived from their maps
     * Only OpenStreetMap / ESRI, but they look rather ugly
     * @param layerStreets
     */
    initializeMapSatelliteLayer(layerStreets) {
      if (!this.isEditorSession) {
        return false;
      }
      /**
       * !NEW! mapbox style tiles
       * use raster tiles from mapbox-gl styles instead: https://docs.mapbox.com/accounts/overview/pricing/#raster-tiles-from-mapbox-gl-styles
       * !!! only for reference here - do not use in production context - use http://www.esri.com instead !!!
       * !!! mapbox terms of use does not allow to store coordinates derived from their maping resources
       */
      // L.mapbox.accessToken = this.mapboxApiKey;
      // const mapboxAttribution = 'Map data & Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>';
      // const layerSatellite = L.mapbox.styleLayer('mapbox://styles/mapbox/satellite-streets-v11', {
      //   minZoom: 4,
      //   maxZoom: 18,
      //   attribution: mapboxAttribution,
      //   id: 'mapbox.satellite',
      // });

      // https://www.esri.com/arcgis-blog/products/constituent-engagement/constituent-engagement/esri-world-imagery-in-openstreetmap/
      const mapLink = '<a href="http://www.esri.com/">Esri</a>';
      const wholink = 'i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';
      const layerSatellite = L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          attribution: `&copy; ${mapLink}, ${wholink}`,
        }).addTo(this.map);

      const switchableLayers = {
        Satelitarna: layerSatellite,
        Topograficzna: layerStreets,
      };
      const overlays = {};
      L.control.layers(switchableLayers, overlays, {
        position: 'bottomleft',
      }).addTo(this.map);
      return true;
    },

    /**
     * show marker
     * @param latLng
     */
    renderMarker(latLng) {
      // this reference for callback functions
      const self = this;
      let pos = latLng;

      // check api
      if (!this.apiAvailable) {
        return false;
      }

      if (typeof pos === 'undefined') {
        pos = this.map.getCenter();
      }

      // init main marker
      if (this.marker == null) {
        this.marker = L.marker(pos, {
          icon: L.ExtraMarkers.icon(self.markerTypes['reference_position']),
        }).addTo(this.map);
      } else {
        this.marker.setLatLng(pos);
      }
      return true;
    },

    initializeRectangularClusteringSectors() {
      // width and height of the sectors in leaflet map points
      // see map.latLngToLayerPoint() and map.layerPointToLatLng()
      const xinc = 140;
      const yinc = 140;

      // draw the chosen feature on the map and return the JSON feature
      const areaFeature = this._rectangularSectorsSetArea(polandWojewodztwa, this.currentAreaFeatureName, true);
      // derive array of boundaries for adjestent sectors which touch our general area
      this.rectangularSectorsBoundariesLatLng = this._rectangularSectorsDeriveSectors(areaFeature, xinc, yinc);
    },

    renderNewPoiMarker(latLng) {
      if (this.newPoiMarker == null) {
        this.newPoiMarker = L.marker(
          latLng,
          {icon: L.ExtraMarkers.icon(this.markerTypes['new_poi'])},
        );
        this.newPoiMarker.addTo(this.map);
      } else {
        if (!this.map.hasLayer(this.newPoiMarker)) {
          this.newPoiMarker.addTo(this.map);
        }
        this.newPoiMarker.setLatLng(latLng);
      }
    },

    // generate marker popup
    // see here for a better solution with vue powers
    // https://stackoverflow.com/questions/56696928/how-can-i-integrate-a-vue-component-in-a-leaflet-popup/56699952
    defineAndBindMarkerPopup(poiItem, marker) {
      // Here is how I render the marker popup content on the fly
      // using custom vue component, which encapulates the template and
      // business logic to handle gui elements included in the popup
      if (poiItem.description > '' && poiItem.description !== '...') {
        const vm = new Vue({
          components: {
            LMarkerPoiPopup,
          },
          props: ['poiItem'],
          propsData: {
            poiItem: poiItem,
          },
          template: '<l-marker-poi-popup :poi-item="poiItem" />',
        }).$mount(document.createElement('DIV'));
        // Now we bind the vue vm.$el directly as popup, not e.g its vm.$el.innerHtml which would defeat the interactivity of the vue components inside
        marker.bindPopup(vm.$el, {
          maxWidth: 500,
        });
      }
      let tooltipContent = '';
      if (poiItem.category) {
        const categoryTranslated = this.trans(`category.${poiItem.category}`);
        tooltipContent = `<div class="text-center"><p class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">${poiItem.name}</p><span class="text-muted font-weight-bold">${categoryTranslated}</span>`
      } else {
        tooltipContent = `<div class="text-center"><p class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">${poiItem.name}</p>`;
      }
      if (poiItem.description > '' && poiItem.description !== '...') {
        tooltipContent += '<br>Kliknij ikonkę, aby dowiedzieć się więcej.';
      }
      tooltipContent += '</div>';
      const tooltip = marker.bindTooltip(
        tooltipContent,
        {
          direction: 'top',
          offset: new L.Point(0, -40),
        },
      );
      marker.on('click', () => {
        tooltip.closeTooltip();
      });
    },

    makePoiIconConfig(poiEntry) {
      let iconConfig = {};
      if (poiEntry.category) {
        iconConfig = this.markerTypes[`category:${poiEntry.category}`];
      } else {
        iconConfig = this.markerTypes['category:other'];
      }
      if (poiEntry.review_status === 'review') {
        iconConfig['icon'] = 'far fa-check-circle';
        iconConfig['markerColor'] += ' extra-marker-review';
        iconConfig['shape'] = 'penta';
      }
      return iconConfig;
    },

    /**
     *
     */
    populatePoiLayerGroups() {
      const self = this;
      const def = $.Deferred();

      guiUtil.blockUI('#poi-location-manage');
      this.loadPois(
        this.getMapRadiusKM(),
        this.map.getCenter().lng,
        this.map.getCenter().lat,
      ).done((results) => {
        def.resolve(results);
      }).fail((error) => {
        def.reject();
        console.error(error);
      }).always(() => {
        def.reject();
        guiUtil.unblockUI('#poi-location-manage');
      });

      $.when(def).then((data) => {
        // fetch markers
        let results = data;

        if (data.ciphertext) {
          // the POI data is crypted to secure it from naive scraping
          const password = '5NmhVXqSro6j9eyN';
          results = JSON.parse(securityUtil.phpAesDecrypt(password, results));
        }

        for (const poiData of results) {
          const poiEntry = new PoiEntry(poiData);
          const iconConfig = self.makePoiIconConfig(poiEntry);
          const marker = L.marker(
            [poiEntry.coordinates.latitude, poiEntry.coordinates.longitude],
            {icon: L.ExtraMarkers.icon(iconConfig)},
          );

          self.defineAndBindMarkerPopup(poiEntry, marker);

          marker.on('popupopen', function onPopupopen(e) {
            self.currentPoiItem = this;
          }, poiEntry);

          marker.on('popupclose', (e) => {
            self.currentPoiItem = null;
          });

          if (!this.helperMarkerInLayerGroup(marker)) {
            self.poiMarkerArray.push(marker);
          }
        }
      });
    },

    /**
     * load POI entries
     * @param radiusKm
     * @param lng
     * @param lat
     * @returns {jQuery|*|{}}
     */
    loadPois(radiusKm, lng, lat) {
      const self = this;
      const deferred = $.Deferred();
      const url = JsRouting.generate('api_poi_entry_cget', {
        longitude: lng,
        latitude: lat,
        radiusKm: radiusKm,
        user: securityUtil.getAuthenticatedUserHash(),
      });
      Axios.get(url).then((response) => {
        deferred.resolve(response.data);
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
        });
        deferred.reject(error);
      });
      return deferred;
    },

    contextMenuCallback_newPoiOnMapLocation(e) {
      this.openNewPoiForm(e.latlng);
    },

    openNewPoiForm(latLng) {
      this.newPoiCoordinates = JSON.stringify({
        lat: latLng.lat,
        lng: latLng.lng,
      });
      this.$refs['modal-poi-add'].show();
    },

    /**
     * Helper function to detect if a markes already in the marker layer group
     * ! This is a workaround because for some reason self.poiClusterLayerGroup.hasLayer()
     * does not work as expected !
     * So we use an intermediate reactive variable this.poiMarkerArray to manage the markers
     * and update the layer group in the watcher of that variable!
     */
    helperMarkerInLayerGroup(marker) {
      // A way to check if an object already exists in an array
      // all other methods like includes(), indexOf(), _.includes()
      // do not seam to work reliably with objects
      const isFound = this.poiMarkerArray.some(obj => (
        obj._latlng.lat === marker._latlng.lat && obj._latlng.lng === marker._latlng.lng
      ));
      return isFound;
    },

    submitPoiNewModalForm() {
      this.$refs['poi-item-form'].$emit('submit-form');
    },

    onNewFormSubmitSuccess(data) {
      const self = this;
      let iconConfig = null;
      const poiEntry = new PoiEntry(data);
      iconConfig = self.makePoiIconConfig(poiEntry);
      // now replace the marker in the layer group using new data
      const newMarker = L.marker(
        [data.coordinates.latitude, data.coordinates.longitude],
        {icon: L.ExtraMarkers.icon(iconConfig)},
      );
      self.defineAndBindMarkerPopup(poiEntry, newMarker);
      if (!this.helperMarkerInLayerGroup(newMarker)) {
        this.poiMarkerArray.push(newMarker);
      }
      window.swal.fire({
        title: Translator.trans('poi.user_created',
          {},
          'AppProduct',
        ),
        text: Translator.trans('poi.user_created.info',
          {},
          'AppProduct',
        ),
        type: 'success',
        // very important to set this target otherwise we won't see this swal while in fullscreen
        // see also other comments in this file related to 'map fullscreen'
        target: this.$refs['poi-location-manage'],
        confirmButtonClass: 'btn btn-secondary',
      }).then((result) => {

      });
    },

    onNewFormSubmitFailure(error) {
      window.swal.fire({
        title: Translator.trans('gui.error_occured'),
        text: error.message,
        type: 'error',
        // very important to set this target otherwise we won't see this swal while in fullscreen
        // see also other comments in this file related to 'map fullscreen'
        target: this.$refs['poi-location-manage'],
        confirmButtonClass: 'btn btn-secondary',
      });
    },

    openEditPoiForm(poiItem) {
      this.newPoiCoordinates = JSON.stringify({
        lat: poiItem.coordinates.lat,
        lng: poiItem.coordinates.lng,
      });
      this.currentPoiItem = poiItem;
      Vue.nextTick(() => {
        this.$refs['modal-poi-edit'].show();
      });
    },

    submitPoiEditModalForm() {
      this.$refs['poi-item-form'].$emit('submit-form');
    },

    onEditFormSubmitSuccess(data) {
      const self = this;
      let iconConfig = null;
      const poiEntry = new PoiEntry(data);
      iconConfig = self.makePoiIconConfig(poiEntry);
      // now replace the marker in the layer group using new data
      const newMarker = L.marker(
        [data.coordinates.latitude, data.coordinates.longitude],
        {icon: L.ExtraMarkers.icon(iconConfig)},
      );
      self.defineAndBindMarkerPopup(poiEntry, newMarker);
      if (!this.helperMarkerInLayerGroup(newMarker)) {
        // add the new marker
        this.poiMarkerArray.push(newMarker);
      } else {
        // replace the old marker with teh updated one
        const poiMarkerArrayBuffer = [];
        for (const [ind, existingMarker] of this.poiMarkerArray.entries()) {
          if (existingMarker._latlng.lat === newMarker._latlng.lat
            && existingMarker._latlng.lng === newMarker._latlng.lng) {
            poiMarkerArrayBuffer.push(newMarker);
          } else {
            poiMarkerArrayBuffer.push(existingMarker);
          }
        }
        // now replace the entire array which will trigger the watcher to rebuild the markerLayerGroup
        this.poiMarkerArray = poiMarkerArrayBuffer;
      }
      window.swal.fire({
        title: Translator.trans('poi.user_updated',
          {},
          'AppProduct',
        ),
        text: Translator.trans('poi.user_updated.info',
          {},
          'AppProduct',
        ),
        type: 'success',
        // very important to set this target otherwise we won't see this swal while in fullscreen
        // see also other comments in this file related to 'map fullscreen'
        target: this.$refs['poi-location-manage'],
        confirmButtonClass: 'btn btn-secondary',
      }).then((result) => {

      });
    },

    onEditFormSubmitFailure(error) {
      window.swal.fire({
        title: Translator.trans('gui.error_occured'),
        text: error.message,
        type: 'error',
        // very important to set this target otherwise we won't see this swal while in fullscreen
        // see also other comments in this file related to 'map fullscreen'
        target: this.$refs['poi-location-manage'],
        confirmButtonClass: 'btn btn-secondary',
      });
    },

    /**
     * Draw a helper grid for working on larger areas.
     * We draw a grid (for admins only) to work systematically on defined areas
     * looking for POIs to geotag
     */
    drawAdminHelperGrid() {
      const self = this;

      // when current user is not an admin, do not draw the grid
      if (!this.isEditorSession) {
        return false;
      }
      // draw the grid for that sectors
      const gridLayerGroup = L.layerGroup();
      const labelsLayerGroup = L.layerGroup();
      this._rectangularSectorsDrawGrid(this.rectangularSectorsBoundariesLatLng, gridLayerGroup, labelsLayerGroup);

      this.map.addLayer(gridLayerGroup);
      this.map.addLayer(labelsLayerGroup);

      // remove the labels for large zoom levels
      this.map.on('zoomend', () => {
        if (self.map.getZoom() < 9) {
          if (self.map.hasLayer(labelsLayerGroup) === true) {
            self.map.removeLayer(labelsLayerGroup);
          }
          if (self.map.hasLayer(gridLayerGroup) === true) {
            self.map.removeLayer(gridLayerGroup);
          }
        } else if (self.map.hasLayer(labelsLayerGroup) === false) {
          self.map.addLayer(labelsLayerGroup);
          self.map.addLayer(gridLayerGroup);
        }
      });
      return true;
    },

    /**
     * Delete POI item
     */
    deletePoiForm(poiItem) {
      // TODO
    },

    /**
     * @param geoJsonData
     * @param featureName
     * @param drawOutline
     * @returns {null}
     * @private
     */
    _rectangularSectorsSetArea(geoJsonData, featureName, drawOutline) {
      // draw the chosen feature on the map
      const geojsonPoland = L.geoJSON(geoJsonData, {
        filter: (feature, layer) => feature.properties.name === featureName,
        style: {
          color: 'green',
          weight: 4,
          opacity: 0.5,
          fill: false,
        },
      });

      if (drawOutline) {
        geojsonPoland.addTo(this.map);
      }

      // select the feature polygon to use for checking
      // if a search quadrant to be drawn touches this polygon
      let areaFeature = null;
      geojsonPoland.eachLayer((layer) => {
        if (layer.feature.properties.name === featureName) {
          areaFeature = layer.feature;
        }
      });

      return areaFeature;
    },

    /**
     * @param areaFeature
     * @param sectorWidth
     * @param sectorHeight
     * @returns {[]}
     * @private
     */
    _rectangularSectorsDeriveSectors(areaFeature, sectorWidth, sectorHeight) {
      const self = this;

      // determine area boundaries in leaflet map points
      const feature = L.geoJson(areaFeature);

      // boundary
      const geoJsonFeatureBoundary = feature.getBounds();
      const xmin = this.map.latLngToLayerPoint(geoJsonFeatureBoundary.getNorthWest()).x;
      const ymin = this.map.latLngToLayerPoint(geoJsonFeatureBoundary.getNorthWest()).y;
      const xmax = this.map.latLngToLayerPoint(geoJsonFeatureBoundary.getSouthEast()).x;
      const ymax = this.map.latLngToLayerPoint(geoJsonFeatureBoundary.getSouthEast()).y;

      // iteration variables
      let x = 0;
      let y = 0;
      let p1 = null;
      let p2 = null;
      let rect = null;
      let xcnt = 1;
      let ycnt = 1;
      const sectors = [];
      const geoJsonFeatureId = areaFeature.properties.ID_1;
      const checkPolygon = turf.polygon(areaFeature.geometry.coordinates);

      // now cycle the area and draw sectors of givven width and heights
      for (x = xmin; x <= xmax; x += sectorWidth) {
        ycnt = 1;
        for (y = ymin; y <= ymax; y += sectorHeight) {
          p1 = this.map.layerPointToLatLng(new L.Point(x, y));
          p2 = this.map.layerPointToLatLng(new L.Point(x + sectorWidth, y + sectorHeight));
          rect = new L.Rectangle([p1, p2], {color: 'red', weight: 1, fill: 0 });
          if (this.rectangleTouchesPolygon(rect, checkPolygon)) {
            sectors.push({
              upperLeft: this.map.layerPointToLatLng(new L.Point(x + sectorWidth, y + sectorHeight)),
              lowerLeft: this.map.layerPointToLatLng(new L.Point(x + sectorWidth, y)),
              upperRight: this.map.layerPointToLatLng(new L.Point(x, y + sectorHeight)),
              lowerRight: this.map.layerPointToLatLng(new L.Point(x, y)),
              identifier: `${geoJsonFeatureId}-${ycnt}-${xcnt}`,
            });
          }
          ycnt += 1;
        }
        xcnt += 1;
      }
      return sectors;
    },

    /**
     * @param sectors
     * @param gridLayerGroup
     * @param labelsLayerGroup
     * @private
     */
    _rectangularSectorsDrawGrid(sectors, gridLayerGroup, labelsLayerGroup) {
      const self = this;

      // iteration variables
      let p1 = null;
      let p2 = null;
      let rect = null;

      // draw the quadrants which touch the drawn polygon
      let labelText = '';
      let marker = null;
      let rectBounds = null;
      // now cycle the area and draw sectors of givven width and heights
      for (const sector of sectors) {
        p1 = sector.lowerRight;
        p2 = sector.upperLeft;
        rect = new L.Rectangle([p1, p2], {color: 'red', weight: 2, fill: 0 });
        rectBounds = rect.getBounds();
        labelText = sector.identifier;
        gridLayerGroup.addLayer(rect);
        marker = this.createTextMarker(labelText, p1).on('click', function(e) {
          self.map.fitBounds(this);
        }, rectBounds);
        labelsLayerGroup.addLayer(marker);
      }
    },

    /**
     * @param rect
     * @param checkPolygon
     * @returns {boolean}
     */
    rectangleTouchesPolygon(rect, checkPolygon) {
      let x = 0;
      let y = 0;
      let checkPoint = null;
      let isInPolygon = false;
      const bounds = rect.getBounds();
      const xsw = this.map.latLngToLayerPoint(bounds._southWest).x;
      const ysw = this.map.latLngToLayerPoint(bounds._southWest).y;
      const xne = this.map.latLngToLayerPoint(bounds._northEast).x;
      const yne = this.map.latLngToLayerPoint(bounds._northEast).y;

      const xinc = xne - xsw;
      const yinc = ysw - yne;

      x = xsw;
      y = yne;

      const checkPoints = [
        new L.Point(x, y),
        new L.Point(x + xinc, y + yinc),
        new L.Point(x + xinc, y),
        new L.Point(x, y + yinc),
        new L.Point(x + xinc / 2, y),
        new L.Point(x + xinc, y + yinc / 2),
        new L.Point(x + xinc / 2, y + yinc),
        new L.Point(x, y + yinc / 2),
      ];

      for (const point of checkPoints) {
        checkPoint = this.map.layerPointToLatLng(point);
        isInPolygon = isInPolygon || booleanPointInPolygon(turf.point([checkPoint.lng, checkPoint.lat]), checkPolygon);
      }
      return isInPolygon;
    },

    /**
     * @returns {number}
     */
    getMapRadiusKM() {
      const mapBoundNorthEast = this.map.getBounds().getNorthEast();
      const mapDistance = mapBoundNorthEast.distanceTo(this.map.getCenter());
      return Math.round(mapDistance / 1000);
    },

    /**
     * @param text
     * @param latLng
     * @returns {*}
     */
    createTextMarker(text, latLng) {
      const marker = L.marker(latLng, {
        icon: L.divIcon({
          html: text,
          className: 'plain-text-marker',
        }),
      });
      return marker;
    },
  },
};

</script>

<style lang="scss" scoped>
.fullscreen-icon {
  background-position: 0 26px;
}

#poi-location-manage {
  #map-canvas {
    height: 1124px;
  }
  &:fullscreen {
    #map-canvas {
      height: 100% !important;
    }
  }
}
</style>
