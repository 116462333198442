import Axios from 'axios';

const axiosDefaults = require('axios/lib/defaults');
import JsRouting from 'fos-jsrouting-bundle';
import HttpUtil from 'js/app/utils/http';

class XsrfUtils {
  constructor() {
    this.tokens = [];
  }

  /**
   * Fetch CSRF token from a special API endpoint if needed
   * @param csrfTokenKey
   * @param refresh get a fresh token even if already fetched before and stored
   * @returns {*}
   */
  fetchCsrfToken(csrfTokenKey, refresh) {
    let self = this;
    let def = $.Deferred();
    refresh = refresh || false;
    csrfTokenKey = csrfTokenKey || 'global';

    function tokenPresent(_tokenKey) {
      // hack begin: there are some issues with this reuse of tokens !!!
      // so do not use this feature unless carefully tested for perfect function !!!
      return null;
      // hack end
      let _result = null;
      for (const _tokenIndex in self.tokens) {
        if (self.tokens[_tokenIndex].key === _tokenKey) {
          _result = self.tokens[_tokenIndex].value;
          break;
        }
      }
      return _result;
    }

    const existingToken = tokenPresent(csrfTokenKey);

    if (existingToken && refresh === false) {
      def.resolve(existingToken);
    } else {
      // get fresh CSRF token
      const csrfTokenUrl = JsRouting.generate('webapi_csrftoken', {csrfTokenKey: (csrfTokenKey === 'global' ? null : csrfTokenKey)}, true);
      Axios.get(csrfTokenUrl)
        .then((response) => {
          if (response.data.value > '') {
            self.tokens.push({
              key: csrfTokenKey,
              value: response.data.value,
            });
            def.resolve(response.data.value);
          } else {
            def.reject();
          }
        })
        .catch((error) => {
          HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
          });
          def.resolve();
        });
    }

    return def;
  }

  /**
   *
   */
  initXsrfCookies() {
    /**
     * XSRF token for dunglas/angular-csrf-bundle
     */
    let xsrfCookie;
    xsrfCookie = new RegExp('(?:^|; )' + encodeURIComponent('XSRF-TOKEN') + '=([^;]*)').exec(document.cookie);
    if (xsrfCookie) {
      // set global default for $.ajax calls
      $.ajaxSetup({
        headers: {
          'X-XSRF-TOKEN': xsrfCookie[1]
        }
      });
      // set global default for Axios calls
      Axios.defaults.headers.common = {
        "X-XSRF-TOKEN": xsrfCookie[1]
      };
    }

  }

}

const xsrfUtils = new XsrfUtils();

export default xsrfUtils;
