<template>
  <!--begin: Notifications -->
  <div class="dropdown">
    <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" role="button">
      <div class="btn btn-icon btn-clean btn-dropdown btn-lg" :class="{'pulse pulse-primary': attention}">
        <span class="svg-icon svg-icon-xs" :class="{'svg-icon-success': !attention, 'svg-icon-primary': attention}">
            <svg id="bell" xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 6.943 7.5">
              <g id="Group_346" data-name="Group 346" transform="translate(0)">
                <g id="Group_345" data-name="Group 345">
                  <path id="Path_534" data-name="Path 534" d="M22.595,4.521V2.753a2.748,2.748,0,1,0-5.5,0V4.521l-.714,1.411h6.943Z" transform="translate(-16.384)" fill="#76ba40" />
                </g>
              </g>
              <g id="Group_348" data-name="Group 348" transform="translate(2.3 6.341)">
                <g id="Group_347" data-name="Group 347">
                  <path id="Path_535" data-name="Path 535" d="M151.552,372.736a1.159,1.159,0,0,0,2.317,0Z" transform="translate(-151.552 -372.736)" fill="#76ba40" />
                </g>
              </g>
            </svg>
        </span>
        <span v-if="attention" class="pulse-ring"></span>
      </div>
    </div>
    <div class="dropdown-menu dropdown-menu-left dropdown-menu-xl py-5 kt-notification">
      <!--div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ 'notification.user_notifications'|trans }}
            <span v-if="unreadCount > 0" class="label label-sm label-danger">
              {{ unreadCount }} {{ 'notification.status.unread'|trans }}</span>
          </h3>
        </div>
      </div-->
      <div v-if="notifications.models.length === 0" class="navi">
        <div class="navi-item">
          <span class="navi-link">{{ 'notification.no_new_notifications'|trans }}</span>
        </div>
      </div>
      <ul v-if="notifications.models.length > 0" class="navi navi-hover navi-icon-center navi-active"
          data-scroll="true"
          data-height="300"
          data-mobile-height="200">
        <li class="navi-item kt-notification__item" v-for="notification in notifications.models" :key="notification.id">
          <a
            class="navi-link"
            :class="{'read': notification.is_read, 'unread': !notification.is_read}"
            href="javascript:;"
            @click="function(e) {return itemClicked(notification, e)}"
          >
            <span class="navi-icon text-center">
              <i :class="getNotificationIcon(notification)"></i>
            </span>
            <div class="navi-text">
              <span
                class="d-block"
                v-html="getNotificationTitle(notification)"
              >---</span>
              <span class="text-muted">{{ notification.timestamp | age }}&nbsp;</span>
              <span v-for="(action, index) in getActions(notification)" :key="index"
                    @click="actionClick(action, notification)"
                    :class="'btn btn-label-'+action.css_class">{{ ('notification.action.' + index)|trans }}</span>
            </div>
            <span v-if="!notification.is_read" class="navi-label">
              <span class="label label-light-danger font-weight-bolder label-inline">{{ 'dict.new'|trans }}</span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!--end: Notifications -->
</template>

<script>
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import Notification from 'js/app/models/Notification';
import NotificationCollection from 'js/app/models/NotificationCollection';
import JsRouting from 'fos-jsrouting-bundle';
import Axios from 'axios';
import securityUtil from 'js/app/utils/security';
import globalConfig from 'js/app/config/app';
import guiUtil from 'js/app/utils/gui';
import HttpUtil from 'js/app/utils/http';
import globalState from '../store/GlobalStore.js';

export default {
  name: 'TopbarUserNotifications',
  data() {
    return {
      notifications: new NotificationCollection(),
      lastEventId: null,
      jwtToken: '-empty-',
      useAuthorizationHeader: true,
    };
  },
  computed: {
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    },
    unreadCount() {
      let count = 0;
      $.each(this.notifications.models, (key, val) => {
        if (val && !val.is_read) count++;
      });
      return count;
    },
    attention() {
      return this.unreadCount > 0;
    },
  },
  watch: {},
  created() {
  },
  mounted() {
    try {
      this.init();
    } catch (error) {
      throw new Error(error);
    }
  },
  beforeDestroy() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  },
  methods: {
    init() {
      const self = this;

      if (this.isAuthenticated) {
        this.mercureSubscribe();
        this.fetchNotifications().done(() => {
          this.$nextTick(() => {
            this.initScroll();
          });
        });
      }
    },

    mercureSubscribe() {
      const self = this;
      let updateEventSource;
      this.jwtToken = HttpUtil.getCookie('mercureAuthorization');

      if (updateEventSource) {
        updateEventSource.close();
      }
      // subscribe for mercure events
      const topic = `zamiastowi.pl/notifications_${securityUtil.getAuthenticatedUserHash()}`;
      const esUri = new URL(globalConfig.mercureHub);
      esUri.searchParams.append('topic', topic);
      if (self.lastEventId) {
        esUri.searchParams.append('Last-Event-ID', self.lastEventId);
      }

      if (this.useAuthorizationHeader && this.jwtToken) {
        updateEventSource = new EventSourcePolyfill(esUri, {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          },
        });
      } else {
        updateEventSource = new EventSource(esUri, {
          withCredentials: true,
        });
      }

      updateEventSource.onmessage = function (e) {
        // Will be called every time an update is published by the mercure server
        self.lastEventId = e.lastEventId;
        console.log(`Notifications update! LastEventId=${self.lastEventId}`);
        self.fetchNotifications().done(() => {
          this.initScroll();
        });
      };
      updateEventSource.onerror = console.log;
      updateEventSource.ontimeout = function (e) {

      };
    },

    initPlugins() {
    },

    initScroll() {
      $(this.$el).find('[data-scroll="true"]').each(function() {
        var el = $(this);
        KTUtil.scrollInit(this, {
          mobileNativeScroll: true,
          handleWindowResize: true,
          rememberPosition: (el.data('remember-position') == 'true' ? true : false),
        });
      });
    },

    trans(key, domain, params) {
      return Translator.trans(key, params || {}, domain || 'messages');
    },

    /**
     *
     */
    itemClicked(notification, e) {
      const targetEl = e.target;
      const aWrapper = $(targetEl).closest('.kt-notification__item');

      if (e.target.nodeName === 'DIV' || e.target.nodeName === 'SPAN' || e.target.nodeName === 'I' || e.target.nodeName === 'A') {
        guiUtil.blockUI(aWrapper);
        this.markNotificationAsRead(notification).done(() => {
        }).fail(() => {
        }).always(() => {
          guiUtil.unblockUI(aWrapper);
        });
      } else {
        console.log('child clicked ' + targetEl);
      }
      if (typeof notification.url !== 'undefined' && notification.url > '') {
        const url = notification.url;
        // delete this notification from local storage
        //this.notifications = this.localStorageDelete(notification);
        document.location.replace(url);
        return false;
      } else if (typeof notification.data.url !== 'undefined' && notification.data.url > '') {
        const url = notification.data.url;
        // delete this notification from local storage
        //this.notifications = this.localStorageDelete(notification);
        document.location.replace(url);
        return false;
      }
    },

    /**
     * Fetch new notifications from remote
     */
    fetchNotifications() {
      const def = $.Deferred();
      const self = this;
      const url = JsRouting.generate('webapi_user_notifications_cget', {
        limit: 10,
        offset: 0,
        unreadOnly: 0
      }, true);
      // Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
      // Axios.defaults.withCredentials = true;
      // Axios.defaults.credentials = 'same-origin';
      Axios.get(url).then((response) => {
        if (response.data) {
          let col = new NotificationCollection();
          $.each(response.data, function (key, val) {
            col.add(new Notification({
              'id': val.id,
              'url': val.url,
              'data': val.data,
              'title': val.title,
              'timestamp': val.created_at,
              'type': val.type,
              'is_read': val.is_read
            }));
          });
          self.notifications = col;
          if (response.data.length > 0 && self.notificationCount > 0) {
            guiUtil.notifyWindowTitle(response.data.length + ' new messages');
            guiUtil.playNotificationSound();
          }
        }
        def.resolve();
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
        });
        // do nothing
        def.reject();
      });
      return def;
    },

    markNotificationAsRead(notification) {
      const def = $.Deferred();
      const self = this;
      const url = JsRouting.generate('webapi_user_notification_mark_read', {id: notification.id}, true);
      Axios.put(url).then((response) => {
        $.each(self.notifications.models, function (key, val) {
          if (val === notification) val.is_read = true;
        });
        def.resolve();
      }).catch((error) => {
        HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
        });
        // do nothing
        def.reject();
      }).then(() => {
      });
      return def;
    },

    getNotificationTitle(notification) {
      return this.trans(notification.title, 'messages', notification.data.translation_parameters.title);
    },

    getNotificationIcon(notification) {
      let icon = 'flaticon-alert';
      switch (notification.type) {
        case 'notification.new_product_connection_request':
          icon = 'flaticon-network text-success';
          break;
        case 'notification.new_message':
          icon = 'flaticon2-email text-secondary';
          break;
        default:
          icon = 'flaticon-alert text-primary';
      }
      return icon;
    },

    // /**
    //  * Update local storage notification bag
    //  * @param newNotifications
    //  */
    // localStorageUpdate(newNotifications) {
    //
    //   // save new notifications in local storage
    //   let currentItems = JSON.parse(window.localStorage.getItem('notifications')) || {};
    //   $.each(newNotifications, function(key,val) {
    //     if (!currentItems[val.id]) {
    //       currentItems[val.id] = val;
    //     }
    //   });
    //   window.localStorage.setItem('notifications', JSON.stringify(currentItems));
    //
    //   let col = new NotificationCollection();
    //   $.each(currentItems, function(key,val) {
    //     col.add(new Notification({
    //       'id': val.id,
    //       'url': val.url,
    //       'data': val.data,
    //       'title': val.title,
    //       'timestamp': val.created_at,
    //       'type': val.type
    //     }));
    //   });
    //
    //   return col;
    // },
    //
    // /**
    //  * Delete item from local storage
    //  * @param notification
    //  */
    // localStorageDelete(notification) {
    //   let currentItems = JSON.parse(window.localStorage.getItem('notifications')) || {};
    //   delete currentItems[notification.id];
    //   window.localStorage.setItem('notifications', JSON.stringify(currentItems));
    //
    //   let col = new NotificationCollection();
    //   $.each(currentItems, function(key,val) {
    //     col.add(new Notification({
    //       'id': val.id,
    //       'url': val.url,
    //       'data': val.data,
    //       'label': val.label,
    //       'timestamp': val.created_at,
    //       'type': val.type
    //     }));
    //   });
    //
    //   return col;
    // },

    getActions(notification) {
      let actions = {};
      let cssClassMap = {
        accept: 'success',
        reject: 'danger'
      };
      if (typeof notification.data.actions !== 'undefined') {
        $.each(notification.data.actions, function (key, val) {
          actions[key] = {
            css_class: cssClassMap[key],
            url: val.url,
            ajax: val.ajax
          };
        });
      }
      return actions;
    },

    /**
     * An action button has been clicked,
     * do what it is supposed to do and mark this notification as read
     * @param action
     * @param notification
     */
    actionClick(action, notification) {
      let self = this;
      if (action.ajax) {
        guiUtil.blockUI(self.$refs['kt-notification-dropdown']);
        Axios.get(action.url).then((response) => {
          //self.notifications = this.localStorageDelete(notification);
        }).catch((error) => {
          HttpUtil.axiosErroDefaultHandler(error).done((errorString) => {
          });
          // do nothing
        }).then(() => {
          guiUtil.unblockUI(self.$refs['kt-notification-dropdown']);
        });
      } else {
        //this.notifications = this.localStorageDelete(notification);
        document.location.replace(action.url);
      }
      this.markNotificationAsRead(notification);
    }

  },
};
</script>

<style scoped>

</style>
