var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "js-slick-carousel js-slick-gallery slick slick-equal-height slick-blurex slick-blurex-cloned-opacity slick-gutters-3",
      attrs: { "data-hs-slick-carousel-options": _vm.hsSlickOptionsJson() }
    },
    [_vm._t("slide-items")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }