var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "d-flex flex-column mb-5 align-items-start": _vm.isIncoming,
        "d-flex flex-column mb-5 align-items-end": !_vm.isIncoming
      }
    },
    [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm.isIncoming
          ? _c("div", [
              _c(
                "span",
                { staticClass: "text-primary font-weight-bold font-size-h6" },
                [_vm._v(_vm._s(_vm.message.getSenderName()))]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "text-muted font-size-sm" }, [
                _vm._v(_vm._s(_vm._f("age")(_vm.message.created_at)))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isIncoming
          ? _c("div", [
              _c("span", { staticClass: "text-muted font-size-sm" }, [
                _vm._v(_vm._s(_vm._f("age")(_vm.message.created_at)))
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "text-primary font-weight-bold font-size-h6" },
                [_vm._v(_vm._s(_vm._f("trans")("dict.you")))]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "kt-chat__text mt-2 rounded p-2 px-3 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px"
        },
        [_vm._v("\n    " + _vm._s(_vm.message.body) + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }