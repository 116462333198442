<template>
  <!--begin::message-->
  <div :class="{'d-flex flex-column mb-5 align-items-start': isIncoming, 'd-flex flex-column mb-5 align-items-end': !isIncoming}">
    <div class="d-flex align-items-center">
      <div v-if="isIncoming">
        <span class="text-primary font-weight-bold font-size-h6">{{ message.getSenderName() }}</span>
        <span class="text-muted font-size-sm">{{ message.created_at | age }}</span>
      </div>
      <div v-if="!isIncoming">
        <span class="text-muted font-size-sm">{{ message.created_at | age }}</span>
        <span class="text-primary font-weight-bold font-size-h6">{{ 'dict.you' | trans }}</span>
      </div>
    </div>
    <div class="kt-chat__text mt-2 rounded p-2 px-3 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">
      {{ message.body }}
    </div>
  </div>
  <!--end::message-->
</template>

<script>
import Message from '../models/Message';

export default {
  name: 'PrivateMessageThreadEntry',
  props: {
    dir: {
      type: String,
      default: 'in', // [in, out]
    },
    message: {
      type: Object,
      default: {},
    },
  },
  computed: {
    isIncoming() {
      return this.dir === 'in';
    },
  },
};
</script>

<style scoped>

</style>
