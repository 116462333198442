var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kt-widget__menu-item" }, [
    _vm.isAuthenticated
      ? _c(
          "button",
          {
            staticClass: "btn btn-label-success btn-sm btn-upper",
            attrs: {
              type: "button",
              "data-toggle": "modal",
              "data-target": "#kt_chat_modal",
              title: ""
            }
          },
          [
            _c("i", { staticClass: "fa fa-envelope" }),
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("trans")("layout.send_user_message", {}, "messages")
                ) +
                "\n  "
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isAuthenticated
      ? _c(
          "button",
          {
            staticClass: "btn btn-label-success btn-sm btn-upper",
            attrs: { type: "button", title: "" },
            on: {
              click: function($event) {
                return _vm.showNotAuthenticatedInfo()
              }
            }
          },
          [
            _c("i", { staticClass: "fa fa-envelope" }),
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("trans")("layout.send_user_message", {}, "messages")
                ) +
                "\n  "
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "kt_chat_modal",
        staticClass: "modal fade- modal-sticky-bottom-right",
        attrs: { id: "kt_chat_modal", role: "dialog", "data-backdrop": "false" }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c(
              "div",
              { staticClass: "modal-content" },
              [
                _c("private-message-thread", {
                  attrs: {
                    "thread-id": _vm.threadId,
                    "recipient-json": _vm.recipientJson,
                    "show-close": "1",
                    "wait-for-trigger-event": "reload-private-message-thread"
                  },
                  on: { "thread-id-update": _vm.onThreadIdUpdate },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "form",
                        fn: function() {
                          return [_vm._t("form")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }