<template>
  <div class="smile-rating smile-rating--display" :class="type">
    <label class="face face-negative"
           v-if="isSelected(1)"
           :title="trans('ratings.overall.negative')"
           data-toggle="kt-popover"
           data-placement="top"
           data-html="true"
           :data-content="transMarkdown('ratings.overall.negative.desc')"
           :class="{ clicked: isSelected(1), faint: !isSelected(1) }"></label>
    <label class="face face-neutral"
           v-if="isSelected(2)"
           :title="trans('ratings.overall.neutral')"
           data-toggle="kt-popover"
           data-placement="top"
           data-html="true"
           :data-content="transMarkdown('ratings.overall.neutral.desc')"
           :class="{ clicked: isSelected(2), faint: !isSelected(2) }"></label>
    <label class="face face-positive"
           v-if="isSelected(3)"
           :title="trans('ratings.overall.positive')"
           data-toggle="kt-popover"
           data-placement="top"
           data-html="true"
           :data-content="transMarkdown('ratings.overall.positive.desc')"
           :class="{ clicked: isSelected(3), faint: !isSelected(3) }"></label>
    <div v-if="showReviewCount" class="smile-rating--display--count">
      <strong>{{ reviewCount }}</strong> {{ 'dict.review_count'|trans({}, 'messages', reviewCount) }}
    </div>
    <div ref="rating-display"></div>
  </div>
</template>

<script>
import 'css/rating.scss'
import Vue from "vue";
import Translator from "bazinga-translator";

export default {
  name: 'SmileRating',
  props: {
    label: {
      type: String,
      default: '',
    },
    rating: {
      type: Number,
      default: null,
    },
    showReviewCount: {
      type: Boolean,
      default: false,
    },
    reviewCount: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    try {
      this.init();
    } catch (error) {
      throw new Error(error);
    }
  },
  methods: {
    init() {
      this.initVendors();
    },

    trans(key, params, domain, multipleCount) {
      if (typeof multipleCount !== 'undefined') {
        return Translator.transChoice(key, multipleCount, params || {}, domain || 'AppProduct');
      } else {
        return Translator.trans(key, params || {}, domain || 'AppProduct');
      }
    },

    transMarkdown(key) {
      let tmp = this.$options.filters.trimLines(Translator.trans(key, {}, 'AppProduct'));
      return this.$options.filters.markdown(tmp);
    },

    isSelected(value) {
      return this.rating === value;
    },

    initVendors() {
      let self = this;
      // ! Do it on the next tick to be sure the DOM is being updated !
      //Vue.nextTick(() => {
      // init bootstrap tooltips
      $(self.$el).find('[data-toggle="kt-popover"]').each((key, el) => {
        const $this = $(el);
        $this.tooltip('dispose'); // dispose previous tooltip
        KTApp.initPopover($this);
      });
      //});
    },

  }
}
</script>

<style scoped>

</style>
