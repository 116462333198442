<template>
  <div v-cloak v-show="showCookieConsent"
       class="ch-cookie-consent ch-cookie-consent--light-theme ch-cookie-consent--bottom ch-cookie-consent--simplified">
    <h3 class="ch-cookie-consent__title">
      {{ 'ch_cookie_consent.title' | trans }}
    </h3>

    <p class="ch-cookie-consent__intro">
      {{ 'ch_cookie_consent.intro' | trans }}
    </p>

    <a class="ch-cookie-consent__read-more" target="_blank"
       :href="'cms.privacy' | path">{{ 'ch_cookie_consent.read_more' | trans }}</a>

    <form id="cookie_consent" name="cookie_consent" method="post" class="ch-cookie-consent__form">
      <div class="ch-cookie-consent__category-group">

        <label v-for="(category, index)  in categories" class="toggle-switch d-flex"
               :for="'cookie-consent-' + category">
          <!-- Checkbox Switch -->
          <input type="checkbox"
                 class="toggle-switch-input"
                 value="yes"
                 :name="'cookie_consent['+ category +']'"
                 :id="'cookie-consent-' + category"
                 @click="updateSettings()"
                 :checked="categoryIsChosen(category)"
          >
          <span class="toggle-switch-label">
            <span class="toggle-switch-indicator"></span>
          </span>
          <span class="toggle-switch-content">
            <span class="d-block">{{ 'ch_cookie_consent.' + category + '.title' | trans }}</span>
            <small class="d-block text-muted">{{ 'ch_cookie_consent.' + category + '.description' | trans }}</small>
          </span>
          <!-- End Checkbox Switch -->
        </label>

        <input type="hidden" id="cookie_consent__token" name="cookie_consent[_token]"
               value="m0FV6oIq0guJGP1VDIh709MjSIZNy8t1RE4oQof7pyM"/>
      </div>

      <div class="ch-cookie-consent__btn-group">
        <div v-if="!detailSettingsAvailable" class="ch-cookie-consent__btn-wrapper">
          <button @click="enableFunctionalCookiesOnly" type="button" id="cookie_consent_use_only_functional_cookies"
                  name="cookie_consent[use_only_functional_cookies]"
                  class="btn ch-cookie-consent__btn ch-cookie-consent__btn--secondary">
            {{ 'ch_cookie_consent.use_only_functional_cookies' | trans }}
          </button>
        </div>
        <div v-if="detailSettingsAvailable" class="ch-cookie-consent__btn-wrapper">
          <button @click="updateSettings" type="button" id="cookie_consent_use_selected_cookies"
                  name="cookie_consent[use_selected_cookies]"
                  class="btn ch-cookie-consent__btn ch-cookie-consent__btn--secondary">
            {{ 'ch_cookie_consent.use_selected_cookies' | trans }}
          </button>
        </div>
        <div class="ch-cookie-consent__btn-wrapper">
          <button @click="enableAllCookies" type="button" id="cookie_consent_use_all_cookies"
                  name="cookie_consent[use_all_cookies]"
                  class="btn ch-cookie-consent__btn">
            {{ 'ch_cookie_consent.use_all_cookies' | trans }}
          </button>
        </div>

        <div class="ch-cookie-consent__toggle-details">
          <span class="ch-cookie-consent__toggle-details-show">{{ 'ch_cookie_consent.show_details' | trans }}</span>
          <span class="ch-cookie-consent__toggle-details-hide">{{ 'ch_cookie_consent.hide_details' | trans }}</span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Translator from 'bazinga-translator';
import moment from 'moment';
import generalUtils from '../utils/general';
import HttpUtil from '../utils/http';
import $ from 'jquery';

export default {
  name: 'CookieConsent',
  props: {
    categories: {
      type: Array,
      default: '',
    },
  },
  data() {
    return {
      detailSettingsAvailable: false,
    };
  },
  computed: {
    showCookieConsent() {
      let cookieData = {};
      let result = true;
      const currentCookie = HttpUtil.getCookie(this.cookieName);
      if (currentCookie > '') {
        cookieData = JSON.parse(currentCookie);
        if (typeof cookieData.key !== 'undefined' && typeof cookieData.consentDate !== 'undefined') {
          result = false;
        }
      }
      return result;
    },
    chosenCategories() {
      let cookieData = {};
      let result = [];
      const currentCookie = HttpUtil.getCookie(this.cookieName);
      if (currentCookie > '') {
        cookieData = JSON.parse(currentCookie);
        if (typeof cookieData.categories === 'object') {
          result = cookieData.categories;
        }
      }
      return result;
    },
  },
  created() {
    this.cookieName = 'Cookie_Consent';
  },
  mounted() {
    try {
      if (this.showCookieConsent === true) {
        this.init();
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  methods: {
    init() {
      const self = this;
      const cookieConsent = document.querySelector('.ch-cookie-consent');
      const cookieConsentForm = document.querySelector('.ch-cookie-consent__form');
      const cookieConsentFormBtn = document.querySelectorAll('.ch-cookie-consent__btn');
      const cookieConsentCategoryDetails = document.querySelector('.ch-cookie-consent__category-group');
      const cookieConsentCategoryDetailsToggle = document.querySelector('.ch-cookie-consent__toggle-details');

      let cookieData = {};
      let result = null;
      const currentCookie = HttpUtil.getCookie(this.cookieName);
      if (currentCookie > '') {
        cookieData = JSON.parse(currentCookie);
        this.detailSettingsAvailable = typeof cookieData.categories === 'object' && cookieData.categories.length > 0;
      }

      if (cookieConsent.classList.contains('ch-cookie-consent--top')) {
        cookieConsent.style.position = 'absolute';
        cookieConsent.style.top = '0';
        cookieConsent.style.left = '0';
      } else {
        cookieConsent.style.position = 'fixed';
        cookieConsent.style.bottom = '0';
        cookieConsent.style.left = '0';
      }

      if (cookieConsentForm) {
        // Submit form via ajax
        let formData = null;
        cookieConsentFormBtn.forEach((btn) => {
          btn.addEventListener('click', (event) => {
            event.preventDefault();
            cookieConsent.style.display = 'none';
            formData = new FormData(cookieConsentForm);
            const regExp = /\[([^)]+)\]/;
            let matches = null;
            for (const pair of formData.entries()) {
              matches = regExp.exec(pair[0]);
              if (pair[1] === 'yes') {
                if (matches[1] === 'use_all_cookies') {
                  self.enableAllCookies();
                } else if (matches[1] === 'use_only_functional_cookies') {
                  self.enableFunctionalCookiesOnly();
                }
              }
            }

            // xhr.open('POST', cookieConsentForm.action);
            // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            // xhr.send(serializeForm(cookieConsentForm, event.target));
          }, false);
        });
      }

      if (cookieConsentCategoryDetails && cookieConsentCategoryDetailsToggle) {
        cookieConsentCategoryDetailsToggle.addEventListener('click', () => {
          const detailsIsHidden = cookieConsentCategoryDetails.style.display !== 'block';
          cookieConsentCategoryDetails.style.display = detailsIsHidden ? 'block' : 'none';
          cookieConsentCategoryDetailsToggle.querySelector('.ch-cookie-consent__toggle-details-hide').style.display = detailsIsHidden ? 'block' : 'none';
          cookieConsentCategoryDetailsToggle.querySelector('.ch-cookie-consent__toggle-details-show').style.display = detailsIsHidden ? 'none' : 'block';
        });
      }
    },

    trans(key, domain, params) {
      return Translator.trans(key, params || {}, domain || 'messages');
    },

    /**
     *
     * @param category
     * @returns {boolean}
     */
    categoryIsChosen(category) {
      for (const activeCategory of this.chosenCategories) {
        if (activeCategory === category) {
          return true;
        }
      }
    },

    /**
     *
     */
    updateSettings() {
      const cookieConsentForm = document.querySelector('.ch-cookie-consent__form');
      const formData = new FormData(cookieConsentForm);
      const regExp = /\[([^)]+)\]/;
      let matches = null;
      const categoriesToSet = [];
      for (const pair of formData.entries()) {
        matches = regExp.exec(pair[0]);
        if (pair[1] === 'yes' && matches[1]) {
          categoriesToSet.push(matches[1]);
        }
      }
      if (categoriesToSet.length > 0) {
        this.enableSelectedCategories(categoriesToSet);
        this.detailSettingsAvailable = true;
      } else {
        this.enableFunctionalCookiesOnly();
        this.detailSettingsAvailable = false;
      }
    },

    enableFunctionalCookiesOnly() {
      const cookieData = this.setMainConsentCookieData();
      cookieData.categories = [];
      HttpUtil.setCookie(this.cookieName, JSON.stringify(cookieData), 365 * 24 * 60);
      // Let GTM know that we have a GDPR consent for everything
      (window.dataLayer = window.dataLayer || []).push({
        event: 'gdprConsentFunctionalOnly',
      });
      this.$emit('ready');
    },

    enableAllCookies() {
      const cookieData = this.setMainConsentCookieData();
      cookieData.categories = this.categories;
      HttpUtil.setCookie(this.cookieName, JSON.stringify(cookieData), 365 * 24 * 60);
      // Let GTM know that we have a GDPR consent for everything
      (window.dataLayer = window.dataLayer || []).push({
        event: 'gdprConsentGiven',
        categories: cookieData.categories,
      });
      this.$emit('ready');
    },

    setMainConsentCookieData() {
      const currentCookie = HttpUtil.getCookie(this.cookieName);
      let cookieData = {};
      if (currentCookie > '') {
        cookieData = JSON.parse(currentCookie);
      }
      if (!cookieData.consentDate) {
        cookieData.consentDate = moment().toISOString();
      }
      if (!cookieData.key) {
        cookieData.key = generalUtils.uniqid();
      }
      HttpUtil.setCookie(this.cookieName, JSON.stringify(cookieData), 365 * 24 * 60);
      return cookieData;
    },

    /**
     * Enable selected categories value ONLY
     * (without cookie key and cookie date)
     * @param categoriesToSet
     */
    enableSelectedCategories(categoriesToSet) {
      const cookieData = this.setMainConsentCookieData();
      cookieData.categories = categoriesToSet;
      HttpUtil.setCookie(this.cookieName, JSON.stringify(cookieData), 365 * 24 * 60);
      // Let GTM know that we have a GDPR consent for everything
      (window.dataLayer = window.dataLayer || []).push({
        event: 'gdprConsentGiven',
        categories: cookieData.categories,
      });
      this.$emit('ready');
    },
  },
};
</script>

<style scoped>

</style>
