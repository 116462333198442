<template>
  <div class="kt-widget__menu-item">
    <button v-if="isAuthenticated" type="button" data-toggle="modal" data-target="#kt_chat_modal" title=""
            class="btn btn-label-success btn-sm btn-upper">
      <i class="fa fa-envelope"></i> {{ 'layout.send_user_message'|trans({},'messages') }}
    </button>
    <button v-if="!isAuthenticated" type="button" title="" class="btn btn-label-success btn-sm btn-upper"
            @click="showNotAuthenticatedInfo()">
      <i class="fa fa-envelope"></i> {{ 'layout.send_user_message'|trans({},'messages') }}
    </button>
    <!--Begin:: Chat-->
    <div class="modal fade- modal-sticky-bottom-right" ref="kt_chat_modal" id="kt_chat_modal" role="dialog"
         data-backdrop="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <private-message-thread :thread-id="threadId" :recipient-json="recipientJson"
                                  @thread-id-update="onThreadIdUpdate" show-close="1"
                                  :wait-for-trigger-event="'reload-private-message-thread'">
            <template v-slot:form>
              <slot name="form"></slot>
            </template>
          </private-message-thread>
        </div>
      </div>
    </div>
    <!--ENd:: Chat-->
  </div>
</template>

<script>
import Vue from "vue";
import Axios from "axios";
import JsRouting from 'fos-jsrouting-bundle';
import guiUtil from "js/app/utils/gui";
import Translator from "bazinga-translator";
import PrivateMessageThread from 'js/messaging/components/private-message-thread';
import securityUtil from "js/app/utils/security";
import {EventBus} from 'js/app/utils/EventBus';

export default {
  name: "product-message",
  props: {
    productId: null,
    threadId: null,
    recipientJson: '',
  },
  components: {
    PrivateMessageThread
  },
  data() {
    return {
      showNavigation: false
    }
  },
  created() {
    let self = this;
  },
  mounted() {
    let self = this;
    self.initVendors();
    $(this.$refs['kt_chat_modal']).on('shown.bs.modal', function () {
      // do something when modal is shown
      EventBus.$emit('reload-private-message-thread', self.threadId);
    });
    $(this.$refs['kt_chat_modal']).on('hidden.bs.modal', function () {
      // do something when modal is hidden
    });
  },
  computed: {
    isAuthenticated() {
      return securityUtil.isAuthenticatedSession();
    }
  },
  watch: {},
  methods: {

    initVendors() {
      let self = this;
      // ! Do it on the next tick to be sure the DOM is being updated !
      Vue.nextTick(() => {
        // init bootstrap tooltips
        $(this).tooltip('dispose'); // dispose previous tooltip
        KTApp.initTooltip($(self.$el));
      });
    },

    trans(key, domain, params) {
      return Translator.trans(key, {}, domain || 'FOSMessageBundle');
    },

    showNotAuthenticatedInfo() {
      if (!this.isAuthenticated) {
        guiUtil.showLoginExclusiveFeatureInfo();
        return false;
      }
    },

    onThreadIdUpdate(newId) {
      this.$emit("thread-id-update", newId);
    },

  }
}
</script>

<style scoped>

</style>
