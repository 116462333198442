var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "search-detailed__holiday--results space-top-3 space-top-sm-4 space-top-md-4 space-top-lg-0"
    },
    [
      _vm.isLoading
        ? _c(
            "div",
            _vm._l([1, 2, 3, 4, 5], function(cnt, index) {
              return _c("search-results-placeholder", {
                key: index,
                attrs: { item: cnt }
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("div", { staticClass: "text-center row justify-content-center" }, [
            _c("div", { staticClass: "col-lg-10" }, [
              _c("h2", { staticClass: "h2" }, [
                _vm._v("Ciężko pracujemy nad dodawaniem ofert!")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mt-10" }, [
                _vm._v(
                  "Już wkrótce, gdy nasza baza będzie zawierać wystarczającą ilość ofert, zobaczysz tutaj listę rezultatów w wybranej przez Ciebie okolicy."
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-10" }, [
                _vm._v("Do tego czasu prosimy jeszcze o odrobinę cierpliwości.")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "metronic-scope" }, [
                _vm.isAuthenticated
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "card card-custom gutter-b bg-light-primary"
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center justify-content-lg-between p-2 p-lg-4 flex-wrap flex-lg-nowrap"
                            },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-lg-0 ml-xxl-6 flex-shrink-0"
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn font-weight-bolder text-uppercase btn-primary py-4 px-6",
                                      attrs: {
                                        href: _vm.path(
                                          "app_account_provider_products_list"
                                        ),
                                        target: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Do edycji ofert\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isAuthenticated
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "card card-custom gutter-b bg-light-primary"
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center justify-content-lg-between p-2 p-lg-4 flex-wrap flex-lg-nowrap"
                            },
                            [
                              _vm._m(1),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-lg-0 ml-xxl-6 flex-shrink-0"
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn font-weight-bolder text-uppercase btn-primary py-4 px-6",
                                      attrs: {
                                        href: _vm.path(
                                          "fos_user_registration_register"
                                        ),
                                        target: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Do rejestracji\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex flex-column mr-lg-5 mb-3" }, [
      _c(
        "a",
        {
          staticClass: "h4 text-dark text-hover-primary mb-5",
          attrs: { href: "#" }
        },
        [
          _vm._v("\n                Zamiastowy Gospodarzu."),
          _c("br"),
          _vm._v("Potrzebujemy Twojej pomocy!\n              ")
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-dark-50" }, [
        _vm._v(
          "\n                Publikując swoje oferty, pomożesz nam, jak najszybciej udostępnić pełną wyszukiwarkę ofert."
        ),
        _c("br"),
        _vm._v(
          "\n                Pomóż nam pokazać pełen potencjał tego ambitnego projektu.\n              "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex flex-column mr-lg-5 mb-3" }, [
      _c(
        "a",
        {
          staticClass: "h4 text-dark text-hover-primary mb-5",
          attrs: { href: "#" }
        },
        [_vm._v("\n                Zamiastowy Gospodarzu\n              ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-dark-50" }, [
        _vm._v(
          "\n                Rejestrując się i publikując swoje oferty, pomożesz nam, jak najszybciej udostępnić pełną wyszukiwarkę ofert."
        ),
        _c("br"),
        _vm._v(
          "\n                Pomóż nam pokazać pełen potencjał tego ambitnego projektu.\n              "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }