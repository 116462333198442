<!--template>
we use inline template here
</template-->

<script>
export default {
  name: 'HomeTagBasedEntry',
  data() {
    return {
      collapsed: true,
    };
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style scoped>

</style>
