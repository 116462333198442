/**
 * @see https://vuemc.io/#basic-usage
 */
import {Model, Collection} from 'vue-mc'
import Notification from "./Notification";

/**
 * Product collection
 */
class NotificationCollection extends Collection {

  // Model that is contained in this collection.
  model() {
    return Notification;
  }

  // Default attributes
  defaults() {
    return {
      orderBy: 'code',
    }
  }

}

export default NotificationCollection;
